.status-selector {
  list-style: none;
}

.status-selector ul {
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.status-selector__item {
  background-color: var(--background-brand-subtle);
  cursor: pointer;
  padding: 1em;
  display: flex;
}

.status-selector__item--active {
  background-color: var(--background-brand-subtle-pressed);
  color: var(--content-info);
}
