.fill-in-the-blank-editor__insert-dropdown-btn.sg-button {
  margin-left: auto;
  margin-right: 8px;
}

.fill-in-the-blank-dropdown-editor button {
  margin-right: .5em;
  padding: .6em .8em;
}

.fill-in-the-blank-dropdown-editor .unbutton {
  color: #50a9e2;
  margin-left: 1em;
}

.fill-in-the-blank-dropdown-editor .unbutton:hover {
  color: #1a699e;
}

.fill-in-the-blank-dropdown-editor hr {
  border: 0;
  border-top: 2px solid var(--border-subtle);
  width: 100%;
  max-width: 32em;
  margin: 2em 0 2.5em;
  display: block;
}

.fill-in-the-blank-editor__dropdown {
  margin: 1em 0;
}

.fill-in-the-blank-editor__dropdown-number {
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  max-width: 24.45em;
  margin-bottom: 1.5em;
  display: flex;
}

.fill-in-the-blank-editor__choices-wrapper {
  display: flex;
}

.fill-in-the-blank-editor__sort-handle-wrapper {
  cursor: -webkit-grab;
  margin-top: 1em;
  margin-left: 2em;
}

.fill-in-the-blank-editor__sort-handle-wrapper .fa {
  color: #d1d5db;
  margin-right: .15em;
  font-size: 2em;
}

.fill-in-the-blank-editor-dropdown-choice {
  align-items: baseline;
  width: 100%;
  min-width: 28.5em;
  display: flex;
}

.fill-in-the-blank-editor-dropdown-choice .fa {
  padding: .75em 1em;
}

.fill-in-the-blank-editor-dropdown-choice .fill-in-the-blank-editor__dropdown-choice-input {
  margin-left: .5em;
}

.fill-in-the-blank-editor-dropdown-choice .fill-in-the-blank-editor__dropdown-choice-input.is-correct-choice input {
  border-color: #059669;
}

.fill-in-the-blank-editor__drag-helper {
  font-size: .87em;
}

.fill-in-the-blank-editor__drag-helper label {
  margin-bottom: .5em;
}

.fill-in-the-blank-editor__drag-helper button {
  margin-right: .5em;
  padding: .5em .75em;
}

.fill-in-the-blank-editor__drag-helper .fill-in-the-blank-editor__sort-handle-wrapper {
  cursor: -webkit-grabbing;
}

.fill-in-the-blank-editor__drag-helper hr {
  border: 0;
  border-top: 2px solid var(--border-subtle);
  width: 100%;
  max-width: 32em;
  margin: 2em 0 2.5em;
  display: block;
}

.fill-in-the-blank-editor__supplement-manager {
  left: 13rem;
}
