.authoring-feedback {
  position: absolute;
  inset: 0;
}

.authoring-feedback__instructions {
  text-align: center;
  margin-top: 50%;
}

.authoring-feedback-messages-empty {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}

.authoring-feedback-form {
  flex-direction: column;
  justify-content: space-between;
  padding: .75em;
  display: flex;
}

.authoring-feedback-form .a-form-input {
  margin: .75em 0;
}

.authoring-feedback-form .sg-button {
  width: 100%;
}

.authoring-feedback-form .a-form-input, .authoring-feedback-form textarea {
  max-width: 100%;
}
