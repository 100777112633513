.text-highlight-question table {
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;
  max-width: 100%;
  margin: 20px auto;
  overflow: auto;
}

.text-highlight-question th {
  background-color: var(--background-primary);
  border-top: 1px solid var(--border-primary);
}

.text-highlight-question tbody tr {
  background-color: var(--background-subtle);
}

.text-highlight-question tbody tr:nth-child(2n) {
  background-color: var(--background-primary);
}

.text-highlight-question tr, .text-highlight-question th {
  box-shadow: inset 0 -1px 0 var(--border-primary);
}

.text-highlight-question tbody:first-child tr:first-child {
  box-shadow: inset 0 -1px 0 var(--border-primary), inset 0 1px 0 var(--border-primary);
}

.text-highlight-question th, .text-highlight-question td {
  border-right: 1px solid var(--border-primary);
  text-align: left;
  padding: 20px 20px 21px;
}

.text-highlight-question th:first-child, .text-highlight-question td:first-child {
  border-left: 1px solid var(--border-primary);
}

.text-highlight-question th {
  white-space: nowrap;
  font-size: 1.1em;
  font-weight: bold;
}

.text-highlight-question th::selection {
  background-color: rgba(0, 0, 0, 0);
}

.text-highlight-question th ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.text-highlight-question p, .text-highlight-question a, .text-highlight-question ul, .text-highlight-question ol, .text-highlight-question li, .text-highlight-question h1, .text-highlight-question h2, .text-highlight-question h3, .text-highlight-question h4, .text-highlight-question h5, .text-highlight-question h6, .text-highlight-question blockquote, .text-highlight-question figcaption {
  -webkit-user-select: none;
  user-select: none;
}

.text-highlight-question a:not([class]) {
  color: var(--content-link);
}

.text-highlight-question a:not([class]):not(:hover) {
  text-decoration: none;
}

.text-highlight-question a:not([class]):hover, .text-highlight-question a:not([class]):visited {
  color: #1e7cb8;
}

.text-highlight-question small {
  line-height: 1.2;
}

.text-highlight-question img {
  max-width: 100%;
}

.text-highlight-question p, .text-highlight-question li {
  color: var(--content-primary);
}

@media (max-width: 600px) {
  .text-highlight-question ul, .text-highlight-question ol {
    margin-left: 1rem;
    padding-left: 0;
  }
}

.text-highlight-question li {
  margin-bottom: .5em;
}

.text-highlight-question strong {
  font-family: Lato, sans-serif;
  font-weight: 800;
}

.text-highlight-question blockquote {
  margin: 0 0 1em;
  padding: 1em;
}

@media (max-width: 600px) {
  .text-highlight-question blockquote {
    margin: 1em 1.5em;
  }
}

.text-highlight-question pre {
  background-color: var(--background-subtle);
  color: var(--content-secondary);
  border-radius: .25em;
  max-width: 100%;
  padding: .5em;
  font-family: Cousine, Courier, monospace;
  line-height: 1.4;
}

.text-highlight-question p code, .text-highlight-question .paragraph code {
  background-color: var(--background-bold);
  border-radius: .25em;
  margin: 0 .25em;
  padding: 0 .25em;
  display: inline-block;
}

.text-highlight-question pre code[class^="lang"] {
  color: #ccc;
  background: #2d2d2d;
  padding: .75em 1em;
  display: block;
  overflow-x: auto;
}

.text-highlight-question hr {
  background-color: var(--background-subtle);
  border: 0;
  height: 1px;
}

.text-highlight-question [class$="question-content"] {
  margin-bottom: 2em;
}

.text-highlight-question .scroll-wrapper {
  max-width: 100%;
  overflow: auto;
}

.text-highlight-question .question-prompt, .text-highlight-question .question-answer-area {
  margin-bottom: 2rem;
  padding: 0 2rem;
}

@media (max-width: 900px) {
  .text-highlight-question .question-prompt, .text-highlight-question .question-answer-area {
    padding: 0 1rem;
  }
}

.text-highlight-question .question__prompt-heading {
  margin: 20px 0;
  font-size: 1.2em;
}

.text-highlight-question .MathJax {
  color: var(--content-primary);
}

.text-highlight-question .MathJax_Display {
  margin: 0;
}

.text-highlight-explanation h2, .text-highlight-question h2 {
  margin-bottom: .75em !important;
}

.text-highlight-area {
  cursor: default;
  letter-spacing: .25px;
  -webkit-user-select: none;
  user-select: none;
  white-space: pre-wrap;
  -webkit-touch-callout: none;
  flex-wrap: wrap;
  margin-bottom: 1.5em;
  padding: 0 2rem;
  line-height: 1.7em;
  display: flex;
}

.text-highlight-area__char {
  cursor: default;
  padding: .25em 0;
}

.text-highlight-area__char--highlight {
  background-color: #fcd34d;
}

.text-highlight-area--correct .text-highlight-area__char--highlight {
  background-color: #a7f3d0;
}

.text-highlight-area--incorrect .text-highlight-area__char--highlight {
  background-color: #fbefef;
}

.text-highlight-area:not(.text-highlight-area--prevent-interaction) .text-highlight-area__char--current {
  background-color: #d1fae5;
}

.text-highlight-area:not(.text-highlight-area--prevent-interaction) .text-highlight-area__char--limit {
  background-color: #6ee7b7;
}

@media (hover: hover) {
  .text-highlight-area:not(.text-highlight-area--prevent-interaction) .text-highlight-area__char:hover, .text-highlight-area:not(.text-highlight-area--prevent-interaction) .text-highlight-area__char--hl:hover {
    background-color: #6ee7b7;
  }
}
