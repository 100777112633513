.collapsible-sidebar-container {
  align-items: flex-start;
  display: flex;
}

.collapsible-sidebar {
  height: 100%;
  display: flex;
}

.collapsible-sidebar__content-wrapper {
  height: 100%;
  transition: width .25s;
  overflow: hidden;
}

.collapsible-sidebar__content {
  height: 100%;
  overflow: auto;
}

.collapsible-sidebar__toggle-wrapper {
  background-color: var(--background-bold);
  box-shadow: inset -1px 0 0 var(--border-subtle), inset 1px 0 0 var(--border-subtle);
  width: 5ch;
  font-size: 16px;
}

.collapsible-sidebar__toggle {
  transform-origin: 0 100%;
  white-space: nowrap;
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 50%;
  transform: rotate(90deg)translateY(50%);
}

.collapsible-sidebar__toggle .fa {
  margin-right: .5em;
  display: inline-block;
}

.collapsible-sidebar__toggle .fa--mirrored {
  transform: rotate(180deg);
}

.collapsible-sidebar__toggle:disabled, .collapsible-sidebar__toggle:disabled:hover {
  color: #d1d5db;
  cursor: not-allowed;
}

.collapsible-sidebar-container__main {
  flex-grow: 1;
  height: 100%;
  overflow: auto;
}
