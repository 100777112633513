.sg-tabs-wrapper .sg-tabs {
  padding-right: 8px;
}

.sg-tabs-wrapper .sg-tab:first-child {
  border-bottom-left-radius: 0;
}

.sg-tabs-wrapper .sg-tab:last-child {
  border-bottom-right-radius: 0;
}

.sg-tabs-nested-content {
  background-color: #fff;
  border-radius: 0 4px 4px;
  overflow: hidden;
}

.sg-tabs {
  display: flex;
}

@media (max-width: 600px) {
  .sg-tabs {
    overflow: auto;
  }
}

.sg-tab, .sg-tab.unbutton {
  text-transform: uppercase;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #e5e7eb;
  border-width: 0 1px 1px 0;
  border-radius: 0;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  display: flex;
}

.sg-tab:not(.sg-tab--active), .sg-tab.unbutton:not(.sg-tab--active) {
  color: #9ca3af;
}

.sg-tab:focus, .sg-tab.unbutton:focus {
  outline-width: 0;
}

.sg-tab:first-child, .sg-tab.unbutton:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.sg-tab:last-child, .sg-tab.unbutton:last-child {
  border-right: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.sg-tab:after, .sg-tab.unbutton:after {
  content: "";
  width: calc(100% - 40px);
  height: 3px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 20px;
}

.sg-tab:focus, .sg-tab:hover, .sg-tab.unbutton:focus, .sg-tab.unbutton:hover {
  color: #9ca3af;
  background-color: #fbfbfb;
  text-decoration: none;
}

.sg-tab:focus:after, .sg-tab:hover:after, .sg-tab.unbutton:focus:after, .sg-tab.unbutton:hover:after {
  background-color: #9ca3af;
}

.sg-tabs--with-nested-content .sg-tab:first-child, .sg-tabs--with-nested-content .sg-tab.unbutton:first-child {
  border-bottom-left-radius: 0;
}

.sg-tabs--with-nested-content .sg-tab:last-child, .sg-tabs--with-nested-content .sg-tab.unbutton:last-child {
  border-bottom-right-radius: 0;
}

.sg-tab__badge {
  color: #fff;
  text-align: center;
  background-color: #9ca3af;
  border-radius: 100px;
  min-width: 3.5ch;
  margin-left: 10px;
  padding: 5px;
  font-size: .9em;
}

.sg-tab__label {
  padding-top: 20px;
  padding-bottom: 20px;
}

.sg-tab--active, .sg-tab--active.unbutton {
  color: #50a9e2;
}

.sg-tab--active:hover, .sg-tab--active:focus, .sg-tab--active.unbutton:hover, .sg-tab--active.unbutton:focus {
  color: #50a9e2;
  background-color: #fff;
}

.sg-tab--active:hover:after, .sg-tab--active:focus:after, .sg-tab--active.unbutton:hover:after, .sg-tab--active.unbutton:focus:after {
  background-color: #50a9e2;
}

.sg-tab--active .sg-tab__label:before, .sg-tab--active.unbutton .sg-tab__label:before {
  display: none;
}

.sg-tab--active:after, .sg-tab--active.unbutton:after, .sg-tab--active .sg-tab__badge, .sg-tab--active.unbutton .sg-tab__badge {
  background-color: #50a9e2;
}
