.sign-up-form-wrapper .search-schools-by-name__text-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: var(--background-primary);
  border: 2px solid var(--border-primary);
  border-radius: 3px;
  height: 2.5rem;
  padding: 0 .5em;
  font-family: Lato, Helvetica, Arial, sans-serif;
  font-size: 1em;
  transition: border 50ms ease-in-out;
}

.sign-up-form-wrapper .search-schools-by-name__text-input:focus, .sign-up-form-wrapper .search-schools-by-name__text-input:hover {
  transition: border 50ms ease-in-out;
}

.sign-up-form-wrapper .search-schools-by-name__text-input:focus {
  border-color: #a8d4f0;
  outline-width: 0;
}

.sign-up-form-wrapper .search-schools-by-name__text-input:hover {
  border-color: #a8d4f0;
}

.sign-up-form-wrapper .search-schools-by-name__text-input:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.sign-up-form-wrapper .search-schools-by-name__text-input:disabled:hover {
  border-color: #e5e7eb;
}

.sign-up-form-wrapper .search-schools-by-name__text-input:disabled {
  opacity: .5;
}

.sign-up-form-user-agreement, .sign-up-form-change-account-type {
  flex: auto;
  justify-content: center;
  align-items: center;
  margin: 0 0 1em;
  font-size: 1rem;
  display: flex;
}

.sign-up-form-link--change-account-type, .sign-up-form-link--no-school {
  margin: 0 0 0 .25em;
}

@media (max-width: 600px) {
  .sign-up-form-link--no-school {
    margin: .25em 0 0;
  }
}
