.questions-list-sortable-item-helper-class {
  z-index: 999;
  font-size: 14px;
  list-style-type: none;
}

.questions-list {
  z-index: 9;
  width: 100%;
  max-width: 320px;
  height: calc(100% - 120px);
  position: fixed;
  top: 120px;
  left: 0;
  box-shadow: 1px 0 #d1d5db;
}

.questions-list ul, .questions-list li {
  list-style-type: none;
}

.questions-list ul {
  padding: 0;
}

ul.questions-list__questions {
  background-color: var(--background-subtle);
  height: 100%;
  margin: 0;
  overflow: auto;
}

ul.questions-list__questions:not(:hover)::-webkit-scrollbar {
  display: none;
}

.multiple-question-set {
  background-color: #f3f4f6;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #d1d5db;
  padding: .5em;
  list-style-type: none;
}

.multiple-question-set ul {
  margin: 0;
  padding: 0;
}

.multiple-question-set__heading {
  align-items: center;
  margin-bottom: .5em;
  display: flex;
}

.multiple-question-set__name {
  margin-right: auto;
  font-weight: bold;
}

.question-list-item {
  background-color: var(--background-primary);
  border-bottom: 1px solid var(--border-subtle);
  border-right: 1px solid var(--border-subtle);
  cursor: pointer;
  min-height: 4em;
}

.question-list-item::selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-list-item ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-list-item:before {
  background-color: var(--background-brand);
  content: "";
  width: 0;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -1px;
}

.question-list-item:hover:before, .question-list-item.question-list-item--active:before {
  width: .5em;
  transition: width .15s;
}

.question-list-item.question-list-item--disabled {
  pointer-events: none;
}

.question-list-item--excluded-from-search {
  opacity: .5;
  align-items: center;
  display: flex;
}

.questions-list-item__tooltip {
  color: #fff;
  background-color: #363a3d;
  transform: translateX(320px);
}

@media (max-width: 440px) {
  .questions-list-item__tooltip {
    transform: translateX(0);
  }
}

.question-list-item__content {
  color: var(--content-primary);
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 1em 1em 1em .75em;
  text-decoration: none;
  display: flex;
}

.question-list-item__content--excluded-from-search {
  flex-basis: 100%;
}

.question-list-item__difficulty {
  color: #374151;
  text-align: center;
  background: #f7f8f8;
  border-radius: .15em;
  width: 3.25ch;
  min-width: 3.25ch;
  margin-right: .75em;
  padding: .5em 0;
}

.question-list-item__difficulty--hidden {
  visibility: hidden;
  width: 0;
  min-width: 0;
}

.question-list-item__difficulty--easy {
  background-color: var(--background-info-subtle);
  color: var(--content-info);
}

.question-list-item__difficulty--moderate {
  background-color: var(--background-positive-subtle);
  color: var(--content-positive);
}

.question-list-item__difficulty--difficult {
  background-color: var(--background-negative-subtle);
  color: var(--content-negative);
}

.question-list-item__title {
  flex-grow: 1;
  margin-right: .5em;
}

.question-list-sortable-drag-handle {
  color: #9ca3af;
  cursor: -webkit-grab;
  cursor: grab;
  text-align: center;
  width: 2ch;
  padding: .5em 0;
  font-size: 1.1em;
  transition: color .15s;
}

.question-list-sortable-drag-handle:hover {
  color: #4b5563;
}

.questions-list__top-drawer {
  z-index: 99;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.questions-list__bottom-drawer {
  z-index: 99;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.questions-list__loading-indicator {
  margin-bottom: .5em;
  padding: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
