h1.supplement-search__heading {
  color: #6b7280;
  text-transform: uppercase;
  margin: 0;
  padding: 15px;
  font-size: 16px;
}

.supplement-search {
  flex-flow: wrap;
  max-width: 1088px;
  margin: -10px;
  display: flex;
}

.supplement-search__filters-wrapper {
  flex-direction: column;
  flex: 100%;
  margin: 10px;
  display: flex;
}

.supplement-search__filters {
  background: #fff;
  border-radius: 4px;
  flex-flow: wrap;
  justify-content: space-between;
  padding: 22px 15px;
  display: flex;
}

.supplement-search__filters-input {
  flex-direction: column;
  flex: 1 0 100%;
  margin: 0 0 20px;
  display: flex;
}

.supplement-search__filters-input--owner {
  flex-basis: 0;
  width: calc(100% - 7.2em);
  margin-bottom: 20px;
}

.supplement-search__results-wrapper {
  flex-direction: column;
  flex: 5 100%;
  margin: 10px;
  display: flex;
}

.supplement-search__results {
  background: #fff;
  border-radius: 4px;
  display: flex;
}

.supplement-search__results-table {
  width: 100%;
}

.supplement-search__results-table .sg-table__thead--fixed-headings {
  border-top: 0;
  border-radius: 4px 4px 0 0;
}

.supplement-search__results-table .sg-table__td {
  text-transform: capitalize;
}

.supplement-search__results-table .sg-table__td:nth-child(3) {
  text-transform: initial;
}

.supplement-search__results-table .sg-table__td:last-child {
  display: flex;
}

.supplement-search__search-dropdown-option {
  cursor: pointer;
  flex-direction: column;
  font-size: .9em;
  display: flex;
}

.supplement-search__search-dropdown-option-email {
  font-weight: bold;
}

.supplement-search__search-dropdown-option-email, .supplement-search__search-dropdown-option-name {
  text-overflow: ellipsis;
  overflow: hidden;
}

.supplement-search__filters-button--new-supplement {
  flex-basis: 100%;
}

@media (min-width: 900px) {
  .supplement-search {
    flex-wrap: nowrap;
  }

  .supplement-search__filters-wrapper {
    flex-basis: 266px;
  }

  .supplement-search__results-wrapper {
    flex-basis: 457px;
  }

  .supplement-search__results-table .sg-table__td {
    text-overflow: ellipsis;
    max-width: 14ch;
    overflow: hidden;
  }
}
