.free-entry-question--legacy table {
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;
  max-width: 100%;
  margin: 20px auto;
  overflow: auto;
}

.free-entry-question--legacy th {
  background-color: var(--background-primary);
  border-top: 1px solid var(--border-primary);
}

.free-entry-question--legacy tbody tr {
  background-color: var(--background-subtle);
}

.free-entry-question--legacy tbody tr:nth-child(2n) {
  background-color: var(--background-primary);
}

.free-entry-question--legacy tr, .free-entry-question--legacy th {
  box-shadow: inset 0 -1px 0 var(--border-primary);
}

.free-entry-question--legacy tbody:first-child tr:first-child {
  box-shadow: inset 0 -1px 0 var(--border-primary), inset 0 1px 0 var(--border-primary);
}

.free-entry-question--legacy th, .free-entry-question--legacy td {
  border-right: 1px solid var(--border-primary);
  text-align: left;
  padding: 20px 20px 21px;
}

.free-entry-question--legacy th:first-child, .free-entry-question--legacy td:first-child {
  border-left: 1px solid var(--border-primary);
}

.free-entry-question--legacy th {
  white-space: nowrap;
  font-size: 1.1em;
  font-weight: bold;
}

.free-entry-question--legacy th::selection {
  background-color: rgba(0, 0, 0, 0);
}

.free-entry-question--legacy th ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.free-entry-question--legacy p, .free-entry-question--legacy a, .free-entry-question--legacy ul, .free-entry-question--legacy ol, .free-entry-question--legacy li, .free-entry-question--legacy h1, .free-entry-question--legacy h2, .free-entry-question--legacy h3, .free-entry-question--legacy h4, .free-entry-question--legacy h5, .free-entry-question--legacy h6, .free-entry-question--legacy blockquote, .free-entry-question--legacy figcaption {
  -webkit-user-select: none;
  user-select: none;
}

.free-entry-question--legacy a:not([class]) {
  color: var(--content-link);
}

.free-entry-question--legacy a:not([class]):not(:hover) {
  text-decoration: none;
}

.free-entry-question--legacy a:not([class]):hover, .free-entry-question--legacy a:not([class]):visited {
  color: #1e7cb8;
}

.free-entry-question--legacy small {
  line-height: 1.2;
}

.free-entry-question--legacy img {
  max-width: 100%;
}

.free-entry-question--legacy p, .free-entry-question--legacy li {
  color: var(--content-primary);
}

@media (max-width: 600px) {
  .free-entry-question--legacy ul, .free-entry-question--legacy ol {
    margin-left: 1rem;
    padding-left: 0;
  }
}

.free-entry-question--legacy li {
  margin-bottom: .5em;
}

.free-entry-question--legacy strong {
  font-family: Lato, sans-serif;
  font-weight: 800;
}

.free-entry-question--legacy blockquote {
  margin: 0 0 1em;
  padding: 1em;
}

@media (max-width: 600px) {
  .free-entry-question--legacy blockquote {
    margin: 1em 1.5em;
  }
}

.free-entry-question--legacy pre {
  background-color: var(--background-subtle);
  color: var(--content-secondary);
  border-radius: .25em;
  max-width: 100%;
  padding: .5em;
  font-family: Cousine, Courier, monospace;
  line-height: 1.4;
}

.free-entry-question--legacy p code, .free-entry-question--legacy .paragraph code {
  background-color: var(--background-bold);
  border-radius: .25em;
  margin: 0 .25em;
  padding: 0 .25em;
  display: inline-block;
}

.free-entry-question--legacy pre code[class^="lang"] {
  color: #ccc;
  background: #2d2d2d;
  padding: .75em 1em;
  display: block;
  overflow-x: auto;
}

.free-entry-question--legacy hr {
  background-color: var(--background-subtle);
  border: 0;
  height: 1px;
}

.free-entry-question--legacy [class$="question-content"] {
  margin-bottom: 2em;
}

.free-entry-question--legacy .scroll-wrapper {
  max-width: 100%;
  overflow: auto;
}

.free-entry-question--legacy .question-prompt, .free-entry-question--legacy .question-answer-area {
  margin-bottom: 2rem;
  padding: 0 2rem;
}

@media (max-width: 900px) {
  .free-entry-question--legacy .question-prompt, .free-entry-question--legacy .question-answer-area {
    padding: 0 1rem;
  }
}

.free-entry-question--legacy .question__prompt-heading {
  margin: 20px 0;
  font-size: 1.2em;
}

.free-entry-question--legacy .MathJax {
  color: var(--content-primary);
}

.free-entry-question--legacy .MathJax_Display {
  margin: 0;
}

.free-entry-question--legacy .free-entry-question__inputs-wrapper {
  max-width: 500px;
  margin: 0 auto;
}

.free-entry-question--legacy .free-entry-input {
  margin-bottom: 20px;
  display: block;
}

.free-entry-question--legacy .free-entry-input--post-submission {
  pointer-events: none;
}

.free-entry-question--legacy .free-entry-input__prompt {
  margin-bottom: 5px;
}

.free-entry-question--legacy .free-entry-input__input {
  margin-right: 0;
}

.free-entry-question--legacy .free-entry-input__input:before {
  content: "";
  pointer-events: none;
  z-index: 1;
  border: 3px solid rgba(0, 0, 0, 0);
  border-radius: 8px;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.free-entry-question--legacy .free-entry-input__input .a-form-input__element {
  padding-left: 10px;
  padding-right: 30px;
}

.free-entry-question--legacy .free-entry-input--post-submission .a-form-input__element {
  border-color: rgba(0, 0, 0, 0);
}

.free-entry-question--legacy .free-entry-input--post-submission .free-entry-input__input:before {
  border-color: #50a9e2;
}

.free-entry-question--legacy .free-entry-input__correctness-indicator {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
