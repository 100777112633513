.queue-results-questions-list.questions-list {
  max-width: 100%;
  height: 100%;
  font-size: .9em;
  position: relative;
  top: 0;
}

.queue-results__pagination {
  background-color: var(--background-primary);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  display: flex;
}

.queue-results__pagination .paginationv2 {
  max-width: 100%;
  margin: 10px 0 0;
  overflow: hidden;
}

.queue-results__pagination .paginationv2__number-list {
  margin: 0;
  overflow: auto;
}

.queue-results__pagination .paginationv2__navigation-button--left {
  margin-right: 10px;
}

.queue-results__pagination .paginationv2__navigation-button--right {
  margin-left: 10px;
}

.queue-results__question-count {
  margin-bottom: 5px;
}

.queue-results__top-drawer {
  background-color: #fff;
  flex-direction: column;
  height: 200px;
  padding: .5em;
  display: flex;
}

.queue-results__empty-results {
  text-align: center;
  margin-top: 50%;
}

.add-question-set {
  background-color: #f3f4f6;
  align-items: center;
  height: 45px;
  padding: 5px;
  display: flex;
}

.sg-button.add-question-set__button {
  width: 100%;
  margin-right: 0;
}

.question-has-changes-indicator {
  color: #1e7cb8;
}
