.questions-list-settings {
  cursor: default;
  z-index: 99;
  width: 100%;
}

.questions-list-settings::selection {
  background-color: rgba(0, 0, 0, 0);
}

.questions-list-settings ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.questions-list-settings__toggle {
  background-color: #f3f4f6;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  justify-content: space-between;
  align-items: center;
  height: 44px;
  padding: 0 1em;
  font-weight: bold;
  display: flex;
}

.questions-list-settings__toggle-icon {
  cursor: pointer;
}

.questions-list-settings__label {
  margin-bottom: .5em;
  font-weight: bold;
}

.questions-list-settings__form-sizer {
  width: 100%;
  height: 0;
  transition: height .25s;
  position: absolute;
  top: 100%;
  left: 0;
  overflow: hidden;
}

.questions-list-settings__form-sizer[style*="height"] {
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.questions-list-settings__settings-form {
  background-color: #f9fafb;
  padding: 1em 1em 2em;
}

.questions-list-settings__input-wrapper {
  justify-content: space-between;
  align-items: center;
  margin-bottom: .75em;
  display: flex;
}

.questions-list-settings__input-wrapper--teacher-mode {
  font-weight: bold;
}

.questions-list-settings__input-wrapper--radio [type="radio"] {
  display: none;
}

.questions-list-settings__input-wrapper--radio .fancy-radio {
  border: 3px solid #d1d5db;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  margin-right: .25rem;
  display: inline-block;
  position: relative;
}

.questions-list-settings__input-wrapper--radio label {
  cursor: pointer;
  align-items: center;
  padding: .25em 0;
  display: flex;
}

.questions-list-settings__input-wrapper--radio label:not(:last-child) {
  margin-bottom: .25em;
}

.questions-list-settings__input-wrapper--radio [type="radio"]:checked ~ .fancy-radio {
  border: 3px solid #059669;
}

.questions-list-settings__input-wrapper--radio [type="radio"]:checked ~ .fancy-radio:before {
  content: "";
  background-color: #059669;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.questions-list-settings__checkbox-wrapper input[type="checkbox"] {
  opacity: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  outline-width: 0;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  position: absolute;
}

.questions-list-settings__checkbox-wrapper .checkbox-toggle {
  background-color: var(--background-subtle-hover);
  cursor: pointer;
  border: 2px solid rgba(0, 0, 0, .2);
  border-radius: 2em;
  width: 2.5em;
  height: 1.25em;
  transition: all .15s;
  display: block;
  position: relative;
}

.questions-list-settings__checkbox-wrapper .checkbox-toggle:before {
  background-color: var(--background-primary);
  border: 2px solid var(--border-primary);
  content: "";
  border-radius: 50%;
  width: 1em;
  height: 1em;
  transition: all .15s;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.questions-list-settings__checkbox-wrapper input[type="checkbox"]:checked ~ .checkbox-toggle {
  background-color: var(--background-brand);
}

.questions-list-settings__checkbox-wrapper input[type="checkbox"]:checked ~ .checkbox-toggle:before {
  left: calc(100% - 1em);
}

.questions-list-settings__header-title {
  color: #50a9e2;
  cursor: pointer;
}

.questions-list-settings__header-title a {
  text-decoration: none;
}

.questions-list-settings__header-title a, .questions-list-settings__header-title a:visited, .questions-list-settings__header-title a:active {
  color: #50a9e2;
}
