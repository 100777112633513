.sgi-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sgi {
  color: #374151;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #e5e7eb;
  border-bottom: 0;
  flex-wrap: nowrap;
  align-items: center;
  scroll-margin-top: 60px;
  font-size: 16px;
  text-decoration: none;
}

.sgi:last-of-type {
  border-bottom: 1px solid #e5e7eb;
}

.sgi:focus {
  z-index: 1;
  background-color: #f9fafb;
  outline: 3px solid #50a9e2;
}

.sgi:hover {
  background-color: #f9fafb;
}

.sgi--last-of-theme, .sgi:last-child {
  border-bottom: 1px solid #e5e7eb;
  border-bottom-right-radius: .8em;
  border-bottom-left-radius: .8em;
}

.sgi--theme, .sgi--topic, .sgi--subtopic, .sgi--question {
  display: flex;
}

.sgi--theme {
  border-top-left-radius: .8em;
  border-top-right-radius: .8em;
  padding: 1em;
}

.sgi--theme ~ .sgi--theme {
  margin-top: 2.1em;
}

.sgi--topic {
  padding: 1.21em 1.07em;
}

.sgi--subtopic, .sgi--topic + .sgi--question, .sgi--topic + .sgi--question ~ .sgi--question {
  padding: 1.21em 1.07em 1.21em 2.14em;
}

.sgi--subtopic + .sgi--question, .sgi--subtopic + .sgi--question ~ .sgi--question {
  padding: 1.21em 1.07em 1.21em 3.21em;
}

.sgi__logo-img-wrapper {
  align-items: center;
  width: 36px;
  margin-right: 1em;
  display: flex;
}

.sgi__logo-img-wrapper img {
  width: 100%;
  max-width: 36px;
  height: auto;
  max-height: 36px;
}

.sgi__content {
  flex-wrap: wrap;
  flex-grow: 1;
  flex-basis: 100%;
  display: flex;
}

.sgi__heading {
  flex-grow: 1;
  flex-basis: 50%;
  max-width: 620px;
  margin: 0;
  font-weight: bold;
}

.sgi__heading a {
  color: inherit;
  text-decoration: none;
}

.sgi__metadata, .sgi__body {
  opacity: .78;
  flex-basis: 100%;
  max-width: 50ch;
}

.sgi__body {
  flex-basis: 100%;
  order: 100;
  display: none;
}

.sgi__heading--theme {
  font-size: 1.3em;
}

.sgi__heading--topic, .sgi__heading--subtopic, .sgi__heading--question {
  font-size: .9375em;
}

.sgi__metadata {
  white-space: nowrap;
  font-size: .9em;
}

.sgi__actions {
  justify-content: flex-end;
  align-self: flex-start;
  margin-left: auto;
  display: flex;
}

.sgi__actions.student {
  min-width: 80px;
}

.sgi__actions.teacher {
  min-width: 50px;
}

.sgi__actions > * {
  margin: 0 auto;
}

.sgi__actions-dropdown {
  flex-basis: 6.3em;
  justify-content: flex-end;
  margin-left: auto;
  display: flex;
}

.sgi__actions-dropdown .button-dropdown-content {
  max-width: 200px;
}

.sgi__actions-dropdown .button-dropdown-content__list-item {
  white-space: normal;
  padding: 1.4em;
}

button.sgi__actions-dropdown-btn {
  box-shadow: none;
  color: #374151;
  cursor: default;
  opacity: .75;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  padding: 0;
  font-size: 0;
}

button.sgi__actions-dropdown-btn:active, button.sgi__actions-dropdown-btn:focus, button.sgi__actions-dropdown-btn:hover {
  transform: none;
}

button.sgi__actions-dropdown-btn:hover {
  color: #374151;
}

button.sgi__actions-dropdown-btn:active, button.sgi__actions-dropdown-btn:focus {
  outline: none;
}

button.sgi__actions-dropdown-btn:before {
  color: #374151;
  cursor: pointer;
  opacity: .75;
  margin: -.5em;
  padding: .5em;
  font-family: "Font Awesome 6 Pro";
  font-size: 1.42rem;
  font-weight: normal;
}

@media (min-width: 600px) {
  .sgi--theme {
    flex-wrap: nowrap;
    padding: 2.14em;
  }

  .sgi {
    align-items: flex-start;
    padding-right: 1.725em;
  }

  .sgi.sgi--theme.sgi--no-body {
    align-items: center;
  }

  .sgi--topic {
    align-items: center;
    padding: 1.43em 1.43em 1.43em 2.14em;
  }

  .sgi--subtopic, .sgi--topic + .sgi--question, .sgi--topic + .sgi--question ~ .sgi--question {
    align-items: center;
    padding: 1.43em 1.43em 1.43em 4.28em;
  }

  .sgi--subtopic + .sgi--question, .sgi--subtopic + .sgi--question ~ .sgi--question {
    padding: 1.43em 1.43em 1.43em 6.4em;
  }

  .sgi__logo-img-wrapper {
    border: 1px solid #e5e7eb;
    border-radius: 100%;
    flex-shrink: 0;
    flex-basis: 90px;
    height: 90px;
    margin-right: 2.14em;
    padding: 1em;
  }

  .sgi__logo-img-wrapper img {
    max-width: 60px;
    max-height: 60px;
  }

  .sgi__logo-img-wrapper:not(.sgi__logo-img-wrapper--theme) {
    display: none;
  }

  .sgi__content {
    flex-flow: wrap;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
  }

  .sgi__body {
    order: 100;
    max-width: 620px;
    margin: .35em 0 0;
    font-size: 15px;
    display: block;
  }

  .sgi__body:empty {
    margin: 0;
  }

  .sgi__heading--topic, .sgi__heading--subtopic, .sgi__heading--question {
    font-size: 1.2em;
  }

  .sgi__actions {
    margin-left: 20px;
  }

  .sgi__metadata {
    flex-basis: 0;
    margin-left: auto;
  }
}
