.snippet-selection-renderer {
  cursor: default;
  font-size: 16px;
}

.snippet-selection-renderer div.paragraph {
  margin-bottom: .5em;
  line-height: 2;
}

.snippet-selection-question__input-area:not(.snippet-selection-question__input-area--interactive) {
  pointer-events: none;
}

.snippet-selection-editor .snippet-selection-option, .snippet-selection-question__input-area--interactive .snippet-selection-option {
  cursor: pointer;
}

.snippet-selection-editor .snippet-selection-option:hover, .snippet-selection-question__input-area--interactive .snippet-selection-option:hover {
  background-color: #fdf1b4;
}

@media (hover: none) {
  .snippet-selection-editor .snippet-selection-option:hover:not(.snippet-selection-option--selected), .snippet-selection-question__input-area--interactive .snippet-selection-option:hover:not(.snippet-selection-option--selected) {
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0);
  }
}

.snippet-selection-option {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 2px solid rgba(0, 0, 0, 0);
  margin-bottom: .25em;
  padding: .15em 0 calc(.15em + 1px);
}

.snippet-selection-option .fa {
  margin-left: .25em;
}

.snippet-selection-option--selected {
  background-color: rgba(80, 169, 226, .15);
  border-color: #50a9e2;
}

.snippet-selection-option--correct-option:not(.snippet-selection-option--selected) {
  border-color: #4b5563;
}

.snippet-selection-editor__snippet-everything-wrapper {
  text-align: right;
  margin-bottom: 1em;
  font-size: 16px;
}

.snippet-selection-editor-preview-helper table {
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;
  max-width: 100%;
  margin: 20px auto;
  overflow: auto;
}

.snippet-selection-editor-preview-helper th {
  background-color: var(--background-primary);
  border-top: 1px solid var(--border-primary);
}

.snippet-selection-editor-preview-helper tbody tr {
  background-color: var(--background-subtle);
}

.snippet-selection-editor-preview-helper tbody tr:nth-child(2n) {
  background-color: var(--background-primary);
}

.snippet-selection-editor-preview-helper tr, .snippet-selection-editor-preview-helper th {
  box-shadow: inset 0 -1px 0 var(--border-primary);
}

.snippet-selection-editor-preview-helper tbody:first-child tr:first-child {
  box-shadow: inset 0 -1px 0 var(--border-primary), inset 0 1px 0 var(--border-primary);
}

.snippet-selection-editor-preview-helper th, .snippet-selection-editor-preview-helper td {
  border-right: 1px solid var(--border-primary);
  text-align: left;
  padding: 20px 20px 21px;
}

.snippet-selection-editor-preview-helper th:first-child, .snippet-selection-editor-preview-helper td:first-child {
  border-left: 1px solid var(--border-primary);
}

.snippet-selection-editor-preview-helper th {
  white-space: nowrap;
  font-size: 1.1em;
  font-weight: bold;
}

.snippet-selection-editor-preview-helper th::selection {
  background-color: rgba(0, 0, 0, 0);
}

.snippet-selection-editor-preview-helper th ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.snippet-selection-editor-preview-helper p, .snippet-selection-editor-preview-helper a, .snippet-selection-editor-preview-helper ul, .snippet-selection-editor-preview-helper ol, .snippet-selection-editor-preview-helper li, .snippet-selection-editor-preview-helper h1, .snippet-selection-editor-preview-helper h2, .snippet-selection-editor-preview-helper h3, .snippet-selection-editor-preview-helper h4, .snippet-selection-editor-preview-helper h5, .snippet-selection-editor-preview-helper h6, .snippet-selection-editor-preview-helper blockquote, .snippet-selection-editor-preview-helper figcaption {
  -webkit-user-select: none;
  user-select: none;
}

.snippet-selection-editor-preview-helper a:not([class]) {
  color: var(--content-link);
}

.snippet-selection-editor-preview-helper a:not([class]):not(:hover) {
  text-decoration: none;
}

.snippet-selection-editor-preview-helper a:not([class]):hover, .snippet-selection-editor-preview-helper a:not([class]):visited {
  color: #1e7cb8;
}

.snippet-selection-editor-preview-helper small {
  line-height: 1.2;
}

.snippet-selection-editor-preview-helper img {
  max-width: 100%;
}

.snippet-selection-editor-preview-helper p, .snippet-selection-editor-preview-helper li {
  color: var(--content-primary);
}

@media (max-width: 600px) {
  .snippet-selection-editor-preview-helper ul, .snippet-selection-editor-preview-helper ol {
    margin-left: 1rem;
    padding-left: 0;
  }
}

.snippet-selection-editor-preview-helper li {
  margin-bottom: .5em;
}

.snippet-selection-editor-preview-helper strong {
  font-family: Lato, sans-serif;
  font-weight: 800;
}

.snippet-selection-editor-preview-helper blockquote {
  margin: 0 0 1em;
  padding: 1em;
}

@media (max-width: 600px) {
  .snippet-selection-editor-preview-helper blockquote {
    margin: 1em 1.5em;
  }
}

.snippet-selection-editor-preview-helper pre {
  background-color: var(--background-subtle);
  color: var(--content-secondary);
  border-radius: .25em;
  max-width: 100%;
  padding: .5em;
  font-family: Cousine, Courier, monospace;
  line-height: 1.4;
}

.snippet-selection-editor-preview-helper p code, .snippet-selection-editor-preview-helper .paragraph code {
  background-color: var(--background-bold);
  border-radius: .25em;
  margin: 0 .25em;
  padding: 0 .25em;
  display: inline-block;
}

.snippet-selection-editor-preview-helper pre code[class^="lang"] {
  color: #ccc;
  background: #2d2d2d;
  padding: .75em 1em;
  display: block;
  overflow-x: auto;
}

.snippet-selection-editor-preview-helper hr {
  background-color: var(--background-subtle);
  border: 0;
  height: 1px;
}

.snippet-selection-editor-preview-helper [class$="question-content"] {
  margin-bottom: 2em;
}

.snippet-selection-editor-preview-helper .scroll-wrapper {
  max-width: 100%;
  overflow: auto;
}

.snippet-selection-editor-preview-helper .question-prompt, .snippet-selection-editor-preview-helper .question-answer-area {
  margin-bottom: 2rem;
  padding: 0 2rem;
}

@media (max-width: 900px) {
  .snippet-selection-editor-preview-helper .question-prompt, .snippet-selection-editor-preview-helper .question-answer-area {
    padding: 0 1rem;
  }
}

.snippet-selection-editor-preview-helper .question__prompt-heading {
  margin: 20px 0;
  font-size: 1.2em;
}

.snippet-selection-editor-preview-helper .MathJax {
  color: var(--content-primary);
}

.snippet-selection-editor-preview-helper .MathJax_Display {
  margin: 0;
}

.snippet-selection-option__correctness-indicator {
  display: inline-block;
}
