.sg-dropdown .a-form-input {
  margin: 0;
}

.sg-dropdown__clear-button {
  color: #9ca3af;
  z-index: 9;
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  bottom: 0;
  right: 0;
}

.sg-dropdown__dropdown-list-wrapper {
  z-index: 999;
  background-color: #fff;
  border-radius: 4px;
  width: 100%;
  padding: 0;
  font-size: 16px;
  transition: all .1s ease-in-out;
  position: absolute;
  top: calc(100% + 2px);
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, .18);
}

.sg-dropdown__dropdown-list-no-results {
  text-align: center;
  margin: 0;
  padding: 1em;
  font-weight: bold;
}

.sg-dropdown__dropdown-list-loader {
  padding: 1em;
}

.sg-dropdown__dropdown-list {
  max-height: 40vh;
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow: auto;
}

.sg-dropdown__dropdown {
  cursor: default;
  border-bottom: 1px solid #f3f4f6;
  padding: 12px;
}

.sg-dropdown__dropdown::selection {
  background-color: rgba(0, 0, 0, 0);
}

.sg-dropdown__dropdown ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.sg-dropdown__dropdown:hover, .sg-dropdown__dropdown:focus {
  color: #fff;
  background-color: #50a9e2;
  outline-width: 0;
}

.sg-dropdown__dropdown:last-child {
  border-bottom: 0;
}

.sg-dropdown__bottom-message {
  pointer-events: none;
  text-align: center;
  background-color: #f9fafb;
  padding: .25em .25em .3em;
}

.sg-dropdown__bottom-message::selection {
  background-color: rgba(0, 0, 0, 0);
}

.sg-dropdown__bottom-message ::selection {
  background-color: rgba(0, 0, 0, 0);
}
