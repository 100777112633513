.markdown-renderer-v2 {
  overflow: auto;
}

.markdown-renderer-v2 table {
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;
  max-width: 100%;
  margin: 20px auto;
  overflow: auto;
}

.markdown-renderer-v2 th {
  background-color: var(--background-primary);
  border-top: 1px solid var(--border-primary);
}

.markdown-renderer-v2 tbody tr {
  background-color: var(--background-subtle);
}

.markdown-renderer-v2 tbody tr:nth-child(2n) {
  background-color: var(--background-primary);
}

.markdown-renderer-v2 tr, .markdown-renderer-v2 th {
  box-shadow: inset 0 -1px 0 var(--border-primary);
}

.markdown-renderer-v2 tbody:first-child tr:first-child {
  box-shadow: inset 0 -1px 0 var(--border-primary), inset 0 1px 0 var(--border-primary);
}

.markdown-renderer-v2 th, .markdown-renderer-v2 td {
  border-right: 1px solid var(--border-primary);
  text-align: left;
  padding: 20px 20px 21px;
}

.markdown-renderer-v2 th:first-child, .markdown-renderer-v2 td:first-child {
  border-left: 1px solid var(--border-primary);
}

.markdown-renderer-v2 th {
  white-space: nowrap;
  font-size: 1.1em;
  font-weight: bold;
}

.markdown-renderer-v2 th::selection {
  background-color: rgba(0, 0, 0, 0);
}

.markdown-renderer-v2 th ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.markdown-renderer-v2 .markdown-table-wrapper {
  max-width: 100%;
  overflow: inherit;
}

.markdown-renderer-v2 .markdown-table-wrapper .fitb-choices {
  position: relative;
}

.markdown-renderer-v2 .center {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.markdown-renderer-v2 .center > * {
  max-width: 100%;
}

.markdown-renderer-v2 .paragraph {
  margin-bottom: 1em;
}

.markdown-renderer-v2 > .paragraph:last-child {
  margin-bottom: 0;
}

.markdown-renderer-v2 img {
  max-width: 100%;
}
