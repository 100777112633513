.o-table__row {
  flex-direction: row;
  flex-grow: 1;
  display: flex;
}

.o-table__row:hover .o-table__cell {
  background: var(--background-subtle);
}

.o-table__row.o-table__row--selected .o-table__cell {
  background: var(--background-brand-subtle);
}

.o-table__row.o-table__row--link:hover .o-table__cell {
  background: var(--background-brand-subtle-hover);
  cursor: pointer;
}

.o-table__row.o-table__row--link:active .o-table__cell {
  background: var(--background-brand-subtle-pressed);
}

.o-table--data .o-table__row:not(:last-child) {
  border-bottom: 1px solid var(--border-primary);
}

.o-table--list thead .o-table__row .o-table__header {
  background: #fff;
}

.o-table--list tbody .o-table__row {
  flex-wrap: wrap;
  margin-top: -1px;
}

.o-table--list tbody .o-table__row:not(:last-child):after {
  box-shadow: inset 0 -1px var(--border-primary);
  content: "";
  width: calc(100% - 26px);
  height: 1px;
  margin: 0 auto;
}

.o-table--list .o-table__row.o-table__row--selected:not(:last-child):after, .o-table--list .o-table__row:not(:last-child):hover:after {
  box-shadow: none;
}

.o-table--basic .o-table__row:not(:last-child) {
  border-bottom: 1px solid var(--border-primary);
}

.o-table--basic:hover .o-table__cell {
  background: inherit;
}
