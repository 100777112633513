.free-entry-editor hr {
  border: 0;
  border-top: 2px solid var(--border-subtle);
  width: 100%;
  max-width: 35em;
  margin: 2em 0;
  display: block;
}

.free-entry-editor .unbutton {
  color: #50a9e2;
}

.free-entry-editor .unbutton:hover {
  color: #1a699e;
}

.free-entry-editor__threshold {
  width: 100%;
  max-width: 10em;
  margin-top: 1em;
}

.free-entry-editor__inputs {
  max-width: 480px;
}

.free-entry-editor__prompt, .free-entry-editor__input-answer-item {
  align-items: flex-end;
  margin-top: 2em;
  margin-bottom: .5em;
  display: flex;
}

.free-entry-editor__prompt .fa, .free-entry-editor__input-answer-item .fa {
  padding: .9em 1.2em;
}

.free-entry-editor__prompt {
  flex-wrap: wrap;
}

.free-entry-editor__prompt-input {
  width: calc(100% - 4.185em);
}

.sg-button.free-entry-editor__delete-btn {
  margin-right: 0;
}

.sg-button.free-entry-editor__add-answer-btn, .sg-button.free-entry-editor__add-supplement-btn {
  padding: 0;
  text-decoration: underline;
}

.sg-button.free-entry-editor__add-supplement-btn {
  margin-top: .5em;
}

.free-entry-editor__threshold-acceptance {
  color: #9ca3af;
  font-style: italic;
  font-weight: bold;
  position: absolute;
  right: 0;
}

.free-entry-editor__inputs-answers {
  padding-left: 1.5em;
}
