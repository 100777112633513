:root {
  --color-neutral-0: #fff;
  --color-neutral-50: #f9f9fb;
  --color-neutral-100: #f3f4f6;
  --color-neutral-150: #ecedf1;
  --color-neutral-200: #e5e7eb;
  --color-neutral-250: #dbdee3;
  --color-neutral-300: #d1d5db;
  --color-neutral-350: #b7bcc5;
  --color-neutral-400: #9ca3af;
  --color-neutral-450: #838a97;
  --color-neutral-500: #777e8b;
  --color-neutral-550: #6b7280;
  --color-neutral-600: #4b5563;
  --color-neutral-650: #374151;
  --color-neutral-700: #2b3544;
  --color-neutral-750: #1f2937;
  --color-neutral-800: #1a2331;
  --color-neutral-850: #151c2b;
  --color-neutral-900: #0f1624;
  --color-neutral-950: #0e1421;
  --color-neutral-1000: #0a0f1a;
  --color-brand-50: #ebf5fa;
  --color-brand-100: #d3eaf8;
  --color-brand-200: #a8d4f0;
  --color-brand-300: #7cbfe9;
  --color-brand-400: #50a9e2;
  --color-brand-500: #1e7cb8;
  --color-brand-600: #1a699e;
  --color-brand-700: #155884;
  --color-brand-800: #104060;
  --color-brand-900: #0b2f46;
  --color-red-50: #fbefef;
  --color-red-100: #f7d7d4;
  --color-red-200: #eb968e;
  --color-red-300: #e36e63;
  --color-red-400: #dc4538;
  --color-red-500: #cc3224;
  --color-red-600: #9c251c;
  --color-red-700: #821f17;
  --color-red-800: #5f1711;
  --color-red-900: #45110c;
  --color-yellow-50: #fffbeb;
  --color-yellow-100: #fef3c7;
  --color-yellow-200: #fde68a;
  --color-yellow-300: #fcd34d;
  --color-yellow-400: #fbbf24;
  --color-yellow-500: #f59e0b;
  --color-yellow-600: #d97706;
  --color-yellow-700: #b45309;
  --color-yellow-800: #92400e;
  --color-yellow-900: #78350f;
  --color-green-50: #ecfdf5;
  --color-green-100: #d1fae5;
  --color-green-200: #a7f3d0;
  --color-green-300: #6ee7b7;
  --color-green-400: #34d399;
  --color-green-500: #10b981;
  --color-green-600: #059669;
  --color-green-700: #047857;
  --color-green-800: #065f46;
  --color-green-900: #064e3b;
  --color-blue-50: #ebf5fa;
  --color-blue-100: #d3eaf8;
  --color-blue-200: #a8d4f0;
  --color-blue-300: #7cbfe9;
  --color-blue-400: #50a9e2;
  --color-blue-500: #1e7cb8;
  --color-blue-600: #1a699e;
  --color-blue-700: #155884;
  --color-blue-800: #104060;
  --color-blue-900: #0b2f46;
  --color-purple-50: #f8f3fb;
  --color-purple-100: #e7d8f3;
  --color-purple-200: #ceb1e7;
  --color-purple-300: #b68adb;
  --color-purple-400: #9d63cf;
  --color-purple-500: #7d39b7;
  --color-purple-600: #652e94;
  --color-purple-700: #502475;
  --color-purple-800: #3a1b56;
  --color-purple-900: #2b133e;
  --color-data-purple-100: #f9d3ff;
  --color-data-purple-200: #e1a5ea;
  --color-data-purple-500: #a639b7;
  --color-data-purple-700: #752482;
  --color-data-green-100: #c9f797;
  --color-data-green-200: #a4e35f;
  --color-data-green-500: #61ab10;
  --color-data-green-700: #3f6f09;
  --color-data-blue-100: #d8d9fb;
  --color-data-blue-200: #9499eb;
  --color-data-blue-500: #6a71e5;
  --color-data-blue-700: #3039e3;
  --color-data-orange-100: #ffd9c8;
  --color-data-orange-200: #ffb08c;
  --color-data-orange-500: #ff7c43;
  --color-data-orange-700: #bd4714;
  --color-data-red-100: #ffcbdf;
  --color-data-red-200: #ff8bb8;
  --color-data-red-500: #d8286b;
  --color-data-red-700: #a20e46;
  --color-legacy-social-studies-50: #fcf1ec;
  --color-legacy-social-studies-100: #fae4db;
  --color-legacy-social-studies-200: #f4c1ae;
  --color-legacy-social-studies-300: #ef9f80;
  --color-legacy-social-studies-400: #e97d53;
  --color-legacy-social-studies-500: #e25a26;
  --color-legacy-social-studies-600: #c24819;
  --color-legacy-social-studies-700: #873211;
  --color-legacy-social-studies-800: #712a0f;
  --color-legacy-social-studies-900: #481a09;
  --color-legacy-world-language-50: #f8ebf0;
  --color-legacy-world-language-100: #f1d2dd;
  --color-legacy-world-language-200: #e4acc0;
  --color-legacy-world-language-300: #d885a2;
  --color-legacy-world-language-400: #cc5e85;
  --color-legacy-world-language-500: #bb3c69;
  --color-legacy-world-language-600: #9c3258;
  --color-legacy-world-language-700: #7d2846;
  --color-legacy-world-language-800: #5e1e35;
  --color-legacy-world-language-900: #3f1424;
  --color-legacy-computer-science-50: #fdf6eb;
  --color-legacy-computer-science-100: #fbe5c4;
  --color-legacy-computer-science-200: #f8c881;
  --color-legacy-computer-science-300: #f5b350;
  --color-legacy-computer-science-400: #f29a16;
  --color-legacy-computer-science-500: #d5840b;
  --color-legacy-computer-science-600: #ae6c09;
  --color-legacy-computer-science-700: #915a07;
  --color-legacy-computer-science-800: #6a4205;
  --color-legacy-computer-science-900: #4d3004;
  --color-legacy-math-50: #ecf9fc;
  --color-legacy-math-100: #c8eff8;
  --color-legacy-math-200: #89ddf0;
  --color-legacy-math-300: #40c9e7;
  --color-legacy-math-400: #1aafd0;
  --color-legacy-math-500: #1489a3;
  --color-legacy-math-600: #1489a3;
  --color-legacy-math-700: #117287;
  --color-legacy-math-800: #0c5463;
  --color-legacy-math-900: #093d48;
  --color-legacy-science-50: #f1eff9;
  --color-legacy-science-100: #d7d1ef;
  --color-legacy-science-200: #a89cdc;
  --color-legacy-science-300: #7d6bcb;
  --color-legacy-science-400: #6049bf;
  --color-legacy-science-500: #553fb5;
  --color-legacy-science-600: #402f87;
  --color-legacy-science-700: #352771;
  --color-legacy-science-800: #271d53;
  --color-legacy-science-900: #1c153c;
  --color-legacy-ela-50: #f9f0f9;
  --color-legacy-ela-100: #eed2ee;
  --color-legacy-ela-200: #da9eda;
  --color-legacy-ela-300: #c86ec8;
  --color-legacy-ela-400: #bb4dbb;
  --color-legacy-ela-500: #9b3a9b;
  --color-legacy-ela-600: #853285;
  --color-legacy-ela-700: #6f296f;
  --color-legacy-ela-800: #511e51;
  --color-legacy-ela-900: #3b163b;
  --color-legacy-other-50: #f9f5f0;
  --color-legacy-other-100: #eee1d2;
  --color-legacy-other-200: #dbbe9e;
  --color-legacy-other-300: #c99e6d;
  --color-legacy-other-400: #bd884b;
  --color-legacy-other-500: #b37e41;
  --color-legacy-other-600: #865e31;
  --color-legacy-other-700: #6f4f29;
  --color-legacy-other-800: #52391e;
  --color-legacy-other-900: #3b2a15;
  --text-xs: 12px;
  --text-s: 14px;
  --text-m: 16px;
  --text-l: 18px;
  --text-xl: 20px;
  --text-xxl: 24px;
  --heading-2xs: 14px;
  --heading-xs: 16px;
  --heading-s: 20px;
  --heading-m: 24px;
  --heading-l: 28px;
  --heading-xl: 34px;
  --space-x0: 0px;
  --space-x0-quarter: 2px;
  --space-x0-half: 4px;
  --space-x1: 8px;
  --space-x1-quarter: 10px;
  --space-x1-half: 12px;
  --space-x2: 16px;
  --space-x2-half: 20px;
  --space-x3: 24px;
  --space-x4: 32px;
  --space-x5: 40px;
  --space-x6: 48px;
  --space-x6-quarter: 50px;
  --space-x7: 56px;
  --space-x8: 64px;
  --space-x9: 72px;
  --space-x10: 80px;
}

:root {
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light;
  --content-primary: var(--lightningcss-light, var(--color-neutral-800)) var(--lightningcss-dark, var(--color-neutral-100));
  --content-primary-inverse: var(--lightningcss-light, var(--color-neutral-0)) var(--lightningcss-dark, var(--color-neutral-300));
  --content-primary-inverse-static: var(--color-neutral-0);
  --content-secondary: var(--lightningcss-light, var(--color-neutral-600)) var(--lightningcss-dark, var(--color-neutral-300));
  --content-secondary-inverse: var(--color-neutral-300);
  --content-tertiary: var(--lightningcss-light, var(--color-neutral-500)) var(--lightningcss-dark, var(--color-neutral-450));
  --content-tertiary-inverse: var(--color-neutral-400);
  --content-link: var(--color-blue-500);
  --content-link-pressed: var(--color-blue-600);
  --content-link-hover: var(--color-blue-700);
  --content-negative: var(--lightningcss-light, var(--color-red-500)) var(--lightningcss-dark, var(--color-red-400));
  --content-positive: var(--lightningcss-light, var(--color-green-700)) var(--lightningcss-dark, var(--color-green-500));
  --content-warning: var(--color-yellow-700);
  --content-brand: var(--lightningcss-light, var(--color-blue-600)) var(--lightningcss-dark, var(--color-blue-400));
  --content-info: var(--lightningcss-light, var(--color-blue-700)) var(--lightningcss-dark, var(--color-blue-400));
  --background-primary: var(--lightningcss-light, var(--color-neutral-0)) var(--lightningcss-dark, var(--color-neutral-1000));
  --background-primary-hover: var(--lightningcss-light, var(--color-neutral-100)) var(--lightningcss-dark, var(--color-neutral-800));
  --background-primary-pressed: var(--lightningcss-light, var(--color-neutral-150)) var(--lightningcss-dark, var(--color-neutral-750));
  --background-subtle: var(--lightningcss-light, var(--color-neutral-50)) var(--lightningcss-dark, var(--color-neutral-800));
  --background-subtle-hover: var(--lightningcss-light, var(--color-neutral-100)) var(--lightningcss-dark, var(--color-neutral-750));
  --background-subtle-pressed: var(--lightningcss-light, var(--color-neutral-150)) var(--lightningcss-dark, var(--color-neutral-700));
  --background-bold: var(--lightningcss-light, var(--color-neutral-150)) var(--lightningcss-dark, var(--color-neutral-700));
  --background-bold-hover: var(--lightningcss-light, var(--color-neutral-200)) var(--lightningcss-dark, var(--color-neutral-650));
  --background-bold-pressed: var(--lightningcss-light, var(--color-neutral-250)) var(--lightningcss-dark, var(--color-neutral-600));
  --background-brand: var(--lightningcss-light, var(--color-brand-500)) var(--lightningcss-dark, var(--color-brand-500));
  --background-brand-hover: var(--lightningcss-light, var(--color-brand-600)) var(--lightningcss-dark, var(--color-brand-600));
  --background-brand-pressed: var(--lightningcss-light, var(--color-brand-700)) var(--lightningcss-dark, var(--color-brand-700));
  --background-brand-subtle: var(--lightningcss-light, var(--color-brand-50)) var(--lightningcss-dark, var(--color-brand-900));
  --background-brand-subtle-hover: var(--lightningcss-light, var(--color-blue-100)) var(--lightningcss-dark, var(--color-blue-800));
  --background-brand-subtle-pressed: var(--lightningcss-light, var(--color-blue-200)) var(--lightningcss-dark, var(--color-blue-700));
  --background-positive-subtle: var(--lightningcss-light, var(--color-green-50)) var(--lightningcss-dark, var(--color-green-800));
  --background-positive-subtle-hover: var(--color-green-100);
  --background-positive-subtle-pressed: var(--color-green-200);
  --background-positive: var(--color-green-700);
  --background-positive-hover: var(--color-green-800);
  --background-positive-pressed: var(--color-green-900);
  --background-negative-subtle: var(--lightningcss-light, var(--color-red-50)) var(--lightningcss-dark, var(--color-red-900));
  --background-negative-subtle-hover: var(--color-red-100);
  --background-negative-subtle-pressed: var(--color-red-200);
  --background-negative: var(--color-red-500);
  --background-negative-hover: var(--color-red-600);
  --background-negative-pressed: var(--color-red-700);
  --background-info: var(--color-blue-500);
  --background-info-hover: var(--color-blue-600);
  --background-info-pressed: var(--color-blue-700);
  --background-info-subtle: var(--lightningcss-light, var(--color-blue-50)) var(--lightningcss-dark, var(--color-blue-900));
  --background-info-subtle-hover: var(--color-blue-100);
  --background-info-subtle-pressed: var(--color-blue-200);
  --background-warning: var(--color-yellow-700);
  --background-warning-hover: var(--color-yellow-800);
  --background-warning-pressed: var(--color-yellow-900);
  --background-warning-subtle: var(--color-yellow-50);
  --background-warning-subtle-hover: var(--color-yellow-100);
  --background-warning-subtle-pressed: var(--color-yellow-200);
  --background-disabled: var(--color-neutral-50);
  --background-primary-inverse: var(--color-neutral-800);
  --background-domain-default: var(--color-brand-500);
  --background-domain-social-studies: var(--color-legacy-social-studies-600);
  --background-domain-world-language: var(--color-legacy-world-language-500);
  --background-domain-computer-science: var(--color-legacy-computer-science-500);
  --background-domain-math: var(--color-legacy-math-500);
  --background-domain-science: var(--color-legacy-science-500);
  --background-domain-ela: var(--color-legacy-ela-500);
  --background-domain-other: var(--color-legacy-other-500);
  --border-primary: var(--lightningcss-light, var(--color-neutral-300)) var(--lightningcss-dark, var(--color-neutral-650));
  --border-subtle: var(--lightningcss-light, var(--color-neutral-200)) var(--lightningcss-dark, var(--color-neutral-700));
  --border-bold: var(--lightningcss-light, var(--color-neutral-400)) var(--lightningcss-dark, var(--color-neutral-500));
  --border-positive: var(--color-green-600);
  --border-positive-bold: var(--color-green-700);
  --border-negative: var(--color-red-500);
  --border-negative-bold: var(--color-red-600);
  --border-warning: var(--color-yellow-500);
  --border-info: var(--color-blue-500);
  --border-brand: var(--color-blue-500);
  --border-selected: var(--color-blue-500);
  --data-mastery: var(--color-data-purple-500);
  --data-excelling: var(--color-data-blue-500);
  --data-proficient: var(--color-data-green-500);
  --data-passing: var(--color-data-orange-500);
  --data-struggling: var(--color-data-red-500);
  --data-na: var(--color-neutral-400);
  --data-mastery-bold: var(--color-data-purple-700);
  --data-excelling-bold: var(--color-data-blue-700);
  --data-proficient-bold: var(--color-data-green-700);
  --data-passing-bold: var(--color-data-orange-700);
  --data-struggling-bold: var(--color-data-red-700);
  --data-na-bold: var(--color-neutral-600);
  --data-mastery-subtle: var(--color-data-purple-100);
  --data-excelling-subtle: var(--color-data-blue-100);
  --data-proficient-subtle: var(--color-data-green-100);
  --data-passing-subtle: var(--color-data-orange-100);
  --data-struggling-subtle: var(--color-data-red-100);
  --data-na-subtle: var(--color-neutral-200);
  --elevation-background-l0: var(--lightningcss-light, var(--color-neutral-0)) var(--lightningcss-dark, var(--color-neutral-1000));
  --elevation-background-l1: var(--lightningcss-light, var(--color-neutral-0)) var(--lightningcss-dark, var(--color-neutral-900));
  --elevation-background-l2: var(--lightningcss-light, var(--color-neutral-0)) var(--lightningcss-dark, var(--color-neutral-850));
  --elevation-background-l3: var(--lightningcss-light, var(--color-neutral-0)) var(--lightningcss-dark, var(--color-neutral-800));
  --elevation-background-l4: var(--lightningcss-light, var(--color-neutral-0)) var(--lightningcss-dark, var(--color-neutral-750));
  --elevation-background-l5: var(--lightningcss-light, var(--color-neutral-0)) var(--lightningcss-dark, var(--color-neutral-700));
  --elevation-background-l6: var(--lightningcss-light, var(--color-neutral-0)) var(--lightningcss-dark, var(--color-neutral-650));
  --elevation-shadow-l0: none;
  --elevation-shadow-l1: 0px 4px 6px rgba(35, 42, 47, .06), 0px 2px 2px rgba(35, 42, 47, .06);
  --elevation-shadow-l2: 0px 8px 16px rgba(35, 42, 47, .1), 0px 4px 4px rgba(35, 42, 47, .04);
  --elevation-shadow-l3: 0px 10px 20px rgba(35, 42, 47, .06), 0px 4px 6px rgba(35, 42, 47, .04);
  --elevation-shadow-l4: 0px 14px 24px rgba(35, 42, 47, .06), 0px 4px 12px rgba(35, 42, 47, .04);
  --elevation-shadow-l5: 0px 20px 40px rgba(35, 42, 47, .08), 0px 4px 16px rgba(35, 42, 47, .04);
  --elevation-shadow-l6: 0px 32px 64px rgba(35, 42, 47, .08), 0px 7px 20px rgba(35, 42, 47, .04);
  --overlay-35: rgba(10, 15, 26, .35);
  --overlay-70: rgba(10, 15, 26, .698);
  --overlay-100: var(--color-neutral-900);
  --duration-fast: .1s;
  --duration-medium: .25s;
  --duration-slow: .4s;
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  src: local(Lato Light), local(Lato-Light), url("~assets/fonts/Lato-Light.ttf") format("truetype");
}

@font-face {
  font-family: Lato;
  font-style: italic;
  font-weight: 300;
  src: local(Lato LightItalic), local(Lato-LightItalic), url("~assets/fonts/Lato-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  src: local(Lato Regular), local(Lato-Regular), url("~assets/fonts/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: Lato;
  font-style: italic;
  font-weight: 400;
  src: local(Lato Italic), local(Lato-Italic), url("~assets/fonts/Lato-Italic.ttf") format("truetype");
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  src: local(Lato Bold), local(Lato-Bold), url("~assets/fonts/Lato-Bold.ttf") format("truetype");
}

@font-face {
  font-family: Lato;
  font-style: italic;
  font-weight: 700;
  src: local(Lato BoldItalic), local(Lato-BoldItalic), url("~assets/fonts/Lato-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 900;
  src: local(Lato Black), local(Lato-Black), url("~assets/fonts/Lato-Black.ttf") format("truetype");
}

@font-face {
  font-family: All Round Gothic;
  font-style: normal;
  font-weight: 700;
  src: local(AllRoundGothic-Bold), url("~assets/fonts/AllRoundGothic-Bold-webfont.ttf") format("truetype");
}

@media (max-width: 600px) {
  #HW_frame_cont {
    display: none;
  }
}

.badge {
  color: #fff;
  background-color: #999;
  border-radius: 1em;
  padding: .4em .6em;
  font-weight: 600;
  line-height: 1;
  display: inline-block;
}

.badge-alert {
  color: #fff;
  background-color: #fff6bf;
  border-radius: 1em;
  padding: .4em .6em;
  font-weight: 600;
  line-height: 1;
  display: inline-block;
}

.badge-error {
  color: #fff;
  background-color: #fbe3e4;
  border-radius: 1em;
  padding: .4em .6em;
  font-weight: 600;
  line-height: 1;
  display: inline-block;
}

.badge-notice {
  color: #fff;
  background-color: #e5edf8;
  border-radius: 1em;
  padding: .4em .6em;
  font-weight: 600;
  line-height: 1;
  display: inline-block;
}

.badge-success {
  color: #fff;
  background-color: #e6efc2;
  border-radius: 1em;
  padding: .4em .6em;
  font-weight: 600;
  line-height: 1;
  display: inline-block;
}

.basic-table {
  border-spacing: 0;
  width: 100%;
  font-size: 14px;
}

.basic-table th:first-child, .basic-table td:first-child {
  padding-left: 1em;
}

.basic-table th:last-child, .basic-table td:last-child {
  padding-right: 1em;
}

.basic-table thead .fa {
  color: #d1d5db;
  cursor: pointer;
  margin-left: .5em;
}

.basic-table thead th {
  text-align: left;
  border-top: 1px solid #d1d5db;
  border-bottom: 1px solid #d1d5db;
  padding: .75em;
}

.basic-table tbody td {
  text-align: left;
  vertical-align: middle;
  border-bottom: 1px solid #d1d5db;
  padding: .75em;
  font-weight: normal;
}

.basic-table tbody tr:last-child td {
  border-bottom: 0;
}

.free-form-content.is-hidden {
  display: none;
}

.common-content {
  background-color: var(--background-primary);
  min-height: 100vh;
  padding: 120px 0 0;
}

.common-content--without-global-nav, .common-content--global-nav-not-sticky {
  padding: 0;
}

.common-content--white-bg {
  background-color: var(--background-primary);
}

.common-content--with-tertiary-header {
  min-height: 100vh;
  padding: 180px 0 0;
}

.common-content .home {
  margin-top: -120px;
}

.common-content .sign-up-wrapper, .common-content .reset-password-wrapper, .common-content .forgot-password-wrapper, .common-content .search-wrapper, .common-content .pricing-page, .common-content .quote-gen, .common-content .course-library, .common-content .reports-landing-page-wrapper, .common-content .global-assignments-wrapper, .common-content .storybert, .common-content .marketplace {
  margin-top: -60px;
}

.dropdown-container {
  width: 150px;
  margin-bottom: 1em;
  position: relative;
}

.dropdown-description {
  display: none;
}

.dropdown-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: var(--background-primary);
  border: 2px solid var(--border-primary);
  border-radius: 3px;
  width: 100%;
  height: 2.5rem;
  margin: 0;
  padding: 0 20px 2px .75em;
  font-family: Lato, Helvetica, Arial, sans-serif;
  font-size: 1em;
  line-height: 2.5em;
  transition: border 50ms ease-in-out;
  display: inline-block;
  position: relative;
}

.dropdown-button:focus, .dropdown-button:hover {
  transition: border 50ms ease-in-out;
}

.dropdown-button:focus {
  border-color: #a8d4f0;
  outline-width: 0;
}

.dropdown-button:hover {
  border-color: #a8d4f0;
}

.dropdown-button:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.dropdown-button:disabled:hover {
  border-color: #e5e7eb;
}

.dropdown-button:after {
  content: "";
  font-family: "Font Awesome 6 Pro";
  position: absolute;
  top: 50%;
  right: 4px;
  transform: translateY(-50%);
}

.dropdown-menu {
  z-index: 9;
  width: 100%;
  margin: 0;
  padding: 0;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.dropdown-menu.show-menu {
  display: block;
}

.dropdown-menu li {
  background-color: #f9fafb;
  width: 100%;
  padding: .75em 1em;
  line-height: 1.4;
  display: inline-block;
}

.dropdown-menu li:hover {
  background-color: #f3f4f6;
}

.dropdown-menu li:not(:last-child) {
  border-bottom: 1px solid #d1d5db;
  border-left: 1px solid #9ca3af;
  border-right: 1px solid #9ca3af;
}

.dropdown-menu li:last-child {
  border: 1px solid #9ca3af;
  border-top: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.modal {
  z-index: 9999;
  animation-name: opacityFadeIn;
  animation-duration: .3s;
  animation-timing-function: cubic-bezier(.23, 1, .32, 1);
  animation-fill-mode: forwards;
  position: fixed;
  inset: 0;
}

@keyframes opacityFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.modal__shade {
  background-color: rgba(0, 0, 0, .6);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
}

.modal__overflow-handler {
  max-height: 100vh;
  padding: 1.75em;
  overflow: auto;
}

@media (max-width: 600px) {
  .modal__overflow-handler {
    padding: .875em;
  }
}

.modal__wrapper {
  width: 100%;
  min-width: 25em;
  max-width: 550px;
  margin: 0 auto;
  position: relative;
}

@media (max-width: 600px) {
  .modal__wrapper {
    min-width: 100%;
  }
}

.modal__header {
  color: #374151;
  background: #f3f4f6;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  justify-content: space-between;
  min-height: 3.75em;
  padding: 1.25em 3.5em 1.25em 1.25em;
  font-size: 1.05rem;
  display: flex;
  position: relative;
}

.modal__header-label {
  flex-flow: wrap;
  align-items: center;
  padding: 0 .5em 0 0;
  font-weight: bold;
  display: flex;
}

.modal__header-close-btn {
  color: #d1d5db;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 1.1539em;
  font-size: 1.1375rem;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.modal__header-close-btn:hover, .modal__header-close-btn:focus {
  color: #374151;
}

.modal__content {
  color: #374151;
  background-color: #fff;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 1.5em;
  font-size: .875rem;
}

.modal__content label {
  font-weight: bolder;
}

.modal__content textarea {
  background-color: #fff;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  width: 100%;
  min-height: 100px;
  margin: .5em 0 1em;
  padding: .75em 1em;
}

.modal__content button:not(.unbutton) {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #fff;
  cursor: pointer;
  white-space: nowrap;
  background-color: #059669;
  border: 0;
  border-radius: .15em;
  padding: .85em 1em .8em;
  font-size: 1em;
  font-weight: bold;
  text-decoration: none;
  transition: box-shadow .1s ease-in-out, transform .1s ease-in-out;
}

.modal__content input[type="button"]:not(.unbutton) {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #fff;
  cursor: pointer;
  white-space: nowrap;
  background-color: #059669;
  border: 0;
  border-radius: .15em;
  padding: .85em 1em .8em;
  font-size: 1em;
  font-weight: bold;
  text-decoration: none;
  transition: box-shadow .1s ease-in-out, transform .1s ease-in-out;
}

.modal__content button:not(.unbutton)::-moz-selection {
  background-color: rgba(0, 0, 0, 0);
}

.modal__content input[type="button"]:not(.unbutton)::-moz-selection {
  background-color: rgba(0, 0, 0, 0);
}

.modal__content button:not(.unbutton)::selection {
  background-color: rgba(0, 0, 0, 0);
}

.modal__content input[type="button"]:not(.unbutton)::selection {
  background-color: rgba(0, 0, 0, 0);
}

.modal__content button:not(.unbutton) ::-moz-selection {
  background-color: rgba(0, 0, 0, 0);
}

.modal__content input[type="button"]:not(.unbutton) ::-moz-selection {
  background-color: rgba(0, 0, 0, 0);
}

.modal__content button:not(.unbutton) ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.modal__content input[type="button"]:not(.unbutton) ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.modal__content button:not(.unbutton).disabled, .modal__content button:not(.unbutton).disabled:hover, .modal__content button:not(.unbutton):disabled, .modal__content button:not(.unbutton):disabled:hover {
  color: #d1d5db;
  background-color: #6b7280;
}

.modal__content input[type="button"]:not(.unbutton).disabled {
  color: #d1d5db;
  background-color: #6b7280;
}

.modal__content input[type="button"]:not(.unbutton).disabled:hover {
  color: #d1d5db;
  background-color: #6b7280;
}

.modal__content input[type="button"]:not(.unbutton):disabled {
  color: #d1d5db;
  background-color: #6b7280;
}

.modal__content input[type="button"]:not(.unbutton):disabled:hover {
  color: #d1d5db;
  background-color: #6b7280;
}

.modal__content button:not(.unbutton):not(:disabled):not(:active):hover {
  transform: translateY(-1px)scale(1.01);
  box-shadow: 0 2px 9px rgba(0, 0, 0, .2);
}

.modal__content input[type="button"]:not(.unbutton):not(:disabled):not(:active):hover {
  transform: translateY(-1px)scale(1.01);
  box-shadow: 0 2px 9px rgba(0, 0, 0, .2);
}

.modal__content .modal__footer-actions {
  justify-content: flex-end;
  display: flex;
}

.modal__content .form-input-error {
  color: #dc4538;
  font-size: .85em;
}

.modal__content .form-input-error:first-letter {
  text-transform: uppercase;
}

.image-zoom-modal {
  text-align: center;
}

.image-zoom-modal .modal__overflow-handler {
  width: 100%;
  padding: 1rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.image-zoom-modal .modal__wrapper {
  width: auto;
  max-width: 100%;
  max-height: 100vh;
  display: inline-block;
}

.image-zoom-modal .modal__header {
  min-height: 1em;
  padding: 1.25em;
}

.image-zoom-modal .modal__content {
  padding: 1em;
}

.image-zoom-modal .modal__body {
  max-width: 100%;
}

.image-zoom-modal .image-supplement .a-card {
  background-color: #fff;
  border-color: #fff;
}

.image-zoom-modal .image-supplement img {
  max-width: 100%;
  max-height: 60vh;
}

.image-zoom-modal figcaption {
  color: #fff;
  background-color: rgba(0, 0, 0, .8);
  border-radius: 8px;
  width: 100%;
}

.question-feedback-modal-content .question-feedback-modal-content-feedback {
  max-height: 55vh;
  overflow: auto;
}

.question-feedback-modal-content .question-feedback-modal-content-item {
  background-color: #fff;
  border-radius: .5em;
  margin-bottom: 1em;
  padding: 1em;
}

.question-feedback-modal-content .question-feedback-modal-content-feedback-date {
  float: right;
  font-weight: 700;
}

.question-feedback-modal-content .question-feedback-modal-content-feedback-name {
  margin-bottom: .5em;
}

.question-feedback-modal-content .question-feedback-modal-content-feedback-name span:first-child {
  font-weight: 700;
}

.question-feedback-modal-content .question-feedback-modal-content-feedback-message p {
  margin: 0 0 .5em;
}

.question-feedback-modal-content .question-feedback-modal-content-feedback-message p:last-child {
  margin: 0;
}

.modal-feedback .issues-modal-error-message {
  color: #dc4538;
  float: left;
}

.modal-feedback .dropdown {
  margin-right: 1em;
  display: inline-block;
}

.fill-in-the-blank table {
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;
  max-width: 100%;
  margin: 20px auto;
  overflow: auto;
}

.fill-in-the-blank th {
  background-color: var(--background-primary);
  border-top: 1px solid var(--border-primary);
}

.fill-in-the-blank tbody tr {
  background-color: var(--background-subtle);
}

.fill-in-the-blank tbody tr:nth-child(2n) {
  background-color: var(--background-primary);
}

.fill-in-the-blank tr, .fill-in-the-blank th {
  box-shadow: inset 0 -1px 0 var(--border-primary);
}

.fill-in-the-blank tbody:first-child tr:first-child {
  box-shadow: inset 0 -1px 0 var(--border-primary), inset 0 1px 0 var(--border-primary);
}

.fill-in-the-blank th, .fill-in-the-blank td {
  border-right: 1px solid var(--border-primary);
  text-align: left;
  padding: 20px 20px 21px;
}

.fill-in-the-blank th:first-child, .fill-in-the-blank td:first-child {
  border-left: 1px solid var(--border-primary);
}

.fill-in-the-blank th {
  white-space: nowrap;
  font-size: 1.1em;
  font-weight: bold;
}

.fill-in-the-blank th::selection {
  background-color: rgba(0, 0, 0, 0);
}

.fill-in-the-blank th ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.fill-in-the-blank p, .fill-in-the-blank a, .fill-in-the-blank ul, .fill-in-the-blank ol, .fill-in-the-blank li, .fill-in-the-blank h1, .fill-in-the-blank h2, .fill-in-the-blank h3, .fill-in-the-blank h4, .fill-in-the-blank h5, .fill-in-the-blank h6, .fill-in-the-blank blockquote, .fill-in-the-blank figcaption {
  -webkit-user-select: none;
  user-select: none;
}

.fill-in-the-blank a:not([class]) {
  color: var(--content-link);
}

.fill-in-the-blank a:not([class]):not(:hover) {
  text-decoration: none;
}

.fill-in-the-blank a:not([class]):hover, .fill-in-the-blank a:not([class]):visited {
  color: #1e7cb8;
}

.fill-in-the-blank small {
  line-height: 1.2;
}

.fill-in-the-blank img {
  max-width: 100%;
}

.fill-in-the-blank p, .fill-in-the-blank li {
  color: var(--content-primary);
}

@media (max-width: 600px) {
  .fill-in-the-blank ul, .fill-in-the-blank ol {
    margin-left: 1rem;
    padding-left: 0;
  }
}

.fill-in-the-blank li {
  margin-bottom: .5em;
}

.fill-in-the-blank strong {
  font-family: Lato, sans-serif;
  font-weight: 800;
}

.fill-in-the-blank blockquote {
  margin: 0 0 1em;
  padding: 1em;
}

@media (max-width: 600px) {
  .fill-in-the-blank blockquote {
    margin: 1em 1.5em;
  }
}

.fill-in-the-blank pre {
  background-color: var(--background-subtle);
  color: var(--content-secondary);
  border-radius: .25em;
  max-width: 100%;
  padding: .5em;
  font-family: Cousine, Courier, monospace;
  line-height: 1.4;
}

.fill-in-the-blank p code, .fill-in-the-blank .paragraph code {
  background-color: var(--background-bold);
  border-radius: .25em;
  margin: 0 .25em;
  padding: 0 .25em;
  display: inline-block;
}

.fill-in-the-blank pre code[class^="lang"] {
  color: #ccc;
  background: #2d2d2d;
  padding: .75em 1em;
  display: block;
  overflow-x: auto;
}

.fill-in-the-blank hr {
  background-color: var(--background-subtle);
  border: 0;
  height: 1px;
}

.fill-in-the-blank [class$="question-content"] {
  margin-bottom: 2em;
}

.fill-in-the-blank .scroll-wrapper {
  max-width: 100%;
  overflow: auto;
}

.fill-in-the-blank .question-prompt, .fill-in-the-blank .question-answer-area {
  margin-bottom: 2rem;
  padding: 0 2rem;
}

@media (max-width: 900px) {
  .fill-in-the-blank .question-prompt, .fill-in-the-blank .question-answer-area {
    padding: 0 1rem;
  }
}

.fill-in-the-blank .question__prompt-heading {
  margin: 20px 0;
  font-size: 1.2em;
}

.fill-in-the-blank .MathJax {
  color: var(--content-primary);
}

.fill-in-the-blank .MathJax_Display {
  margin: 0;
}

.fill-in-the-blank-dropdown-passage {
  margin: 2rem 0;
  padding: 0 3rem;
}

.fill-in-the-blank-dropdown-passage div, .fill-in-the-blank-dropdown-passage p {
  line-height: 2.5em;
  display: inline;
}

.fill-in-the-blank-dropdown-passage p + p:before {
  content: "";
  width: 100%;
  height: .5em;
  display: block;
}

@media (max-width: 600px) {
  .fill-in-the-blank-dropdown-passage {
    margin-bottom: 1rem;
    padding: 0 1rem;
  }
}

div.fill-in-the-blank-dropdown-passage-dropdown {
  margin: 0 5px;
  display: inline-block;
  position: relative;
}

div.fill-in-the-blank-dropdown-passage-dropdown div, div.fill-in-the-blank-dropdown-passage-dropdown p {
  display: inline-block;
}

div.fill-in-the-blank-dropdown-passage-dropdown.is-open {
  z-index: 999;
}

.fill-in-the-blank-dropdown-passage-dropdown-container {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: var(--background-primary);
  border: 2px solid var(--border-primary);
  cursor: default;
  background-color: #fff;
  border-radius: 3px;
  min-width: 150px;
  height: 2em;
  padding: 0 20px 2px 5px;
  font-family: Lato, Helvetica, Arial, sans-serif;
  font-size: 1em;
  transition: border 50ms ease-in-out;
  position: relative;
  transform: translateY(3px);
}

.fill-in-the-blank-dropdown-passage-dropdown-container:focus, .fill-in-the-blank-dropdown-passage-dropdown-container:hover {
  transition: border 50ms ease-in-out;
}

.fill-in-the-blank-dropdown-passage-dropdown-container:focus {
  border-color: #a8d4f0;
  outline-width: 0;
}

.fill-in-the-blank-dropdown-passage-dropdown-container:hover {
  border-color: #a8d4f0;
}

.fill-in-the-blank-dropdown-passage-dropdown-container:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.fill-in-the-blank-dropdown-passage-dropdown-container:disabled:hover {
  border-color: #e5e7eb;
}

.fill-in-the-blank-dropdown-passage-dropdown-container:after {
  content: "";
  font-family: "Font Awesome 6 Pro";
  position: absolute;
  top: 50%;
  right: 4px;
  transform: translateY(-50%);
}

.fill-in-the-blank-dropdown-passage-dropdown-container.is-correct {
  background-color: #d1fae5;
  border-color: #059669;
}

.fill-in-the-blank-dropdown-passage-dropdown-container.is-incorrect {
  background-color: #fbefef;
  border-color: #dc4538;
}

.fill-in-the-blank-dropdown-passage-dropdown-container-menu {
  z-index: 99;
  position: absolute;
  top: calc(2em - 1px);
  left: 0;
}

.fill-in-the-blank-dropdown-passage-dropdown-container-menu > div {
  background-color: #f9fafb;
  min-width: 100%;
  padding: .75em 1em;
  line-height: 1.4;
  display: inline-block;
}

.fill-in-the-blank-dropdown-passage-dropdown-container-menu > div:hover {
  background-color: #f3f4f6;
}

.fill-in-the-blank-dropdown-passage-dropdown-container-menu > div:not(:last-child) {
  border-bottom: 1px solid #d1d5db;
  border-left: 1px solid #9ca3af;
  border-right: 1px solid #9ca3af;
}

.fill-in-the-blank-dropdown-passage-dropdown-container-menu > div:last-child {
  border: 1px solid #9ca3af;
  border-top: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

div.fill-in-the-blank-dropdown-passage-dropdown-container-selected-choice {
  width: 100%;
  line-height: 1em;
  transform: translateY(-.25em);
}

.fitb-question-response-stats__item {
  color: #4b5563;
  margin-bottom: 1em;
}

.fitb-question-response-stats__header {
  color: #4b5563;
  text-transform: uppercase;
  margin-bottom: .5em;
}

.fitb-question-response-stats__choice {
  margin-bottom: .5em;
  margin-left: .5em;
  padding-left: 1.5em;
  position: relative;
}

.fitb-question-response-stats__correct-answer, .fitb-question-response-stats__incorrect-answer {
  font-size: 1.25em;
  position: absolute;
  left: 0;
}

.fitb-question-response-stats__correct-answer {
  color: #059669;
}

.fitb-question-response-stats__incorrect-answer {
  color: #dc4538;
}

.fitb-question-response-stats__choice-percentage {
  color: #9ca3af;
  text-align: right;
  width: 3em;
  margin-right: 1.25em;
  display: inline-block;
}

.two-way-question table {
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;
  max-width: 100%;
  margin: 20px auto;
  overflow: auto;
}

.two-way-question th {
  background-color: var(--background-primary);
  border-top: 1px solid var(--border-primary);
}

.two-way-question tbody tr {
  background-color: var(--background-subtle);
}

.two-way-question tbody tr:nth-child(2n) {
  background-color: var(--background-primary);
}

.two-way-question tr, .two-way-question th {
  box-shadow: inset 0 -1px 0 var(--border-primary);
}

.two-way-question tbody:first-child tr:first-child {
  box-shadow: inset 0 -1px 0 var(--border-primary), inset 0 1px 0 var(--border-primary);
}

.two-way-question th, .two-way-question td {
  border-right: 1px solid var(--border-primary);
  text-align: left;
  padding: 20px 20px 21px;
}

.two-way-question th:first-child, .two-way-question td:first-child {
  border-left: 1px solid var(--border-primary);
}

.two-way-question th {
  white-space: nowrap;
  font-size: 1.1em;
  font-weight: bold;
}

.two-way-question th::selection {
  background-color: rgba(0, 0, 0, 0);
}

.two-way-question th ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.two-way-question p, .two-way-question a, .two-way-question ul, .two-way-question ol, .two-way-question li, .two-way-question h1, .two-way-question h2, .two-way-question h3, .two-way-question h4, .two-way-question h5, .two-way-question h6, .two-way-question blockquote, .two-way-question figcaption {
  -webkit-user-select: none;
  user-select: none;
}

.two-way-question a:not([class]) {
  color: var(--content-link);
}

.two-way-question a:not([class]):not(:hover) {
  text-decoration: none;
}

.two-way-question a:not([class]):hover, .two-way-question a:not([class]):visited {
  color: #1e7cb8;
}

.two-way-question small {
  line-height: 1.2;
}

.two-way-question img {
  max-width: 100%;
}

.two-way-question p, .two-way-question li {
  color: var(--content-primary);
}

@media (max-width: 600px) {
  .two-way-question ul, .two-way-question ol {
    margin-left: 1rem;
    padding-left: 0;
  }
}

.two-way-question li {
  margin-bottom: .5em;
}

.two-way-question strong {
  font-family: Lato, sans-serif;
  font-weight: 800;
}

.two-way-question blockquote {
  margin: 0 0 1em;
  padding: 1em;
}

@media (max-width: 600px) {
  .two-way-question blockquote {
    margin: 1em 1.5em;
  }
}

.two-way-question pre {
  background-color: var(--background-subtle);
  color: var(--content-secondary);
  border-radius: .25em;
  max-width: 100%;
  padding: .5em;
  font-family: Cousine, Courier, monospace;
  line-height: 1.4;
}

.two-way-question p code, .two-way-question .paragraph code {
  background-color: var(--background-bold);
  border-radius: .25em;
  margin: 0 .25em;
  padding: 0 .25em;
  display: inline-block;
}

.two-way-question pre code[class^="lang"] {
  color: #ccc;
  background: #2d2d2d;
  padding: .75em 1em;
  display: block;
  overflow-x: auto;
}

.two-way-question hr {
  background-color: var(--background-subtle);
  border: 0;
  height: 1px;
}

.two-way-question [class$="question-content"] {
  margin-bottom: 2em;
}

.two-way-question .scroll-wrapper {
  max-width: 100%;
  overflow: auto;
}

.two-way-question .question-prompt, .two-way-question .question-answer-area {
  margin-bottom: 2rem;
  padding: 0 2rem;
}

@media (max-width: 900px) {
  .two-way-question .question-prompt, .two-way-question .question-answer-area {
    padding: 0 1rem;
  }
}

.two-way-question .question__prompt-heading {
  margin: 20px 0;
  font-size: 1.2em;
}

.two-way-question .MathJax {
  color: var(--content-primary);
}

.two-way-question .MathJax_Display {
  margin: 0;
}

.two-way-question-content-table {
  flex-wrap: wrap;
  width: 100%;
  margin-top: 2em;
  display: flex;
}

.two-way-question-content-table p {
  margin: 0;
  line-height: 1.2;
}

.two-way-question-content-table-header {
  border-bottom: 1px solid #9ca3af;
  width: 50%;
  padding-bottom: .5em;
  display: flex;
}

.two-way-question-content-table-header p {
  color: #374151;
}

.two-way-question-content-table-rows-row-cell {
  align-items: center;
  padding: 1em 1em 1em 0;
  display: flex;
}

.two-way-question-content-table-header-left, .two-way-question-content-table-header-right {
  text-align: center;
  align-items: flex-end;
  width: 50%;
  padding: 0 2em 0 0;
  display: flex;
  position: relative;
}

.two-way-question-content-table-rows {
  width: 100%;
}

.two-way-question-content-table-rows-row {
  display: flex;
}

.two-way-question-content-table-rows-row:not(:last-child) {
  border-bottom: 1px solid #9ca3af;
}

.two-way-question-content-table-rows-row .image-supplement img {
  margin: 0;
}

.two-way-question-content-table-rows-row-statement {
  align-items: center;
  width: 50%;
  display: flex;
}

.two-way-question-content-table-rows-row-statement > div {
  width: 50%;
}

.two-way-question-content-table-rows-row-statement p {
  color: #374151;
}

.two-way-question-content-table-rows-row-input {
  color: #d1d5db;
  margin: 0 .5em 0 0;
  font-size: 1rem;
}

.two-way-question-content-table-rows-row-input.fa-check-square {
  color: #50a9e2;
  cursor: pointer;
}

.two-way-question-content-table-rows-row-input.fa-check-square.correct {
  color: #059669;
}

.two-way-question-content-table-rows-row-input.fa-check-square.incorrect {
  color: #dc4538;
}

.two-way-question-content-table-rows-row-statement-right-value {
  align-items: center;
  width: 50%;
  padding: 1em 0 1em .5em;
  display: flex;
}

.free-response-question-view {
  padding: .5em 1em;
}

.free-response-question-view table {
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;
  max-width: 100%;
  margin: 20px auto;
  overflow: auto;
}

.free-response-question-view th {
  background-color: var(--background-primary);
  border-top: 1px solid var(--border-primary);
}

.free-response-question-view tbody tr {
  background-color: var(--background-subtle);
}

.free-response-question-view tbody tr:nth-child(2n) {
  background-color: var(--background-primary);
}

.free-response-question-view tr, .free-response-question-view th {
  box-shadow: inset 0 -1px 0 var(--border-primary);
}

.free-response-question-view tbody:first-child tr:first-child {
  box-shadow: inset 0 -1px 0 var(--border-primary), inset 0 1px 0 var(--border-primary);
}

.free-response-question-view th, .free-response-question-view td {
  border-right: 1px solid var(--border-primary);
  text-align: left;
  padding: 20px 20px 21px;
}

.free-response-question-view th:first-child, .free-response-question-view td:first-child {
  border-left: 1px solid var(--border-primary);
}

.free-response-question-view th {
  white-space: nowrap;
  font-size: 1.1em;
  font-weight: bold;
}

.free-response-question-view th::selection {
  background-color: rgba(0, 0, 0, 0);
}

.free-response-question-view th ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.free-response-question-view p, .free-response-question-view a, .free-response-question-view ul, .free-response-question-view ol, .free-response-question-view li, .free-response-question-view h1, .free-response-question-view h2, .free-response-question-view h3, .free-response-question-view h4, .free-response-question-view h5, .free-response-question-view h6, .free-response-question-view blockquote, .free-response-question-view figcaption {
  -webkit-user-select: none;
  user-select: none;
}

.free-response-question-view a:not([class]) {
  color: var(--content-link);
}

.free-response-question-view a:not([class]):not(:hover) {
  text-decoration: none;
}

.free-response-question-view a:not([class]):hover, .free-response-question-view a:not([class]):visited {
  color: #1e7cb8;
}

.free-response-question-view small {
  line-height: 1.2;
}

.free-response-question-view img {
  max-width: 100%;
}

.free-response-question-view p, .free-response-question-view li {
  color: var(--content-primary);
}

@media (max-width: 600px) {
  .free-response-question-view ul, .free-response-question-view ol {
    margin-left: 1rem;
    padding-left: 0;
  }
}

.free-response-question-view li {
  margin-bottom: .5em;
}

.free-response-question-view strong {
  font-family: Lato, sans-serif;
  font-weight: 800;
}

.free-response-question-view blockquote {
  margin: 0 0 1em;
  padding: 1em;
}

@media (max-width: 600px) {
  .free-response-question-view blockquote {
    margin: 1em 1.5em;
  }
}

.free-response-question-view pre {
  background-color: var(--background-subtle);
  color: var(--content-secondary);
  border-radius: .25em;
  max-width: 100%;
  padding: .5em;
  font-family: Cousine, Courier, monospace;
  line-height: 1.4;
}

.free-response-question-view p code, .free-response-question-view .paragraph code {
  background-color: var(--background-bold);
  border-radius: .25em;
  margin: 0 .25em;
  padding: 0 .25em;
  display: inline-block;
}

.free-response-question-view pre code[class^="lang"] {
  color: #ccc;
  background: #2d2d2d;
  padding: .75em 1em;
  display: block;
  overflow-x: auto;
}

.free-response-question-view hr {
  background-color: var(--background-subtle);
  border: 0;
  height: 1px;
}

.free-response-question-view [class$="question-content"] {
  margin-bottom: 2em;
}

.free-response-question-view .scroll-wrapper {
  max-width: 100%;
  overflow: auto;
}

.free-response-question-view .question-prompt, .free-response-question-view .question-answer-area {
  margin-bottom: 2rem;
  padding: 0 2rem;
}

@media (max-width: 900px) {
  .free-response-question-view .question-prompt, .free-response-question-view .question-answer-area {
    padding: 0 1rem;
  }
}

.free-response-question-view .question__prompt-heading {
  margin: 20px 0;
  font-size: 1.2em;
}

.free-response-question-view .MathJax {
  color: var(--content-primary);
}

.free-response-question-view .MathJax_Display {
  margin: 0;
}

.free-response-question-view__question-container {
  max-width: 830px;
  margin: 0 auto;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
}

@media screen and (max-width: 600px) {
  .free-response-question-view__question-container {
    width: 100%;
    padding: .75em;
  }
}

.free-response-question-view__question {
  background: #fff;
  border-radius: .25em;
  padding: 1.5em;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
}

.free-response-question-view__question .question-footer__labels-header {
  color: #9ca3af;
  font-size: 1.17em;
  font-weight: bold;
}

.free-response-question-view-paywall {
  background: linear-gradient(0deg, #f9fafb 80%, rgba(249, 250, 251, .5));
  height: 80vh;
  position: absolute;
  inset: 20vh 0 0;
  box-shadow: 0 0 30px 15px rgba(249, 250, 251, .5);
}

.free-response-question-view-paywall__contents {
  text-align: center;
  max-width: 500px;
  height: 50%;
  margin: 0 auto;
  padding: 2em;
  position: relative;
  top: 25%;
}

.free-response-question-view-paywall__lead {
  color: #374151;
  font-size: 1.15em;
}

.free-response-question-view-paywall__actions {
  justify-content: center;
  display: flex;
}

.free-response-question-view-paywall__actions button {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #fff;
  cursor: pointer;
  background-color: #059669;
  border: 0;
  border-radius: .15em;
  margin: 0 1em;
  padding: .6em .75em;
  font-size: 1em;
  font-weight: bolder;
  text-decoration: none;
  transition: box-shadow .1s ease-in-out, transform .1s ease-in-out;
}

.free-response-question-view-paywall__actions button::selection {
  background-color: rgba(0, 0, 0, 0);
}

.free-response-question-view-paywall__actions button ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.free-response-question-view-paywall__actions button.disabled, .free-response-question-view-paywall__actions button.disabled:hover, .free-response-question-view-paywall__actions button:disabled, .free-response-question-view-paywall__actions button:disabled:hover {
  color: #d1d5db;
  background-color: #6b7280;
}

.free-response-question-view-paywall__actions button:not(:disabled):not(:active):hover {
  transform: translateY(-1px)scale(1.01);
  box-shadow: 0 2px 9px rgba(0, 0, 0, .2);
}

.card {
  background-color: var(--background-primary);
  border-radius: .4em;
  padding: 1.25em;
  font-size: 16px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
}

.card--no-padding {
  padding: 0;
}

.card--hover {
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
}

.card--hover:hover:not(:active) {
  transition: all .15s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
}

@media (min-width: 901px) {
  .hidden-device-medium-up {
    display: none !important;
  }
}

@media (max-width: 900px) {
  .hidden-device-medium-down {
    display: none !important;
  }
}

@media (min-width: 600px) {
  .hidden-device-small-up {
    display: none !important;
  }
}

@media (max-width: 600px) {
  .hidden-device-small-down {
    display: none !important;
  }
}

.flag {
  color: #4b5563;
  white-space: nowrap;
  background-color: #f3f4f6;
  border-radius: 3px;
  margin: 0 .5em .5em 0;
  padding: .5em .75em;
  font-size: 12px;
  display: inline-block;
}

.flag::selection {
  background-color: rgba(0, 0, 0, 0);
}

.flag ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.flag, .flag a {
  text-decoration: none;
}

@media (min-width: 600px) {
  .flag--hover:hover, .flag--hover:focus {
    cursor: pointer;
    background-color: #e5e7eb;
    outline-width: 0;
    transition: background-color .25s;
  }
}

.flag--red {
  color: #dc4538;
  background-color: #fbefef;
}

@media (min-width: 600px) {
  .flag--red.flag--hover:hover, .flag--red.flag--hover:focus {
    background-color: #f7d7d4;
  }
}

.flag--green {
  color: #059669;
  background-color: #d1fae5;
}

@media (min-width: 600px) {
  .flag--green.flag--hover:hover, .flag--green.flag--hover:focus {
    background-color: #a7f3d0;
  }
}

.flag--green-white {
  color: #fff;
  background-color: #059669;
  border-radius: 2px;
  font-size: .9em;
  font-weight: 900;
}

@media (min-width: 600px) {
  .flag--green-white.flag--hover:hover, .flag--green-white.flag--hover:focus {
    background-color: #047857;
  }
}

.flag--blue {
  color: #1e7cb8;
  background-color: #d3eaf8;
}

@media (min-width: 600px) {
  .flag--blue.flag--hover:hover, .flag--blue.flag--hover:focus {
    background-color: #a8d4f0;
  }
}

.flag--dark-blue {
  color: #fff;
  background-color: #1e7cb8;
}

@media (min-width: 600px) {
  .flag--dark-blue.flag--hover:hover, .flag--dark-blue.flag--hover:focus {
    background-color: #1a699e;
  }
}

.flag--mastery {
  color: #fff;
  cursor: pointer;
  background-color: #9d63cf;
  border-radius: 3px;
  padding: .5em .75em;
  font-size: .75rem;
  font-weight: bold;
  display: inline-block;
}

.flag--mastery, .flag--mastery a {
  text-decoration: none;
}

.flag--mastery:empty:not([type="button"]) {
  display: none;
}

.flag--mastery::selection {
  background-color: rgba(0, 0, 0, 0);
}

.flag--mastery ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.flag--mastery:hover, .flag--mastery:focus {
  background-color: #9150c9;
  outline-width: 0;
  transition: background-color .25s;
}

@media (max-width: 600px) {
  .flag--mastery:hover, .flag--mastery:focus {
    background-color: #9d63cf;
    outline-width: 0;
    transition: background-color .25s;
  }
}

.flag--excelling {
  color: #fff;
  cursor: pointer;
  background-color: #50a9e2;
  border-radius: 3px;
  padding: .5em .75em;
  font-size: .75rem;
  font-weight: bold;
  display: inline-block;
}

.flag--excelling, .flag--excelling a {
  text-decoration: none;
}

.flag--excelling:empty:not([type="button"]) {
  display: none;
}

.flag--excelling::selection {
  background-color: rgba(0, 0, 0, 0);
}

.flag--excelling ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.flag--excelling:hover, .flag--excelling:focus {
  background-color: #3a9ede;
  outline-width: 0;
  transition: background-color .25s;
}

@media (max-width: 600px) {
  .flag--excelling:hover, .flag--excelling:focus {
    background-color: #50a9e2;
    outline-width: 0;
    transition: background-color .25s;
  }
}

.flag--passing {
  color: #fff;
  cursor: pointer;
  background-color: #f0bf6c;
  border-radius: 3px;
  padding: .5em .75em;
  font-size: .75rem;
  font-weight: bold;
  display: inline-block;
}

.flag--passing, .flag--passing a {
  text-decoration: none;
}

.flag--passing:empty:not([type="button"]) {
  display: none;
}

.flag--passing::selection {
  background-color: rgba(0, 0, 0, 0);
}

.flag--passing ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.flag--passing:hover, .flag--passing:focus {
  background-color: #eeb555;
  outline-width: 0;
  transition: background-color .25s;
}

@media (max-width: 600px) {
  .flag--passing:hover, .flag--passing:focus {
    background-color: #f0bf6c;
    outline-width: 0;
    transition: background-color .25s;
  }
}

.flag--proficient {
  color: #fff;
  cursor: pointer;
  background-color: #10b981;
  border-radius: 3px;
  padding: .5em .75em;
  font-size: .75rem;
  font-weight: bold;
  display: inline-block;
}

.flag--proficient, .flag--proficient a {
  text-decoration: none;
}

.flag--proficient:empty:not([type="button"]) {
  display: none;
}

.flag--proficient::selection {
  background-color: rgba(0, 0, 0, 0);
}

.flag--proficient ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.flag--proficient:hover, .flag--proficient:focus {
  background-color: #0ea271;
  outline-width: 0;
  transition: background-color .25s;
}

@media (max-width: 600px) {
  .flag--proficient:hover, .flag--proficient:focus {
    background-color: #10b981;
    outline-width: 0;
    transition: background-color .25s;
  }
}

.flag--struggling {
  color: #fff;
  cursor: pointer;
  background-color: #e36e63;
  border-radius: 3px;
  padding: .5em .75em;
  font-size: .75rem;
  font-weight: bold;
  display: inline-block;
}

.flag--struggling, .flag--struggling a {
  text-decoration: none;
}

.flag--struggling:empty:not([type="button"]) {
  display: none;
}

.flag--struggling::selection {
  background-color: rgba(0, 0, 0, 0);
}

.flag--struggling ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.flag--struggling:hover, .flag--struggling:focus {
  background-color: #df5a4d;
  outline-width: 0;
  transition: background-color .25s;
}

@media (max-width: 600px) {
  .flag--struggling:hover, .flag--struggling:focus {
    background-color: #e36e63;
    outline-width: 0;
    transition: background-color .25s;
  }
}

.flag--learning, .flag--started {
  color: #fff;
  cursor: pointer;
  background-color: #9ca3af;
  border-radius: 3px;
  padding: .5em .75em;
  font-size: .75rem;
  font-weight: bold;
  display: inline-block;
}

.flag--learning, .flag--learning a, .flag--started, .flag--started a {
  text-decoration: none;
}

.flag--learning:empty:not([type="button"]) {
  display: none;
}

.flag--started:empty:not([type="button"]) {
  display: none;
}

.flag--learning::-moz-selection {
  background-color: rgba(0, 0, 0, 0);
}

.flag--started::-moz-selection {
  background-color: rgba(0, 0, 0, 0);
}

.flag--learning::selection {
  background-color: rgba(0, 0, 0, 0);
}

.flag--started::selection {
  background-color: rgba(0, 0, 0, 0);
}

.flag--learning ::-moz-selection {
  background-color: rgba(0, 0, 0, 0);
}

.flag--started ::-moz-selection {
  background-color: rgba(0, 0, 0, 0);
}

.flag--learning ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.flag--started ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.flag--learning:hover, .flag--learning:focus, .flag--started:hover, .flag--started:focus {
  background-color: #8e96a4;
  outline-width: 0;
  transition: background-color .25s;
}

@media (max-width: 600px) {
  .flag--learning:hover, .flag--learning:focus, .flag--started:hover, .flag--started:focus {
    background-color: #9ca3af;
    outline-width: 0;
    transition: background-color .25s;
  }
}

.flag--get-started {
  color: #fff;
  cursor: pointer;
  background-color: #1e7cb8;
  border-radius: 3px;
  padding: .5em .75em;
  font-size: .75rem;
  font-weight: bold;
  display: inline-block;
}

.flag--get-started, .flag--get-started a {
  text-decoration: none;
}

.flag--get-started:empty:not([type="button"]) {
  display: none;
}

.flag--get-started::selection {
  background-color: rgba(0, 0, 0, 0);
}

.flag--get-started ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.flag--get-started:hover, .flag--get-started:focus {
  background-color: #1a6da2;
  outline-width: 0;
  transition: background-color .25s;
}

@media (max-width: 600px) {
  .flag--get-started:hover, .flag--get-started:focus {
    background-color: #1e7cb8;
    outline-width: 0;
    transition: background-color .25s;
  }
}

.form {
  font-size: 14px;
}

.form__input-wrapper {
  width: 100%;
  margin-bottom: .75em;
  display: block;
}

.form__input-wrapper .form__button {
  font-size: .9em;
}

.form__row {
  display: flex;
}

.form__row > * {
  flex-grow: 1;
  margin-left: .5em;
  margin-right: .5em;
}

.form__row > :first-child {
  margin-left: 0;
}

.form__row > :last-child {
  margin-right: 0;
}

.form__actionable-input-wrapper {
  align-items: flex-start;
  margin-bottom: .75em;
  display: flex;
}

.form__actionable-input-wrapper .form__input-wrapper, .form__actionable-input-wrapper .form__field {
  margin-bottom: 0;
}

.form__actionable-input-wrapper .form__button {
  padding-top: .65em;
  padding-bottom: .65em;
  position: relative;
  top: 1.5em;
}

.form__actionable-input-wrapper > * {
  margin-left: .25em;
  margin-right: .25em;
}

.form__actionable-input-wrapper > :first-child {
  margin-left: 0;
}

.form__actionable-input-wrapper > :last-child {
  margin-right: 0;
}

.form__label-text {
  color: var(--content-primary);
  margin-bottom: .25em;
  font-weight: bold;
}

.form__input--required .form__label-text:after {
  color: #dc4538;
  content: "*";
}

.form__input-error {
  color: #dc4538;
  text-transform: none;
  margin-top: .25em;
  font-size: .85em;
}

.form__field {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: var(--background-primary);
  border: 2px solid var(--border-primary);
  border-radius: 3px;
  width: 100%;
  height: 2.5rem;
  padding-left: .5rem;
  font-family: Lato, Helvetica, Arial, sans-serif;
  font-size: 1em;
  transition: border 50ms ease-in-out;
  display: block;
}

.form__field:focus, .form__field:hover {
  transition: border 50ms ease-in-out;
}

.form__field:focus {
  border-color: #a8d4f0;
  outline-width: 0;
}

.form__field:hover {
  border-color: #a8d4f0;
}

.form__field:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.form__field:disabled:hover {
  border-color: #e5e7eb;
}

@media (max-width: 600px) {
  .form__field:not([type="date"]) {
    -webkit-appearance: none;
  }

  .form__field[type="date"] {
    align-items: center;
    display: flex;
  }
}

.form__static-field {
  color: #4b5563;
  background-color: #f3f4f6;
  border-radius: .25em;
  padding: .5em .75em;
  font-size: .9em;
}

.form__field--textarea {
  width: 100%;
  min-height: 8em;
  padding: .75em 1em;
  line-height: 1.4;
}

.form__button.form__button {
  white-space: nowrap;
  font-size: .9em;
  display: inline-block;
}

.form__button {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #fff;
  cursor: pointer;
  background-color: #1e7cb8;
  border: 0;
  border-radius: .15em;
  padding: .6em .75em;
  font-size: 1em;
  font-weight: bolder;
  text-decoration: none;
  transition: box-shadow .1s ease-in-out, transform .1s ease-in-out;
}

.form__button::selection {
  background-color: rgba(0, 0, 0, 0);
}

.form__button ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.form__button.disabled, .form__button.disabled:hover, .form__button:disabled, .form__button:disabled:hover {
  color: #d1d5db;
  background-color: #6b7280;
}

.form__button:not(:disabled):not(:active):hover {
  transform: translateY(-1px)scale(1.01);
  box-shadow: 0 2px 9px rgba(0, 0, 0, .2);
}

.form__button--green {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #fff;
  cursor: pointer;
  background-color: #059669;
  border: 0;
  border-radius: .15em;
  padding: .6em .75em;
  font-size: 1em;
  font-weight: bolder;
  text-decoration: none;
  transition: box-shadow .1s ease-in-out, transform .1s ease-in-out;
}

.form__button--green::selection {
  background-color: rgba(0, 0, 0, 0);
}

.form__button--green ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.form__button--green.disabled, .form__button--green.disabled:hover, .form__button--green:disabled, .form__button--green:disabled:hover {
  color: #d1d5db;
  background-color: #6b7280;
}

.form__button--green:not(:disabled):not(:active):hover {
  transform: translateY(-1px)scale(1.01);
  box-shadow: 0 2px 9px rgba(0, 0, 0, .2);
}

.form__button--red {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #fff;
  cursor: pointer;
  background-color: #dc4538;
  border: 0;
  border-radius: .15em;
  padding: .6em .75em;
  font-size: 1em;
  font-weight: bolder;
  text-decoration: none;
  transition: box-shadow .1s ease-in-out, transform .1s ease-in-out;
}

.form__button--red::selection {
  background-color: rgba(0, 0, 0, 0);
}

.form__button--red ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.form__button--red.disabled, .form__button--red.disabled:hover, .form__button--red:disabled, .form__button--red:disabled:hover {
  color: #d1d5db;
  background-color: #6b7280;
}

.form__button--red:not(:disabled):not(:active):hover {
  transform: translateY(-1px)scale(1.01);
  box-shadow: 0 2px 9px rgba(0, 0, 0, .2);
}

.form__button--gray {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #4b5563;
  cursor: pointer;
  background-color: #e5e7eb;
  border: 0;
  border-radius: .15em;
  padding: .6em .75em;
  font-size: 1em;
  font-weight: bolder;
  text-decoration: none;
  transition: box-shadow .1s ease-in-out, transform .1s ease-in-out;
}

.form__button--gray::selection {
  background-color: rgba(0, 0, 0, 0);
}

.form__button--gray ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.form__button--gray.disabled, .form__button--gray.disabled:hover, .form__button--gray:disabled, .form__button--gray:disabled:hover {
  color: #d1d5db;
  background-color: #6b7280;
}

.form__button--gray:not(:disabled):not(:active):hover {
  transform: translateY(-1px)scale(1.01);
  box-shadow: 0 2px 9px rgba(0, 0, 0, .2);
}

.form__button--disabled {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #fff;
  cursor: pointer;
  background-color: #1e7cb8;
  border: 0;
  border-radius: .15em;
  padding: .6em .75em;
  font-size: 1em;
  font-weight: bolder;
  text-decoration: none;
  transition: box-shadow .1s ease-in-out, transform .1s ease-in-out;
}

.form__button--disabled::selection {
  background-color: rgba(0, 0, 0, 0);
}

.form__button--disabled ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.form__button--disabled.disabled, .form__button--disabled.disabled:hover, .form__button--disabled:disabled, .form__button--disabled:disabled:hover {
  color: #d1d5db;
  background-color: #6b7280;
}

.form__button--disabled:not(:disabled):not(:active):hover {
  transform: translateY(-1px)scale(1.01);
  box-shadow: 0 2px 9px rgba(0, 0, 0, .2);
}

.form__button--disabled, .form__button--disabled:hover {
  color: #d1d5db;
  background-color: #6b7280;
}

.form__actions-wrapper {
  justify-content: flex-end;
  display: flex;
}

.form__actions-wrapper .form__button {
  font-size: 1em;
}

.form__actions-wrapper .form__button:not(:last-child) {
  margin-right: .5em;
}

.button {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #fff;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  background-color: #1e7cb8;
  border: 0;
  border-radius: .15em;
  margin: 0 .75em;
  padding: .6em .75em;
  font-size: 1em;
  font-weight: bold;
  text-decoration: none;
  transition: box-shadow .1s ease-in-out, transform .1s ease-in-out;
  display: inline-block;
}

.button::selection {
  background-color: rgba(0, 0, 0, 0);
}

.button ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.button.disabled, .button.disabled:hover, .button:disabled, .button:disabled:hover {
  color: #d1d5db;
  background-color: #6b7280;
}

.button:not(:disabled):not(:active):hover {
  transform: translateY(-1px)scale(1.01);
  box-shadow: 0 2px 9px rgba(0, 0, 0, .2);
}

.button:first-child {
  margin-left: 0;
}

.button:last-child {
  margin-right: 0;
}

.button--no-margin {
  margin: 0;
}

.button--aqua {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #fff;
  cursor: pointer;
  background-color: #34d399;
  border: 0;
  border-radius: .15em;
  padding: .6em .75em;
  font-size: 1em;
  font-weight: bold;
  text-decoration: none;
  transition: box-shadow .1s ease-in-out, transform .1s ease-in-out;
}

.button--aqua::selection {
  background-color: rgba(0, 0, 0, 0);
}

.button--aqua ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.button--aqua.disabled, .button--aqua.disabled:hover, .button--aqua:disabled, .button--aqua:disabled:hover {
  color: #d1d5db;
  background-color: #6b7280;
}

.button--aqua:not(:disabled):not(:active):hover {
  transform: translateY(-1px)scale(1.01);
  box-shadow: 0 2px 9px rgba(0, 0, 0, .2);
}

.button--green {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #fff;
  cursor: pointer;
  background-color: #059669;
  border: 0;
  border-radius: .15em;
  padding: .6em .75em;
  font-size: 1em;
  font-weight: bold;
  text-decoration: none;
  transition: box-shadow .1s ease-in-out, transform .1s ease-in-out;
}

.button--green::selection {
  background-color: rgba(0, 0, 0, 0);
}

.button--green ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.button--green.disabled, .button--green.disabled:hover, .button--green:disabled, .button--green:disabled:hover {
  color: #d1d5db;
  background-color: #6b7280;
}

.button--green:not(:disabled):not(:active):hover {
  transform: translateY(-1px)scale(1.01);
  box-shadow: 0 2px 9px rgba(0, 0, 0, .2);
}

.button--blue {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #fff;
  cursor: pointer;
  background-color: #1e7cb8;
  border: 0;
  border-radius: .15em;
  padding: .6em .75em;
  font-size: 1em;
  font-weight: bold;
  text-decoration: none;
  transition: box-shadow .1s ease-in-out, transform .1s ease-in-out;
}

.button--blue::selection {
  background-color: rgba(0, 0, 0, 0);
}

.button--blue ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.button--blue.disabled, .button--blue.disabled:hover, .button--blue:disabled, .button--blue:disabled:hover {
  color: #d1d5db;
  background-color: #6b7280;
}

.button--blue:not(:disabled):not(:active):hover {
  transform: translateY(-1px)scale(1.01);
  box-shadow: 0 2px 9px rgba(0, 0, 0, .2);
}

.button--blue-medium {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #fff;
  cursor: pointer;
  background-color: #50a9e2;
  border: 0;
  border-radius: .15em;
  padding: .6em .75em;
  font-size: 1em;
  font-weight: bold;
  text-decoration: none;
  transition: box-shadow .1s ease-in-out, transform .1s ease-in-out;
}

.button--blue-medium::selection {
  background-color: rgba(0, 0, 0, 0);
}

.button--blue-medium ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.button--blue-medium.disabled, .button--blue-medium.disabled:hover, .button--blue-medium:disabled, .button--blue-medium:disabled:hover {
  color: #d1d5db;
  background-color: #6b7280;
}

.button--blue-medium:not(:disabled):not(:active):hover {
  transform: translateY(-1px)scale(1.01);
  box-shadow: 0 2px 9px rgba(0, 0, 0, .2);
}

.button--red {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #fff;
  cursor: pointer;
  background-color: #dc4538;
  border: 0;
  border-radius: .15em;
  padding: .6em .75em;
  font-size: 1em;
  font-weight: bold;
  text-decoration: none;
  transition: box-shadow .1s ease-in-out, transform .1s ease-in-out;
}

.button--red::selection {
  background-color: rgba(0, 0, 0, 0);
}

.button--red ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.button--red.disabled, .button--red.disabled:hover, .button--red:disabled, .button--red:disabled:hover {
  color: #d1d5db;
  background-color: #6b7280;
}

.button--red:not(:disabled):not(:active):hover {
  transform: translateY(-1px)scale(1.01);
  box-shadow: 0 2px 9px rgba(0, 0, 0, .2);
}

.button--dark-blue {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #fff;
  cursor: pointer;
  background-color: #1e7cb8;
  border: 0;
  border-radius: .15em;
  padding: .6em .75em;
  font-size: 1em;
  font-weight: bold;
  text-decoration: none;
  transition: box-shadow .1s ease-in-out, transform .1s ease-in-out;
}

.button--dark-blue::selection {
  background-color: rgba(0, 0, 0, 0);
}

.button--dark-blue ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.button--dark-blue.disabled, .button--dark-blue.disabled:hover, .button--dark-blue:disabled, .button--dark-blue:disabled:hover {
  color: #d1d5db;
  background-color: #6b7280;
}

.button--dark-blue:not(:disabled):not(:active):hover {
  transform: translateY(-1px)scale(1.01);
  box-shadow: 0 2px 9px rgba(0, 0, 0, .2);
}

.button--light-gray {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #4b5563;
  cursor: pointer;
  background-color: #e5e7eb;
  border: 0;
  border-radius: .15em;
  padding: .6em .75em;
  font-size: 1em;
  font-weight: bold;
  text-decoration: none;
  transition: box-shadow .1s ease-in-out, transform .1s ease-in-out;
}

.button--light-gray::selection {
  background-color: rgba(0, 0, 0, 0);
}

.button--light-gray ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.button--light-gray.disabled, .button--light-gray.disabled:hover, .button--light-gray:disabled, .button--light-gray:disabled:hover {
  color: #d1d5db;
  background-color: #6b7280;
}

.button--light-gray:not(:disabled):not(:active):hover {
  transform: translateY(-1px)scale(1.01);
  box-shadow: 0 2px 9px rgba(0, 0, 0, .2);
}

.button--lighter-gray {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #6b7280;
  cursor: pointer;
  background-color: #f3f4f6;
  border: 0;
  border-radius: .15em;
  padding: .6em .75em;
  font-size: 1em;
  font-weight: bold;
  text-decoration: none;
  transition: box-shadow .1s ease-in-out, transform .1s ease-in-out;
}

.button--lighter-gray::selection {
  background-color: rgba(0, 0, 0, 0);
}

.button--lighter-gray ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.button--lighter-gray.disabled, .button--lighter-gray.disabled:hover, .button--lighter-gray:disabled, .button--lighter-gray:disabled:hover {
  color: #d1d5db;
  background-color: #6b7280;
}

.button--lighter-gray:not(:disabled):not(:active):hover {
  transform: translateY(-1px)scale(1.01);
  box-shadow: 0 2px 9px rgba(0, 0, 0, .2);
}

.button--white {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #fff;
  cursor: pointer;
  background-color: #fff;
  border: 0;
  border-radius: .15em;
  padding: .6em .75em;
  font-size: 1em;
  font-weight: bold;
  text-decoration: none;
  transition: box-shadow .1s ease-in-out, transform .1s ease-in-out;
}

.button--white::selection {
  background-color: rgba(0, 0, 0, 0);
}

.button--white ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.button--white.disabled, .button--white.disabled:hover, .button--white:disabled, .button--white:disabled:hover {
  color: #d1d5db;
  background-color: #6b7280;
}

.button--white:not(:disabled):not(:active):hover {
  transform: translateY(-1px)scale(1.01);
  box-shadow: 0 2px 9px rgba(0, 0, 0, .2);
}

.button--disabled {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #fff;
  cursor: pointer;
  background-color: #1e7cb8;
  border: 0;
  border-radius: .15em;
  padding: .6em .75em;
  font-size: 1em;
  font-weight: bold;
  text-decoration: none;
  transition: box-shadow .1s ease-in-out, transform .1s ease-in-out;
}

.button--disabled::selection {
  background-color: rgba(0, 0, 0, 0);
}

.button--disabled ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.button--disabled.disabled, .button--disabled.disabled:hover, .button--disabled:disabled, .button--disabled:disabled:hover {
  color: #d1d5db;
  background-color: #6b7280;
}

.button--disabled:not(:disabled):not(:active):hover {
  transform: translateY(-1px)scale(1.01);
  box-shadow: 0 2px 9px rgba(0, 0, 0, .2);
}

.button--disabled, .button--disabled:hover {
  color: #d1d5db;
  background-color: #6b7280;
}

.button--flex-item {
  align-items: center;
  padding: 0 .75em;
  display: inline-flex;
}

.button--wide {
  padding: .6em 2em;
}

.outline-button {
  color: #1e7cb8;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  border-radius: 3px;
  padding: 10px 28px;
  font-size: 15.4px;
  font-weight: bold;
  text-decoration: none;
  transition: all .1s;
  box-shadow: inset 0 0 0 2px #1e7cb8;
}

.outline-button:last-child {
  margin-right: 0;
}

.outline-button:focus, .outline-button:hover {
  outline-width: 0;
  box-shadow: inset 0 0 0 2px #1e7cb8, 0 2px 9px rgba(0, 0, 0, .2);
}

.outline-button:disabled {
  color: #9ca3af;
  background: #fff;
}

.outline-button--aqua {
  color: #34d399;
  box-shadow: inset 0 0 0 2px #34d399;
}

.outline-button--aqua:focus, .outline-button--aqua:hover {
  box-shadow: inset 0 0 0 2px #34d399, 0 2px 9px rgba(0, 0, 0, .2);
}

.outline-button--green {
  color: #059669;
  box-shadow: inset 0 0 0 2px #059669;
}

.outline-button--green:focus, .outline-button--green:hover {
  box-shadow: inset 0 0 0 2px #059669, 0 2px 9px rgba(0, 0, 0, .2);
}

.outline-button--red {
  color: #dc4538;
  box-shadow: inset 0 0 0 2px #dc4538;
}

.outline-button--red:focus, .outline-button--red:hover {
  box-shadow: inset 0 0 0 2px #dc4538, 0 2px 9px rgba(0, 0, 0, .2);
}

.outline-button--white {
  color: #fff;
  box-shadow: inset 0 0 0 2px #fff;
}

.outline-button--white:focus, .outline-button--white:hover {
  box-shadow: inset 0 0 0 2px #fff, 0 2px 9px rgba(0, 0, 0, .2);
}

.button--large {
  border-radius: 3px;
  padding: 39px 43px;
  font-size: 18.2px;
}

.button--small {
  border-radius: 2px;
  padding: 7px;
  font-size: 12.6px;
}

.unbutton {
  color: currentColor;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 1em;
  font-weight: normal;
  display: inline;
}

.unbutton:hover {
  color: currentColor;
}

.unbutton:active, .unbutton:focus {
  outline-width: 0;
}

.hacky-message-area {
  text-align: center;
  z-index: 999;
  background-color: #f3f4f6;
  border-radius: .25em;
  margin-bottom: .75em;
  padding: 1em;
  font-weight: bolder;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.hacky-message-area--success {
  color: #065f46;
  background-color: #d1fae5;
}

.hacky-message-area--error {
  background-color: #fbefef;
  color: #dc4538 !important;
}

.insight-table {
  border-collapse: collapse;
  text-align: left;
  width: 100%;
}

.insight-table th, .insight-table td {
  text-align: center;
  padding: .5em;
}

.insight-table th, .insight-table tr {
  border-bottom: 1px solid #d1d5db;
}

.insight-table tr:last-child {
  border-bottom: none;
}

.insight-table th:first-child, .insight-table td:first-child {
  text-align: left;
}

.insight-table__row-disabled {
  color: #9ca3af;
}

.insight-table__row-no-border {
  border-bottom: 0;
}

.insight-table__table-header--max th:first-child {
  max-width: 300px;
}

.insight-table__student-info, .insight-table__question-info {
  cursor: pointer;
  flex-wrap: initial;
  align-items: center;
  max-width: 300px;
  display: flex;
}

.insight-table__student-info p, .insight-table__question-info p {
  margin: 0;
}

.insight-table__health-indicator {
  width: 100%;
}

.insight-table__header-tooltip {
  width: 200px;
}

.insight-table__question-info--no-click {
  cursor: auto;
}

.insight-table__submit-status-wrapper {
  align-items: baseline;
  display: flex;
}

.insight-table__submit-status {
  justify-content: center;
  align-items: center;
  padding: .5em;
  display: flex;
}

.insight-table__submit-status-badge {
  border-radius: 50%;
  width: .5em;
  height: .5em;
  margin-right: .5em;
}

.insight-table__submit-status-badge--on-time {
  background-color: #059669;
}

.insight-table__submit-status-badge--late {
  background-color: #dc4538;
}

.insight-table__submit-status-badge--incomplete {
  border: 1px solid #9ca3af;
}

.insight-table__submit-status-label {
  font-size: .9em;
  font-weight: bold;
}

.insight-table__submit-status-badge:not(.insight-table__submit-status-badge--incomplete), .insight-table__submit-status-label:not(.insight-table__submit-status-label--incomplete) {
  cursor: pointer;
}

.insight-table__submit-status-label--on-time {
  color: #059669;
}

.insight-table__submit-status-label--late {
  color: #dc4538;
}

.insight-table__submit-status-label--incomplete {
  color: #9ca3af;
}

.insight-table__student-info-name, .insight-table__question-info-name {
  justify-content: flex-end;
}

.insight-table .insight-table__question-answer-badge-list {
  flex-flow: wrap;
  justify-content: center;
  max-width: 180px;
  display: flex;
}

.insight-table .insight-table__question-answer-badge-list .question-answer-badge {
  margin: .05em .25em;
}

.insight-table .insight-table__question-answer-badge-list .tooltip__wrapper {
  margin: 0;
}

.insight-table__question-answer-badge-tooltip {
  text-align: left;
}

.insight-table__question-answer-badge-tooltip-datum {
  margin: 1em 0;
}

.insight-table__question-answer-badge-tooltip-term {
  font-weight: bold;
}

.insight-table__question-answer-badge-tooltip-definition {
  margin-left: .5em;
  font-weight: normal;
}

.question-attempt-indicator {
  background-color: #d1d5db;
  border-radius: 50%;
  width: 15px;
  min-width: 15px;
  height: 15px;
  min-height: 15px;
  margin: 0 5px 0 10px;
}

.question-attempt-indicator--blocked {
  color: #e29a3d;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 50%;
  width: auto;
  min-width: auto;
  height: auto;
  min-height: auto;
  font-family: "Font Awesome 6 Pro";
  font-size: 1em;
}

.question-attempt-indicator--blocked:before {
  content: "";
}

.question-attempt-indicator--unanswered {
  background-color: rgba(0, 0, 0, 0);
  border: 2px solid #d1d5db;
}

.question-attempt-indicator--correct {
  background-color: #059669;
}

.question-attempt-indicator--incorrect {
  background-color: #dc4538;
}

.question-attempt-indicator--no-feedback {
  background-color: #9ca3af;
  border: 0;
}

.question-set-difficulty-badge {
  color: #9ca3af;
  cursor: pointer;
  background-color: #f3f4f6;
  border-radius: 3px;
  margin: 0 .5em;
  padding: .5em .75em;
  font-family: Cousine, Courier, monospace;
  font-size: .75rem;
  font-weight: bold;
  display: inline-block;
}

.question-set-difficulty-badge, .question-set-difficulty-badge a {
  text-decoration: none;
}

.question-set-difficulty-badge:empty:not([type="button"]) {
  display: none;
}

.question-set-difficulty-badge::selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-set-difficulty-badge ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-set-difficulty-badge:hover, .question-set-difficulty-badge:focus {
  background-color: #e4e7eb;
  outline-width: 0;
  transition: background-color .25s;
}

@media (max-width: 600px) {
  .question-set-difficulty-badge:hover, .question-set-difficulty-badge:focus {
    background-color: #f3f4f6;
    outline-width: 0;
    transition: background-color .25s;
  }
}

.question-set-difficulty-badge.easy {
  color: #1e7cb8;
  background-color: #d3eaf8;
}

.question-set-difficulty-badge.moderate {
  color: #059669;
  background-color: #d1fae5;
}

.question-set-difficulty-badge.difficult {
  color: #dc4538;
  background-color: #fbefef;
}

.question-set-difficulty-badge.easy:before {
  content: "E";
}

.question-set-difficulty-badge.moderate:before {
  content: "M";
}

.question-set-difficulty-badge.difficult:before {
  content: "D";
}

.question-answer-badge {
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  display: inline-flex;
}

.question-answer-badge.correct {
  color: #059669;
  background-color: #d1fae5;
}

.question-answer-badge.incorrect {
  color: #dc4538;
  background-color: #fbefef;
}

.question-answer-badge.unanswered {
  color: #9ca3af;
  background-color: #d1d5db;
}

.max-width {
  max-width: 1088px;
  margin: 0 auto;
}

@media (max-width: 1120px) {
  .max-width {
    margin: 0 1em;
  }
}

@media (max-width: 600px) {
  .max-width {
    margin: 0 .75em;
  }
}

.h1-font-size {
  font-size: 1.6em;
}

.h2-font-size {
  font-size: 1.4em;
}

.h3-font-size {
  font-size: 1.2em;
}

.h4-font-size {
  font-size: 1.1em;
}

.h5-font-size {
  font-size: 1em;
}

.h6-font-size {
  font-size: .9em;
}

.no-results-text {
  color: #d1d5db;
  text-align: center;
  font-size: 1.25em;
}

.avoid-wrap {
  display: inline-block;
}

.card-label {
  color: #6b7280;
  text-transform: uppercase;
  margin: 0;
  padding: 15px;
  font-size: 16px;
}

.blocky-dropdown {
  cursor: default;
  margin-bottom: .75em;
}

.blocky-dropdown::selection {
  background-color: rgba(0, 0, 0, 0);
}

.blocky-dropdown ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.blocky-dropdown--open .blocky-dropdown__selection {
  border-radius: .25em .25em 0 0;
}

.blocky-dropdown__selection, .blocky-dropdown__selection--disabled {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: var(--background-primary);
  border: 2px solid var(--border-primary);
  border-radius: 3px;
  align-items: center;
  height: 2.5rem;
  padding: .75em 2.25em .75em .75em;
  font-family: Lato, Helvetica, Arial, sans-serif;
  font-size: 1em;
  transition: border 50ms ease-in-out;
  display: flex;
}

.blocky-dropdown__selection:focus, .blocky-dropdown__selection:hover, .blocky-dropdown__selection--disabled:focus, .blocky-dropdown__selection--disabled:hover {
  transition: border 50ms ease-in-out;
}

.blocky-dropdown__selection:focus, .blocky-dropdown__selection--disabled:focus {
  border-color: #a8d4f0;
  outline-width: 0;
}

.blocky-dropdown__selection:hover, .blocky-dropdown__selection--disabled:hover {
  border-color: #a8d4f0;
}

.blocky-dropdown__selection:disabled, .blocky-dropdown__selection--disabled:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.blocky-dropdown__selection:disabled:hover, .blocky-dropdown__selection--disabled:disabled:hover {
  border-color: #e5e7eb;
}

.blocky-dropdown__selection:after, .blocky-dropdown__selection--disabled:after {
  color: #4b5563;
  content: "";
  width: .75em;
  font-family: "Font Awesome 6 Pro";
  font-size: 1.25em;
  position: absolute;
  right: .5em;
}

.blocky-dropdown__selection__active-item {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.blocky-dropdown__selection--disabled {
  cursor: not-allowed;
  opacity: .5;
}

.blocky-dropdown__selection--no-results:after {
  content: none;
}

.blocky-dropdown__results-wrapper {
  color: #4b5563;
  cursor: pointer;
  z-index: 99;
  min-width: 100%;
  position: absolute;
  top: 100%;
}

.blocky-dropdown__result {
  background-color: #fff;
  border: 1px solid #9ca3af;
  padding: .75em;
}

.blocky-dropdown__result:hover {
  background-color: #f9fafb;
}

.blocky-dropdown__result:not(:first-child) {
  border-top: 0;
}

.blocky-dropdown__result:last-child {
  border-radius: 0 0 .25em .25em;
}

.blocky-dropdown--small .blocky-dropdown__selection {
  padding: .5em 2em .5em .75em;
  font-size: .75rem;
}

.blocky-dropdown--small .blocky-dropdown__selection:after {
  font-size: 1.15em;
}

.blocky-dropdown--small .blocky-dropdown__result {
  padding: .75em;
  font-size: .75rem;
}

.subheader .blocky-dropdown {
  margin-bottom: 0;
}

.subheader .blocky-dropdown__selection {
  color: #fff;
  background-color: #1e7cb8;
  padding: .5em 2em .5em .75em;
}

.subheader .blocky-dropdown__selection:after {
  color: #fff;
  content: "";
  font-size: .8em;
  right: 1em;
}

.subheader .blocky-dropdown__results-wrapper {
  top: 100%;
}

.subheader .blocky-dropdown__result {
  color: #fff;
  background-color: #1e7cb8;
  border-color: #50a9e2;
}

.subheader .blocky-dropdown__result:hover {
  background-color: #1a699e;
}

.progress-meter {
  appearance: none;
  color: #50a9e2;
  background: #d1d5db;
  border: 0;
  border-radius: .25em;
  width: 100%;
  min-width: 7em;
  max-width: 22em;
  height: .5em;
  font-size: .875rem;
  overflow: hidden;
}

.progress-meter::-webkit-progress-bar {
  background: #d1d5db;
  border-radius: .5em;
}

.progress-meter::-webkit-progress-value {
  will-change: width, background-color;
  background: #50a9e2;
  transition: width .2s cubic-bezier(.23, 1, .32, 1), background-color .2s cubic-bezier(.23, 1, .32, 1);
}

.progress-meter::-moz-progress-bar {
  background: #50a9e2;
  border-radius: .5em;
}

.progress-meter--medium {
  border-radius: .5em;
  height: 1em;
}

.progress-meter--large {
  border-radius: 1em;
  height: 2em;
}

.progress-meter--blue {
  color: #50a9e2;
}

.progress-meter--blue::-webkit-progress-value {
  background: #50a9e2;
}

.progress-meter--blue::-moz-progress-bar {
  background: #50a9e2;
}

.progress-meter--green {
  color: #059669;
}

.progress-meter--green::-webkit-progress-value {
  background: #059669;
}

.progress-meter--green::-moz-progress-bar {
  background: #059669;
}

.progress-meter--red {
  color: #e36e63;
}

.progress-meter--red::-webkit-progress-value {
  background: #e36e63;
}

.progress-meter--red::-moz-progress-bar {
  background: #e36e63;
}

.progress-meter--orange {
  color: #f0bf6c;
}

.progress-meter--orange::-webkit-progress-value {
  background: #f0bf6c;
}

.progress-meter--orange::-moz-progress-bar {
  background: #f0bf6c;
}

.progress-meter--gray {
  color: #6b7280;
}

.progress-meter--gray::-webkit-progress-value {
  background: #6b7280;
}

.progress-meter--gray::-moz-progress-bar {
  background: #6b7280;
}

.progress-meter--purple {
  color: #9d63cf;
}

.progress-meter--purple::-webkit-progress-value {
  background: #9d63cf;
}

.progress-meter--purple::-moz-progress-bar {
  background: #9d63cf;
}

.progress-meter--passing {
  color: #f0bf6c;
}

.progress-meter--passing::-webkit-progress-value {
  background: #f0bf6c;
}

.progress-meter--passing::-moz-progress-bar {
  background: #f0bf6c;
}

.progress-meter--proficient {
  color: #10b981;
}

.progress-meter--proficient::-webkit-progress-value {
  background: #10b981;
}

.progress-meter--proficient::-moz-progress-bar {
  background: #10b981;
}

.progress-meter--excelling {
  color: #50a9e2;
}

.progress-meter--excelling::-webkit-progress-value {
  background: #50a9e2;
}

.progress-meter--excelling::-moz-progress-bar {
  background: #50a9e2;
}

.progress-meter--mastery {
  color: #9d63cf;
}

.progress-meter--mastery::-webkit-progress-value {
  background: #9d63cf;
}

.progress-meter--mastery::-moz-progress-bar {
  background: #9d63cf;
}

.breadcrumb-list {
  border-bottom: 1px solid #d1d5db;
  justify-content: center;
  height: 50px;
  margin: 0;
  padding: 0;
  display: flex;
}

.breadcrumb-list__item {
  align-items: center;
  padding: 0 35px 0 21px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.breadcrumb-list__item:first-child {
  padding-left: 0;
}

.breadcrumb-list__item:last-child:before, .breadcrumb-list__item:last-child:after {
  content: none;
}

.breadcrumb-list__item:before, .breadcrumb-list__item:after {
  content: "";
  background-color: #d1d5db;
  width: 1px;
  height: 29px;
  position: absolute;
  top: -2px;
  bottom: 0;
  right: 7px;
  transform: rotate(-28deg);
}

.breadcrumb-list__item:after {
  top: 23px;
  transform: rotate(28deg);
}

.breadcrumb-list__item-anchor {
  color: #6b7280;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 140px;
  text-decoration: none;
  transition: all .2s ease-in-out;
  display: block;
  overflow: hidden;
}

.breadcrumb-list__item-anchor.breadcrumb-list__item-anchor--selected, .breadcrumb-list__item-anchora:hover {
  color: #50a9e2;
  max-width: 500px;
  transition: all .2s ease-in-out;
  overflow: auto;
  box-shadow: inset 0 -1px #50a9e2;
}

.a-form-wrapper {
  justify-content: center;
  margin: 0 0 1em;
  display: flex;
}

.a-form-wrapper ::-webkit-input-placeholder {
  color: #4b5563;
  opacity: 1;
}

.a-form-wrapper ::placeholder {
  color: #4b5563;
  opacity: 1;
}

.a-form-wrapper :-ms-input-placeholder {
  color: #4b5563;
  opacity: 1;
}

.a-form {
  background: #fff;
  border-radius: .25em;
  flex-direction: column;
  width: 36em;
  padding: 1.5em 2em;
  display: flex;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
}

@media (max-width: 600px) {
  .a-form {
    width: 100%;
  }
}

.a-form-row {
  flex: auto;
  margin: 0 0 .75em;
  display: flex;
}

@media (max-width: 600px) {
  .a-form-row {
    flex-direction: column;
  }
}

.a-form-row--align-right {
  justify-content: flex-end;
}

.a-form-col {
  flex: 50%;
  margin: 0 .75em 0 0;
  display: flex;
}

.a-form-col:last-child {
  margin: 0;
}

@media (max-width: 600px) {
  .a-form-col {
    flex: auto;
    margin: 0 0 .75em;
  }
}

.a-form-link {
  color: #1a699e;
  cursor: pointer;
  text-decoration: underline;
}

.a-form-link:hover {
  color: #50a9e2;
}

.a-form-error-container {
  color: #dc4538;
  border: 1px solid #dc4538;
  border-radius: .15em;
  flex: auto;
  margin: 0 0 .5em;
  padding: .5em;
}

.a-form-error-container ul {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.a-form-error-title {
  margin: 0 .25em 0 0;
  font-weight: bold;
}

.a-form-submit-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #fff;
  cursor: pointer;
  text-align: center;
  background-color: #059669;
  border: 0;
  border-radius: .15em;
  flex-flow: row;
  flex: auto;
  justify-content: center;
  align-items: center;
  padding: .85em 1em .8em;
  font-size: 1em;
  font-weight: bold;
  text-decoration: none;
  transition: box-shadow .1s ease-in-out, transform .1s ease-in-out;
  display: flex;
}

.a-form-submit-button::selection {
  background-color: rgba(0, 0, 0, 0);
}

.a-form-submit-button ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.a-form-submit-button.disabled, .a-form-submit-button.disabled:hover, .a-form-submit-button:disabled, .a-form-submit-button:disabled:hover {
  color: #d1d5db;
  background-color: #6b7280;
}

.a-form-submit-button:not(:disabled):not(:active):hover {
  transform: translateY(-1px)scale(1.01);
  box-shadow: 0 2px 9px rgba(0, 0, 0, .2);
}

.hljs-comment, .hljs-quote {
  color: #d4d0ab;
}

.hljs-variable, .hljs-template-variable, .hljs-tag, .hljs-name, .hljs-selector-id, .hljs-selector-class, .hljs-regexp, .hljs-deletion {
  color: #ffa07a;
}

.hljs-number, .hljs-built_in, .hljs-builtin-name, .hljs-literal, .hljs-type, .hljs-params, .hljs-meta, .hljs-link {
  color: #f5ab35;
}

.hljs-attribute {
  color: gold;
}

.hljs-string, .hljs-symbol, .hljs-bullet, .hljs-addition {
  color: #abe338;
}

.hljs-title, .hljs-section {
  color: #00e0e0;
}

.hljs-keyword, .hljs-selector-tag {
  color: #dcc6e0;
}

.hljs {
  color: #f8f8f2;
  background: #2b2b2b;
  padding: .5em;
  display: block;
  overflow-x: auto;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

@media screen and (-ms-high-contrast: active) {
  .hljs-addition, .hljs-attribute, .hljs-built_in, .hljs-builtin-name, .hljs-bullet, .hljs-comment, .hljs-link, .hljs-literal, .hljs-meta, .hljs-number, .hljs-params, .hljs-string, .hljs-symbol, .hljs-type, .hljs-quote {
    color: highlight;
  }

  .hljs-keyword, .hljs-selector-tag {
    font-weight: bold;
  }
}

*, :before, :after {
  box-sizing: inherit;
  font-family: inherit;
  position: relative;
}

html, body {
  box-sizing: border-box;
  color: var(--content-primary);
  background-color: #f9fafb;
  margin: 0;
  padding: 0;
  font-family: Lato, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

:root {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html.has-active-modal, body.has-active-modal {
  overflow: hidden;
}

.clearfix:after {
  clear: both;
  content: "";
  display: table;
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0 0 1em;
}

input {
  padding: 0;
}

input:invalid {
  box-shadow: none;
}

hr {
  border: 0;
  border-bottom: 1px solid var(--border-primary);
  margin: 2em 0;
  font-size: 16px;
}

code {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  font-family: Cousine, Courier, monospace;
}

pre {
  overflow: auto;
}

button::selection {
  background-color: rgba(0, 0, 0, 0);
}

button ::selection {
  background-color: rgba(0, 0, 0, 0);
}

dd {
  margin: 0;
}

@media (max-width: 600px) {
  body {
    min-height: 100vh;
  }

  html.has-active-modal, body.has-active-modal {
    height: 100%;
    -webkit-overflow-scrolling: touch !important;
    overflow: auto !important;
  }

  .zEWidget-launcher {
    display: none;
  }
}

.fa--strike:after {
  content: "";
  background-color: currentColor;
  border-radius: 1px;
  width: 170%;
  height: 2px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%)rotate(45deg);
  box-shadow: 0 -1px #fff;
}
