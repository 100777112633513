.a-form-select {
  flex-flow: column;
  flex: auto;
  margin: 0 1em 0 0;
  display: flex;
  position: relative;
}

.a-form-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: var(--background-primary);
  border: 2px solid var(--border-primary);
  border-radius: 3px;
  width: 100%;
  min-width: 5em;
  height: 2.5rem;
  padding: 0 1.5rem 0 .5rem;
  font-family: Lato, Helvetica, Arial, sans-serif;
  font-size: .875rem;
  transition: border 50ms ease-in-out;
  display: block;
  position: relative;
}

.a-form-select select:focus, .a-form-select select:hover {
  transition: border 50ms ease-in-out;
}

.a-form-select select:focus {
  border-color: #a8d4f0;
  outline-width: 0;
}

.a-form-select select:hover {
  border-color: #a8d4f0;
}

.a-form-select select:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.a-form-select select:disabled:hover {
  border-color: #e5e7eb;
}

.a-form-select:after {
  content: "";
  pointer-events: none;
  font-family: "Font Awesome 6 Pro";
  font-size: 1rem;
  position: absolute;
  top: 50%;
  right: .5rem;
  transform: translateY(-50%);
}

.a-form-select:last-child {
  margin: 0;
}

.a-form-select .a-form-select__element--error {
  border-color: #dc4538;
}

.a-form-select__label {
  margin: 0 0 .25em;
  font-weight: bold;
}

.a-form-select__label:empty {
  display: none;
}

.a-form-select--required .a-form-select__label:after {
  color: #dc4538;
  content: "*";
}

.a-form-select__error {
  color: #dc4538;
  margin: .25em 0 0;
  font-size: .875rem;
}
