.single-question--text-highlight table {
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;
  max-width: 100%;
  margin: 20px auto;
  overflow: auto;
}

.single-question--text-highlight th {
  background-color: var(--background-primary);
  border-top: 1px solid var(--border-primary);
}

.single-question--text-highlight tbody tr {
  background-color: var(--background-subtle);
}

.single-question--text-highlight tbody tr:nth-child(2n) {
  background-color: var(--background-primary);
}

.single-question--text-highlight tr, .single-question--text-highlight th {
  box-shadow: inset 0 -1px 0 var(--border-primary);
}

.single-question--text-highlight tbody:first-child tr:first-child {
  box-shadow: inset 0 -1px 0 var(--border-primary), inset 0 1px 0 var(--border-primary);
}

.single-question--text-highlight th, .single-question--text-highlight td {
  border-right: 1px solid var(--border-primary);
  text-align: left;
  padding: 20px 20px 21px;
}

.single-question--text-highlight th:first-child, .single-question--text-highlight td:first-child {
  border-left: 1px solid var(--border-primary);
}

.single-question--text-highlight th {
  white-space: nowrap;
  font-size: 1.1em;
  font-weight: bold;
}

.single-question--text-highlight th::selection {
  background-color: rgba(0, 0, 0, 0);
}

.single-question--text-highlight th ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.single-question--text-highlight p, .single-question--text-highlight a, .single-question--text-highlight ul, .single-question--text-highlight ol, .single-question--text-highlight li, .single-question--text-highlight h1, .single-question--text-highlight h2, .single-question--text-highlight h3, .single-question--text-highlight h4, .single-question--text-highlight h5, .single-question--text-highlight h6, .single-question--text-highlight blockquote, .single-question--text-highlight figcaption {
  -webkit-user-select: none;
  user-select: none;
}

.single-question--text-highlight a:not([class]) {
  color: var(--content-link);
}

.single-question--text-highlight a:not([class]):not(:hover) {
  text-decoration: none;
}

.single-question--text-highlight a:not([class]):hover, .single-question--text-highlight a:not([class]):visited {
  color: #1e7cb8;
}

.single-question--text-highlight small {
  line-height: 1.2;
}

.single-question--text-highlight img {
  max-width: 100%;
}

.single-question--text-highlight p, .single-question--text-highlight li {
  color: var(--content-primary);
}

@media (max-width: 600px) {
  .single-question--text-highlight ul, .single-question--text-highlight ol {
    margin-left: 1rem;
    padding-left: 0;
  }
}

.single-question--text-highlight li {
  margin-bottom: .5em;
}

.single-question--text-highlight strong {
  font-family: Lato, sans-serif;
  font-weight: 800;
}

.single-question--text-highlight blockquote {
  margin: 0 0 1em;
  padding: 1em;
}

@media (max-width: 600px) {
  .single-question--text-highlight blockquote {
    margin: 1em 1.5em;
  }
}

.single-question--text-highlight pre {
  background-color: var(--background-subtle);
  color: var(--content-secondary);
  border-radius: .25em;
  max-width: 100%;
  padding: .5em;
  font-family: Cousine, Courier, monospace;
  line-height: 1.4;
}

.single-question--text-highlight p code, .single-question--text-highlight .paragraph code {
  background-color: var(--background-bold);
  border-radius: .25em;
  margin: 0 .25em;
  padding: 0 .25em;
  display: inline-block;
}

.single-question--text-highlight pre code[class^="lang"] {
  color: #ccc;
  background: #2d2d2d;
  padding: .75em 1em;
  display: block;
  overflow-x: auto;
}

.single-question--text-highlight hr {
  background-color: var(--background-subtle);
  border: 0;
  height: 1px;
}

.single-question--text-highlight [class$="question-content"] {
  margin-bottom: 2em;
}

.single-question--text-highlight .scroll-wrapper {
  max-width: 100%;
  overflow: auto;
}

.single-question--text-highlight .question-prompt, .single-question--text-highlight .question-answer-area {
  margin-bottom: 2rem;
  padding: 0 2rem;
}

@media (max-width: 900px) {
  .single-question--text-highlight .question-prompt, .single-question--text-highlight .question-answer-area {
    padding: 0 1rem;
  }
}

.single-question--text-highlight .question__prompt-heading {
  margin: 20px 0;
  font-size: 1.2em;
}

.single-question--text-highlight .MathJax {
  color: var(--content-primary);
}

.single-question--text-highlight .MathJax_Display {
  margin: 0;
}

.answer-input--text-highlight, .text-highlight-correct-answers__correct-answers {
  color: #4b5563;
  letter-spacing: .025em;
  white-space: pre-wrap;
  border-left: 3px solid #d1d5db;
  max-width: 600px;
  margin-left: .5rem;
  padding: 0 4rem 0 .75rem;
  line-height: 1.6;
}

.answer-input--text-highlight .answer-input__highlight, .text-highlight-correct-answers__correct-answers .answer-input__highlight {
  background-color: #fde68a;
}

@media (max-width: 600px) {
  .answer-input--text-highlight, .text-highlight-correct-answers__correct-answers {
    margin-left: 1rem;
    padding-right: 1rem;
  }
}

.text-highlight-correct-answers {
  padding: 0 3rem;
}

.text-highlight-correct-answers h3 {
  text-transform: uppercase;
}

.text-highlight-correct-answers__correct-answers {
  margin: 0 0 1rem;
}

.text-highlight-correct-answers__correct-answers .answer-input__highlight {
  background-color: #d1fae5;
  border: 1px solid #059669;
}

.question-response-stats--text-highlight .question-response-stats__item-label {
  box-sizing: content-box;
  color: #9ca3af;
  text-align: center;
  background-color: #f3f4f6;
  width: 100%;
  max-width: 9ch;
}
