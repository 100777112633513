.sg-table {
  width: 100%;
  font-size: 16px;
}

.sg-table__table-wrapper {
  max-width: 100%;
  overflow: auto;
}

.sg-table__table {
  border: 1px solid var(--border-primary);
  border-spacing: 0;
  border-width: 1px 0 0;
  margin: 0;
}

.sg-table__thead {
  background-color: #fff;
}

.sg-table__thead--fixed-headings {
  border-top: 1px solid var(--border-primary);
  z-index: 1;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.sg-table__thead--fixed-headings .sg-table__tr {
  display: flex;
}

.sg-table__thead--fixed-headings .sg-table__th {
  border-bottom: 1px solid var(--border-primary);
}

.sg-table__tbody .sg-table__tr {
  background-color: #fafbfc;
}

.sg-table__tbody .sg-table__tr:nth-child(2n) {
  background-color: #fff;
}

.sg-table__tr {
  box-shadow: inset 0 -1px 0 var(--border-primary);
}

.sg-table__tr--link:nth-child(n) {
  cursor: pointer;
}

.sg-table__tr--link:nth-child(n):hover {
  background-color: #f7f7f7;
}

.sg-table__th, .sg-table__td {
  border-right: 1px solid var(--border-primary);
  text-align: left;
  padding: 20px 20px 21px;
}

.sg-table__th:last-child, .sg-table__td:last-child {
  border-right: none;
}

.sg-table__td--align-center {
  text-align: center;
}

.sg-table__td--align-right {
  text-align: right;
}

.sg-table__td-mobile-heading {
  display: none;
}

.sg-table__th {
  white-space: nowrap;
  font-size: 1.1em;
  font-weight: bold;
}

.sg-table__th::selection {
  background-color: rgba(0, 0, 0, 0);
}

.sg-table__th ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.sg-table__th--sortable {
  cursor: pointer;
}

.sg-table__heading-content-wrapper {
  align-items: center;
  display: flex;
  position: relative;
}

.sg-table__heading-content-wrapper--align-center {
  justify-content: center;
}

.sg-table__heading-content-wrapper--align-right {
  justify-content: flex-end;
}

.sg-table-sort {
  margin-left: .5em;
}

.sg-table-sort--deselected {
  color: #d1d5db;
}

.sg-table__no-results-found {
  text-align: center;
  padding: 2em 1em;
  font-size: 1.2em;
  font-style: italic;
}

.sg-tabs-wrapper .sg-table__thead--fixed-headings, .sg-tabs-wrapper .sg-table__table {
  border-top: 0;
}

.sg-table--mobile .sg-table__thead {
  display: none;
}

.sg-table--mobile .sg-table__table, .sg-table--mobile .sg-table__tbody, .sg-table--mobile .sg-table__tr {
  display: block;
}

.sg-table--mobile .sg-table__tr {
  flex-direction: column;
  padding: 20px;
  display: flex;
}

.sg-table--mobile .sg-table__td {
  margin-bottom: 10px;
  padding: 0;
}

.sg-table--mobile .sg-table__td:empty {
  margin-bottom: 0;
}

.sg-table--mobile .sg-table__td-mobile-heading {
  margin-bottom: 5px;
  font-weight: bold;
  display: block;
}
