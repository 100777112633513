.assignment-details {
  width: 100%;
  max-width: 830px;
  margin: 20px 30px;
}

.assignment-details__card {
  display: flex;
  overflow: hidden;
}

@media (max-width: 900px) {
  .assignment-details__card {
    background-color: #f3f4f6;
    flex-direction: column;
    margin: 0;
    padding: 1.25em .5em;
  }
}

.assignment-details__content-wrapper, .practice-exam-editor-how-to-move-questions {
  flex-direction: column;
  width: 60%;
  padding: 1.5em 2em;
  display: flex;
}

.assignment-details__content-wrapper .a-form-input, .practice-exam-editor-how-to-move-questions .a-form-input {
  flex-grow: 0;
  margin-bottom: 1em;
}

.assignment-details__content-wrapper .assignment-details__title-input, .practice-exam-editor-how-to-move-questions .assignment-details__title-input {
  margin-bottom: 2em;
}

.assignment-details__content-wrapper .assignment-details__title-input input, .practice-exam-editor-how-to-move-questions .assignment-details__title-input input {
  color: #1e7cb8;
  height: auto;
  padding-top: .25em;
  padding-bottom: .25em;
  font-size: 1.5em;
  font-weight: bold;
}

@media (max-width: 900px) {
  .assignment-details__content-wrapper, .practice-exam-editor-how-to-move-questions {
    width: 100%;
  }
}

.assignment-details__name {
  color: #1e7cb8;
  margin-bottom: 1em;
  font-size: 1.5em;
}

.assignment-details__heading {
  margin-bottom: .5em;
  font-size: 1em;
  font-weight: bold;
}

.assignment-details__content {
  margin-bottom: 1.5em;
}

.assignment-details__time-limit-display {
  margin-top: 0;
}

.assignment-details__time-limit-display dt {
  margin-bottom: .5em;
  font-weight: bold;
}

.assignment-details__footer-wrapper {
  justify-content: flex-end;
  margin-top: auto;
  display: flex;
}

.assignment-details__footer-wrapper > * {
  flex-grow: 1;
}

.assignment-details__footer-wrapper > :not(:last-child) {
  margin-right: 1em;
}

.assignment-sections {
  background-color: #f9fafb;
  border-right: 1px solid #f3f4f6;
  flex-direction: column;
  width: 40%;
  display: flex;
}

@media (max-width: 900px) {
  .assignment-sections {
    width: 100%;
  }
}

.assignment-sections__overview {
  cursor: default;
  justify-content: space-between;
  align-items: center;
  padding: 1.4em 1.6em;
  display: flex;
}

.assignment-sections__overview--active {
  background-color: #50a9e2;
}

.assignment-sections__overview--active, .assignment-sections__overview--active .assignment-sections__about {
  color: #fff;
}

.assignment-sections__heading-wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.assignment-sections__heading {
  margin-bottom: .25em;
  font-size: 1.5em;
}

.assignment-sections__add-section-wrapper {
  color: #50a9e2;
  padding: 1.2em 1.4em;
}

.assignment-sections__add-section-wrapper .fa {
  margin-right: .5em;
}

.assignment-sections__list {
  margin: 0 0 auto;
  padding: 0;
  list-style-type: none;
}

.assignment-sections__no-sections-found {
  text-align: center;
  padding: 1em 2em;
  font-style: italic;
}

.assignment-sections__section, .assignment-question-bank {
  cursor: default;
  background-color: #f9fafb;
  border: 0 solid #f3f4f6;
  border-bottom-width: 1px;
  justify-content: space-between;
  align-items: center;
  padding: 1.4em 1.6em;
  font-size: 16px;
  display: flex;
}

.assignment-sections__section:first-child {
  border-top-width: 1px;
}

.assignment-sections__section--active {
  background-color: #50a9e2;
}

.assignment-sections__section--active, .assignment-sections__section--active .assignment-sections__about, .assignment-sections__section--active .assignment-sections__completion, .assignment-sections__section--active .assignment-sections__edit-actions {
  color: #fff;
}

.assignment-sections__section--active .assignment-section-accuracy {
  background-color: rgba(255, 255, 255, .1);
}

.assignment-sections__details-wrapper {
  margin-right: 10px;
}

.assignment-sections__name, .assignment-question-bank__label {
  margin-bottom: .5em;
  font-size: 1em;
  font-weight: bold;
}

.assignment-sections__name--untitled {
  opacity: .75;
  font-style: italic;
}

.assignment-sections__about {
  color: #4b5563;
  margin-bottom: .25em;
}

.assignment-sections__completion {
  color: #9ca3af;
  font-weight: bold;
}

.assignment-sections__completion--complete {
  color: #059669;
}

.assignment-sections__completion--in-progress {
  color: #50a9e2;
}

.assignment-sections__completion--not-started {
  font-style: italic;
}

.assignment-section-accuracy {
  text-align: center;
  background-color: rgba(0, 0, 0, .035);
  border-radius: 5px;
  min-width: 6ch;
  margin-left: auto;
  padding: 5px;
  font-weight: bold;
}

.assignment-section-view-btn {
  margin-left: 10px;
}

.assignment-sections__edit-actions {
  color: #1e7cb8;
}

.assignment-sections__edit-actions .fa {
  padding: .5em;
}

.assignment-details__delete-btn {
  color: #dc4538;
  font-weight: bold;
}

.assignment-details__delete-btn:hover {
  color: #dc4538;
  text-decoration: underline;
}

.assignment-details__delete-btn:only-child {
  flex-grow: 0;
  padding: .75em;
}

.assignment-question-bank {
  color: #fff;
  background-color: #6b7280;
  margin-top: 2em;
}

.assignment-question-bank--active {
  background-color: #50a9e2;
}

.assignment-question-bank__count {
  margin: 0;
}

.assignment-details__question-bank-heading {
  color: #1e7cb8;
  font-size: 1.5em;
}

.assignment-details-stats {
  background-color: #f9fafb;
  border-radius: 5px;
  margin: 0 0 10px;
  padding: 20px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .2);
}

.assignment-details-stats__term {
  color: #6b7280;
  float: left;
  min-width: 12ch;
  font-weight: bold;
}

.assignment-details-stats__term:not(:last-of-type) {
  margin-bottom: 15px;
}

.assignment-details-stats__detail {
  font-weight: bold;
}

.assignment-details-stats__detail:not(:last-of-type) {
  margin-bottom: 15px;
}
