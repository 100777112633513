.simple-dropdown {
  cursor: default;
}

.simple-dropdown--disabled {
  opacity: .6;
  pointer-events: none;
}

.simple-dropdown__input-wrapper {
  align-items: center;
  font-size: 16px;
  display: inline-flex;
  position: relative;
}

.simple-dropdown__input-wrapper .simple-dropdown__options {
  background-color: var(--background-primary);
  border: 1px solid var(--border-primary);
  border-radius: var(--space-x1);
  box-shadow: var(--elevation-shadow-l3);
  opacity: 0;
  visibility: hidden;
  z-index: 99;
  flex-flow: column;
  min-width: 100%;
  max-height: 65vh;
  transition: all .1s ease-in-out;
  display: flex;
  position: absolute;
  top: calc(100% - 4px);
  left: 0;
  overflow: hidden auto;
}

.simple-dropdown__input-wrapper.simple-dropdown__input-wrapper--opened .simple-dropdown__options {
  opacity: 1;
  visibility: visible;
  transition: all .1s ease-in-out;
  top: calc(100% + 2px);
}

.simple-dropdown__input-wrapper.simple-dropdown__input-wrapper--opened .simple-dropdown__option {
  white-space: nowrap;
}

.simple-dropdown__input-wrapper.simple-dropdown__input-wrapper--opened .simple-dropdown__option--active {
  background-color: var(--background-brand-subtle);
}

.simple-dropdown__input-wrapper .simple-dropdown__option {
  border-bottom: 1px solid var(--border-subtle);
  cursor: pointer;
  text-align: left;
  padding: .9em;
  font-weight: bold;
  display: block;
}

.simple-dropdown__input-wrapper .simple-dropdown__option:hover {
  background-color: var(--background-primary-hover);
}

.simple-dropdown__input-wrapper .simple-dropdown__option:first-child {
  border-radius: 4px 4px 0 0;
}

.simple-dropdown__input-wrapper .simple-dropdown__option:last-child {
  border-radius: 0 0 4px 4px;
}

.simple-dropdown__input-wrapper .simple-dropdown__input {
  cursor: pointer;
  text-overflow: ellipsis;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  background: #50a9e2;
  border-radius: 2.5em;
  width: 15em;
  padding: .5em 2.5em .5em 1em;
  font-weight: bold;
  display: flex;
  overflow: hidden;
}

.simple-dropdown__input-wrapper .simple-dropdown__input:not(.simple-dropdown__input--bare) {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: var(--background-primary);
  border: 2px solid var(--border-primary);
  border-radius: 3px;
  align-items: center;
  height: 2.5rem;
  padding: .8em 2.5em .8em .75em;
  font-family: Lato, Helvetica, Arial, sans-serif;
  font-size: 1em;
  font-weight: normal;
  transition: border-color .1s;
}

.simple-dropdown__input-wrapper .simple-dropdown__input:not(.simple-dropdown__input--bare):focus, .simple-dropdown__input-wrapper .simple-dropdown__input:not(.simple-dropdown__input--bare):hover {
  transition: border 50ms ease-in-out;
}

.simple-dropdown__input-wrapper .simple-dropdown__input:not(.simple-dropdown__input--bare):focus {
  border-color: #a8d4f0;
  outline-width: 0;
}

.simple-dropdown__input-wrapper .simple-dropdown__input:not(.simple-dropdown__input--bare):hover {
  border-color: #a8d4f0;
}

.simple-dropdown__input-wrapper .simple-dropdown__input:not(.simple-dropdown__input--bare):disabled {
  cursor: not-allowed;
  opacity: .5;
}

.simple-dropdown__input-wrapper .simple-dropdown__input:not(.simple-dropdown__input--bare):disabled:hover {
  border-color: #e5e7eb;
}

.simple-dropdown__input-wrapper .simple-dropdown__input:not(.simple-dropdown__input--bare):hover {
  border-color: #a8d4f0;
  transition: border-color 50ms;
}

@media (max-width: 600px) {
  .simple-dropdown__input-wrapper .simple-dropdown__input {
    width: 10em;
  }
}

.simple-dropdown__input-wrapper.simple-dropdown__input-wrapper--full-width {
  width: 100%;
  display: flex;
}

.simple-dropdown__input-wrapper.simple-dropdown__input-wrapper--full-width .simple-dropdown__input {
  width: 100%;
}

.simple-dropdown__label {
  margin-bottom: .25em;
  font-weight: bold;
}

.simple-dropdown__input-active-item {
  text-overflow: ellipsis;
  overflow: hidden;
}

.simple-dropdown__input-active-item--placeholder {
  color: #6b7280;
}

.simple-dropdown__icon {
  align-items: center;
  padding: 0 1em;
  font-size: 1.1375em;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.simple-dropdown__icon.fa {
  display: flex;
}
