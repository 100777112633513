.hero-unit {
  z-index: 1;
}

.hero-unit--with-navbar {
  background-color: var(--background-primary);
  font-size: 16px;
}

.hero-unit__content-wrapper {
  color: var(--content-primary-inverse-static);
  text-align: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 3em 1rem 4.96em;
  display: flex;
}

.hero-unit__content-wrapper h1, .hero-unit__content-wrapper h2, .hero-unit__content-wrapper p {
  color: var(--content-primary-inverse-static);
  max-width: 500px;
  display: inline-block;
}

.hero-unit__content-wrapper h1, .hero-unit__content-wrapper h2 {
  text-shadow: 0 1px 1px rgba(0, 0, 0, .3);
  margin: 0;
  font-weight: 900;
}

.hero-unit__content-wrapper h1 {
  font-size: 1.71em;
}

.hero-unit__content-wrapper h2 {
  font-size: 1.42em;
}

.hero-unit__content-wrapper p {
  margin: 1.1rem auto 0;
}

.hero-unit__content-wrapper--with-search-input {
  padding-bottom: 5.5em;
}

.hero-unit__back-btn {
  box-shadow: none;
  color: var(--content-secondary);
  border-radius: 4px;
  font-size: 0;
  font-weight: normal;
  position: absolute;
  top: 1rem;
  left: 1rem;
}

@media (max-width: 579px) {
  .hero-unit__back-btn {
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    margin: 0;
    padding: 0;
    font-size: 0;
  }

  .hero-unit__back-btn:before {
    content: "";
    margin-right: 0;
    font-size: 0;
  }

  .hero-unit__back-btn:after {
    color: var(--content-primary-inverse-static);
    cursor: pointer;
    opacity: .75;
    content: "";
    padding: 0;
    font-family: "Font Awesome 6 Pro";
    font-size: 1.42rem;
    font-weight: normal;
  }

  .hero-unit__back-btn:focus:after {
    outline: 4px auto var(--border-bold);
  }
}

@media (min-width: 580px) {
  .hero-unit__back-btn {
    font-size: 1.1em;
  }

  .hero-unit__back-btn:before {
    content: "";
    margin-right: .7rem;
    font-family: "Font Awesome 6 Pro";
    font-size: 1rem;
  }
}

.hero-unit__back-btn--compact {
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 0;
}

.hero-unit__back-btn--compact:before {
  content: "";
  margin-right: 0;
  font-size: 0;
}

.hero-unit__back-btn--compact:after {
  color: var(--content-primary-inverse-static);
  cursor: pointer;
  opacity: .75;
  content: "";
  padding: 0;
  font-family: "Font Awesome 6 Pro";
  font-size: 1.42rem;
  font-weight: normal;
}

.hero-unit__back-btn--compact:focus:after {
  outline: 4px auto var(--border-bold);
}

.hero-unit__kebab {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

button.hero-unit__kebab-btn {
  box-shadow: none;
  color: currentColor;
  cursor: default;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  padding: 0;
  font-size: 0;
}

button.hero-unit__kebab-btn:active, button.hero-unit__kebab-btn:focus, button.hero-unit__kebab-btn:hover {
  transform: none;
}

button.hero-unit__kebab-btn:hover {
  color: currentColor;
}

button.hero-unit__kebab-btn:active, button.hero-unit__kebab-btn:focus {
  outline-width: 0;
}

button.hero-unit__kebab-btn:before {
  color: var(--content-primary-inverse-static);
  cursor: pointer;
  opacity: .75;
  padding: 0;
  font-family: "Font Awesome 6 Pro";
  font-size: 1.42rem;
  font-weight: normal;
}

.hero-unit__icon-wrapper {
  justify-content: center;
  align-items: center;
  height: 48px;
  margin: 0 auto 1.14em;
  display: flex;
}

.hero-unit__icon {
  height: 100%;
}

.hero-unit__text {
  color: var(--content-primary-inverse-static);
}

.hero-unit__text--superscript {
  letter-spacing: .05em;
  opacity: .75;
  text-transform: uppercase;
  font-weight: bold;
}

.hero-unit__breadcrumbs-wrapper {
  margin: 0 auto .35rem;
}

.hero-unit__breadcrumbs {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.hero-unit__breadcrumb-item {
  font-size: .85em;
}

.hero-unit__breadcrumb-item:not(:last-child) {
  margin-right: .5ch;
}

.sg-button.hero-unit__breadcrumb-item-link {
  color: var(--content-primary-inverse-static);
  background-color: rgba(0, 0, 0, 0);
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.sg-button.hero-unit__breadcrumb-item-link:hover {
  box-shadow: none;
  transition: none;
  transform: none;
}

.hero-unit__navbar.tertiary-header {
  text-transform: uppercase;
  background-color: #fff;
  margin-bottom: 0;
}

.hero-unit__navbar--adjacent-search.tertiary-header {
  padding-top: 1.984em;
}

.hero-search-input {
  z-index: 1;
  border-radius: 10em;
  width: calc(100% - 40px);
  max-width: 520px;
  position: absolute;
  bottom: -2em;
  left: 50%;
  transform: translateX(-50%);
}

.hero-search-input--adjacent-nav {
  bottom: 60px;
}

.hero-search-input--focused .hero-search-input__search-icon {
  color: var(--content-brand);
}

.hero-search-input__search-icon {
  color: var(--content-tertiary);
  z-index: 1;
  font-size: 1.25em;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}

.hero-search-input__input {
  -webkit-appearance: none;
  border: 1px solid var(--border-primary);
  padding: var(--space-x2) var(--space-x5);
  border-radius: 10em;
  outline-width: 0;
  width: 100%;
  font-size: 1em;
  box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
}

.hero-search-input__input:focus {
  outline: 0;
}

.hero-search-input__input:focus::placeholder {
  opacity: .5;
}

.hero-search-input__input::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.hero-search-input__reset-btn {
  z-index: 9;
  width: 50px;
  height: 100%;
  font-size: 0;
  position: absolute;
  bottom: 0;
  right: 0;
}

.hero-search-input__reset-btn:before {
  color: #9ca3af;
  font-size: 1.25rem;
  right: 10px;
}

.hero-search-input__reset-btn:disabled {
  cursor: default;
  opacity: 0;
}

.hero-search-input__reset-btn:focus:before {
  outline: -webkit-focus-ring-color auto 5px;
}

@media screen and (max-width: 900px) {
  .hero-search-input {
    bottom: -1.5em;
  }

  .hero-search-input--adjacent-nav {
    bottom: calc(60px + .5em);
  }

  .hero-unit__content-wrapper--with-search-input {
    padding-bottom: 5em;
  }
}

@media screen and (max-width: 600px) {
  .hero-search-input {
    bottom: -1em;
  }

  .hero-search-input--adjacent-nav {
    bottom: calc(60px + 1em);
  }

  .hero-unit__content-wrapper--with-search-input {
    padding-bottom: 4.5em;
  }

  .hero-search-input__search-icon {
    font-size: 1.15em;
    left: 17.5px;
  }

  .hero-search-input__input {
    padding: 15px 15px 15px 42.5px;
  }
}
