.add-to-template-toggle__checkbox-wrapper--single-question {
  color: var(--content-primary-inverse-static);
  cursor: pointer;
  width: 1.2em;
  min-width: 1.2em;
  height: 1.2em;
  min-height: 1.2em;
  display: inline-block;
  position: relative;
}

.add-to-template-toggle__checkbox-wrapper--single-question .checkbox-checkmark, .add-to-template-toggle__checkbox-wrapper--single-question .checkbox-checkmark:after {
  position: absolute;
  inset: 0;
}

.add-to-template-toggle__checkbox-wrapper--single-question input[type="checkbox"] {
  position: absolute;
  inset: 0;
}

.add-to-template-toggle__checkbox-wrapper--single-question .checkbox-checkmark {
  background-color: var(--background-bold);
  content: "";
  will-change: background-color;
  border-radius: .2em;
}

.add-to-template-toggle__checkbox-wrapper--single-question input[type="checkbox"] {
  cursor: pointer;
  opacity: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  outline-width: 0;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
}

.add-to-template-toggle__checkbox-wrapper--single-question input[type="checkbox"]:not(:disabled):hover ~ .checkbox-checkmark {
  background-color: var(--background-bold-hover);
  transition: background-color .2s cubic-bezier(.23, 1, .32, 1);
}

.add-to-template-toggle__checkbox-wrapper--single-question input[type="checkbox"]:not(:disabled):focus ~ .checkbox-checkmark {
  background-color: var(--background-bold-hover);
  transition: background-color .2s cubic-bezier(.23, 1, .32, 1);
}

.add-to-template-toggle__checkbox-wrapper--single-question input[type="checkbox"]:not(:disabled):checked:hover ~ .checkbox-checkmark {
  background-color: var(--background-brand-hover);
  transition: background-color .2s cubic-bezier(.23, 1, .32, 1);
}

.add-to-template-toggle__checkbox-wrapper--single-question input[type="checkbox"]:not(:disabled):checked:focus ~ .checkbox-checkmark {
  background-color: var(--background-brand-hover);
  transition: background-color .2s cubic-bezier(.23, 1, .32, 1);
}

.add-to-template-toggle__checkbox-wrapper--single-question input[type="checkbox"]:not(:disabled):focus ~ .checkbox-checkmark {
  box-shadow: 0 0 1px 3px var(--border-primary);
}

.add-to-template-toggle__checkbox-wrapper--single-question input[type="checkbox"]:not(:disabled):checked:focus ~ .checkbox-checkmark {
  box-shadow: 0 0 1px 3px var(--border-primary);
}

.add-to-template-toggle__checkbox-wrapper--single-question input[type="checkbox"]:checked ~ .checkbox-checkmark {
  background-color: var(--background-brand);
}

.add-to-template-toggle__checkbox-wrapper--single-question input[type="checkbox"]:checked ~ .checkbox-checkmark:after {
  color: var(--content-primary-inverse-static);
  content: "";
  text-align: center;
  font-family: "Font Awesome 6 Pro";
  font-size: .9em;
}

.add-to-template-toggle__checkbox-wrapper--single-question input[type="checkbox"]:checked:disabled ~ .checkbox-checkmark {
  background-color: #9ca3af;
  transition: all cubic-bezier(.23, 1, .32, 1);
}

.add-to-template-toggle__checkbox-wrapper--multi-question {
  color: var(--content-primary-inverse-static);
  cursor: pointer;
  width: 1.2em;
  min-width: 1.2em;
  height: 1.2em;
  min-height: 1.2em;
  display: inline-block;
  position: relative;
}

.add-to-template-toggle__checkbox-wrapper--multi-question .checkbox-checkmark, .add-to-template-toggle__checkbox-wrapper--multi-question .checkbox-checkmark:after {
  position: absolute;
  inset: 0;
}

.add-to-template-toggle__checkbox-wrapper--multi-question input[type="checkbox"] {
  position: absolute;
  inset: 0;
}

.add-to-template-toggle__checkbox-wrapper--multi-question .checkbox-checkmark {
  content: "";
  will-change: background-color;
  background-color: #fff;
  border-radius: .2em;
}

.add-to-template-toggle__checkbox-wrapper--multi-question input[type="checkbox"] {
  cursor: pointer;
  opacity: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  outline-width: 0;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
}

.add-to-template-toggle__checkbox-wrapper--multi-question input[type="checkbox"]:not(:disabled):hover ~ .checkbox-checkmark {
  background-color: var(--background-bold-hover);
  transition: background-color .2s cubic-bezier(.23, 1, .32, 1);
}

.add-to-template-toggle__checkbox-wrapper--multi-question input[type="checkbox"]:not(:disabled):focus ~ .checkbox-checkmark {
  background-color: var(--background-bold-hover);
  transition: background-color .2s cubic-bezier(.23, 1, .32, 1);
}

.add-to-template-toggle__checkbox-wrapper--multi-question input[type="checkbox"]:not(:disabled):checked:hover ~ .checkbox-checkmark {
  background-color: var(--background-brand-hover);
  transition: background-color .2s cubic-bezier(.23, 1, .32, 1);
}

.add-to-template-toggle__checkbox-wrapper--multi-question input[type="checkbox"]:not(:disabled):checked:focus ~ .checkbox-checkmark {
  background-color: var(--background-brand-hover);
  transition: background-color .2s cubic-bezier(.23, 1, .32, 1);
}

.add-to-template-toggle__checkbox-wrapper--multi-question input[type="checkbox"]:not(:disabled):focus ~ .checkbox-checkmark {
  box-shadow: 0 0 1px 3px var(--border-primary);
}

.add-to-template-toggle__checkbox-wrapper--multi-question input[type="checkbox"]:not(:disabled):checked:focus ~ .checkbox-checkmark {
  box-shadow: 0 0 1px 3px var(--border-primary);
}

.add-to-template-toggle__checkbox-wrapper--multi-question input[type="checkbox"]:checked ~ .checkbox-checkmark {
  background-color: var(--background-brand);
}

.add-to-template-toggle__checkbox-wrapper--multi-question input[type="checkbox"]:checked ~ .checkbox-checkmark:after {
  color: var(--content-primary-inverse-static);
  content: "";
  text-align: center;
  font-family: "Font Awesome 6 Pro";
  font-size: .9em;
}

.add-to-template-toggle__checkbox-wrapper--multi-question input[type="checkbox"]:checked:disabled ~ .checkbox-checkmark {
  background-color: #9ca3af;
  transition: all cubic-bezier(.23, 1, .32, 1);
}
