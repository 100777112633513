.question-footer__actions-wrapper {
  justify-content: flex-end;
  align-items: center;
  padding: 0 .75rem .75rem;
  display: flex;
}

.question-footer__secondary-actions {
  flex-grow: 1;
  margin-left: 1rem;
  font-size: 1.5rem;
}

.question-footer__secondary-actions .fa {
  color: #e5e7eb;
  cursor: pointer;
  margin-right: .5em;
  font-size: .8em;
}

@media (max-width: 600px) {
  .question-footer__secondary-actions {
    display: none;
  }
}

.question-footer__primary-actions {
  justify-content: space-between;
  display: flex;
}

@media (max-width: 600px) {
  .question-footer__primary-actions {
    flex-wrap: wrap;
    width: 100%;
  }
}

.question-footer-regular-button, .question-footer-submit-button, .question-footer-next-button {
  text-align: center;
  margin-left: .5em;
}

.question-footer-regular-button:last-child, .question-footer-submit-button:last-child, .question-footer-next-button:last-child {
  padding-left: 4em;
  padding-right: 4em;
}

@media (max-width: 600px) {
  .question-footer-regular-button[href^="/editpage"], .question-footer-submit-button[href^="/editpage"], .question-footer-next-button[href^="/editpage"] {
    display: none;
  }

  .question-footer-regular-button:first-child, .question-footer-regular-button:last-child, .question-footer-regular-button[href^="/editpage"] + .question-footer-regular-button, .question-footer-submit-button:first-child, .question-footer-submit-button:last-child, .question-footer-submit-button[href^="/editpage"] + .question-footer-regular-button, .question-footer-next-button:first-child, .question-footer-next-button:last-child, .question-footer-next-button[href^="/editpage"] + .question-footer-regular-button {
    margin: 0;
  }

  .question-footer-regular-button:not(:last-child), .question-footer-submit-button:not(:last-child), .question-footer-next-button:not(:last-child) {
    flex-grow: 1;
  }

  .question-footer-regular-button:last-child, .question-footer-submit-button:last-child, .question-footer-next-button:last-child {
    width: 100%;
    margin-top: .5em;
  }
}

.question-footer-submit-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #fff;
  cursor: pointer;
  background-color: #059669;
  border: 0;
  border-radius: .15em;
  padding: .85em 1em .8em;
  font-size: 1em;
  font-weight: bold;
  text-decoration: none;
  transition: box-shadow .1s ease-in-out, transform .1s ease-in-out;
}

.question-footer-submit-button::selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-footer-submit-button ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-footer-submit-button.disabled, .question-footer-submit-button.disabled:hover, .question-footer-submit-button:disabled, .question-footer-submit-button:disabled:hover {
  color: #d1d5db;
  background-color: #6b7280;
}

.question-footer-submit-button:not(:disabled):not(:active):hover {
  transform: translateY(-1px)scale(1.01);
  box-shadow: 0 2px 9px rgba(0, 0, 0, .2);
}

.question-footer-regular-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #fff;
  cursor: pointer;
  background-color: #d1d5db;
  border: 0;
  border-radius: .15em;
  padding: .85em 1em .8em;
  font-size: 1em;
  font-weight: bold;
  text-decoration: none;
  transition: box-shadow .1s ease-in-out, transform .1s ease-in-out;
}

.question-footer-regular-button::selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-footer-regular-button ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-footer-regular-button.disabled, .question-footer-regular-button.disabled:hover, .question-footer-regular-button:disabled, .question-footer-regular-button:disabled:hover {
  color: #d1d5db;
  background-color: #6b7280;
}

.question-footer-regular-button:not(:disabled):not(:active):hover {
  transform: translateY(-1px)scale(1.01);
  box-shadow: 0 2px 9px rgba(0, 0, 0, .2);
}

.question-footer-next-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #fff;
  cursor: pointer;
  background-color: #1e7cb8;
  border: 0;
  border-radius: .15em;
  padding: .85em 1em .8em;
  font-size: 1em;
  font-weight: bold;
  text-decoration: none;
  transition: box-shadow .1s ease-in-out, transform .1s ease-in-out;
}

.question-footer-next-button::selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-footer-next-button ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-footer-next-button.disabled, .question-footer-next-button.disabled:hover, .question-footer-next-button:disabled, .question-footer-next-button:disabled:hover {
  color: #d1d5db;
  background-color: #6b7280;
}

.question-footer-next-button:not(:disabled):not(:active):hover {
  transform: translateY(-1px)scale(1.01);
  box-shadow: 0 2px 9px rgba(0, 0, 0, .2);
}

.question-footer__results-indicator {
  align-items: center;
  margin-bottom: 2em;
  padding: 1em 2em;
  display: flex;
}

.question-footer__results-indicator span {
  margin: 1em 0;
}

.question-footer__results-indicator .fa {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin: 0 10px 0 0;
  display: inline-block;
  position: relative;
}

.question-footer__results-indicator .fa:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.question-footer__results-indicator--correct {
  color: #059669;
  background-color: #d1fae5;
}

.question-footer__results-indicator--correct a {
  color: #059669;
}

.question-footer__results-indicator--correct .fa {
  color: #d1fae5;
  background-color: #059669;
}

.question-footer__results-indicator--incorrect {
  color: #dc4538;
  background-color: #fbefef;
}

.question-footer__results-indicator--incorrect a {
  color: #dc4538;
}

.question-footer__results-indicator--incorrect .fa {
  color: #fbefef;
  background-color: #dc4538;
}

.question-footer__toggle-explanation-area {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  border-radius: .15em;
  margin: .75em;
  padding: .85em 1em .8em;
  font-size: 1.15em;
  font-weight: bold;
  text-decoration: none;
  transition: box-shadow .1s ease-in-out, transform .1s ease-in-out;
}

.question-footer__toggle-explanation-area::selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-footer__toggle-explanation-area ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-footer__toggle-explanation-area.disabled, .question-footer__toggle-explanation-area.disabled:hover, .question-footer__toggle-explanation-area:disabled, .question-footer__toggle-explanation-area:disabled:hover {
  color: #d1d5db;
  background-color: #6b7280;
}

.question-footer__toggle-explanation-area:not(:disabled):not(:active):hover {
  transform: translateY(-1px)scale(1.01);
  box-shadow: 0 2px 9px rgba(0, 0, 0, .2);
}

.question-footer__toggle-explanation-area .fa {
  min-width: 1em;
  margin-left: .25em;
  transition: transform .25s ease-out;
}

.question-footer__toggle-explanation-area .opened {
  transform: rotateZ(90deg);
}

.question-footer__main-content-wrapper {
  padding: 0 0 1rem;
}

.question-footer__main-content-wrapper .question-footer__explanation-heading {
  text-transform: uppercase;
  margin: 0 0 .5em;
  font-size: 1.15em;
}

.question-footer__main-content-wrapper p {
  margin: 0 0 .75em;
}

@media (max-width: 600px) {
  .question-footer__main-content-wrapper {
    padding: 0 1rem .75rem;
  }
}

.question-footer__main-content-wrapper--collapsed {
  height: 0;
  padding: 0;
  overflow: hidden;
}

.question-footer__main-content-wrapper--expanded {
  height: auto;
}

.question-footer__sample-response, .question-footer__explanation-wrapper {
  margin-bottom: 2em;
}

.question-footer__explanation-content-unsubmitted-message {
  font-weight: bold;
}

.explanation-content-placeholder .explanation-content-placeholder__line {
  color: #f3f4f6;
  background-color: #f3f4f6;
  height: 1em;
  margin-bottom: .25em;
}

.explanation-content-placeholder .explanation-content-placeholder__line--small {
  width: 35%;
}

.explanation-content-placeholder .explanation-content-placeholder__line--medium {
  width: 65%;
}

.question-footer__labels-wrapper, .question-footer__tags-wrapper {
  margin-bottom: 1em;
}

.question-footer__labels-header, .question-footer__tags-header {
  text-transform: uppercase;
  margin-bottom: .5em;
  font-size: 1.15em;
}

.question-footer__labels-content, .question-footer__tags-content {
  position: relative;
}

.question-footer__labels-content a, .question-footer__tags-content a {
  color: inherit;
}

.question-footer__label-item {
  color: inherit;
  cursor: pointer;
  background-color: #f3f4f6;
  border-radius: 3px;
  margin: 0 .5em .5em 0;
  padding: .5em .75em;
  font-size: .75rem;
  font-weight: bold;
  display: inline-block;
  position: relative;
}

.question-footer__label-item, .question-footer__label-item a {
  text-decoration: none;
}

.question-footer__label-item:empty:not([type="button"]) {
  display: none;
}

.question-footer__label-item::selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-footer__label-item ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-footer__label-item:hover, .question-footer__label-item:focus {
  background-color: #e4e7eb;
  outline-width: 0;
  transition: background-color .25s;
}

@media (max-width: 600px) {
  .question-footer__label-item:hover, .question-footer__label-item:focus {
    background-color: #f3f4f6;
    outline-width: 0;
    transition: background-color .25s;
  }
}

.question-footer__label-item .tooltip {
  bottom: calc(100% + 2.5em);
}

.question-footer__tag-item {
  color: inherit;
  cursor: pointer;
  background-color: #f3f4f6;
  border-radius: 3px;
  margin: 0 .5em .5em 0;
  padding: .5em .75em;
  font-size: .75rem;
  font-weight: bold;
  display: inline-block;
}

.question-footer__tag-item, .question-footer__tag-item a {
  text-decoration: none;
}

.question-footer__tag-item:empty:not([type="button"]) {
  display: none;
}

.question-footer__tag-item::selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-footer__tag-item ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-footer__tag-item:hover, .question-footer__tag-item:focus {
  background-color: #e4e7eb;
  outline-width: 0;
  transition: background-color .25s;
}

@media (max-width: 600px) {
  .question-footer__tag-item:hover, .question-footer__tag-item:focus {
    background-color: #f3f4f6;
    outline-width: 0;
    transition: background-color .25s;
  }
}

.question-footer__helpfulness-wrapper .upvote, .question-footer__helpfulness-wrapper .downvote {
  color: inherit;
  cursor: pointer;
  background-color: #f3f4f6;
  border-radius: 3px;
  margin-left: .75em;
  padding: .5em .75em;
  font-size: .75rem;
  font-weight: bold;
  display: inline-block;
}

.question-footer__helpfulness-wrapper .upvote, .question-footer__helpfulness-wrapper .upvote a, .question-footer__helpfulness-wrapper .downvote, .question-footer__helpfulness-wrapper .downvote a {
  text-decoration: none;
}

.question-footer__helpfulness-wrapper .upvote:empty:not([type="button"]) {
  display: none;
}

.question-footer__helpfulness-wrapper .downvote:empty:not([type="button"]) {
  display: none;
}

.question-footer__helpfulness-wrapper .upvote::-moz-selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-footer__helpfulness-wrapper .downvote::-moz-selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-footer__helpfulness-wrapper .upvote::selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-footer__helpfulness-wrapper .downvote::selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-footer__helpfulness-wrapper .upvote ::-moz-selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-footer__helpfulness-wrapper .downvote ::-moz-selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-footer__helpfulness-wrapper .upvote ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-footer__helpfulness-wrapper .downvote ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-footer__helpfulness-wrapper .upvote:hover, .question-footer__helpfulness-wrapper .upvote:focus, .question-footer__helpfulness-wrapper .downvote:hover, .question-footer__helpfulness-wrapper .downvote:focus {
  background-color: #e4e7eb;
  outline-width: 0;
  transition: background-color .25s;
}

@media (max-width: 600px) {
  .question-footer__helpfulness-wrapper .upvote:hover, .question-footer__helpfulness-wrapper .upvote:focus, .question-footer__helpfulness-wrapper .downvote:hover, .question-footer__helpfulness-wrapper .downvote:focus {
    background-color: #f3f4f6;
    outline-width: 0;
    transition: background-color .25s;
  }
}

.question-footer__helpfulness-wrapper .upvote.active, .question-footer__helpfulness-wrapper .downvote.active {
  color: #fff;
  cursor: pointer;
  background-color: #1e7cb8;
  border-radius: 3px;
  padding: .5em .75em;
  font-size: .75rem;
  font-weight: bold;
  display: inline-block;
}

.question-footer__helpfulness-wrapper .upvote.active, .question-footer__helpfulness-wrapper .upvote.active a, .question-footer__helpfulness-wrapper .downvote.active, .question-footer__helpfulness-wrapper .downvote.active a {
  text-decoration: none;
}

.question-footer__helpfulness-wrapper .upvote.active:empty:not([type="button"]) {
  display: none;
}

.question-footer__helpfulness-wrapper .downvote.active:empty:not([type="button"]) {
  display: none;
}

.question-footer__helpfulness-wrapper .upvote.active::-moz-selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-footer__helpfulness-wrapper .downvote.active::-moz-selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-footer__helpfulness-wrapper .upvote.active::selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-footer__helpfulness-wrapper .downvote.active::selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-footer__helpfulness-wrapper .upvote.active ::-moz-selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-footer__helpfulness-wrapper .downvote.active ::-moz-selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-footer__helpfulness-wrapper .upvote.active ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-footer__helpfulness-wrapper .downvote.active ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-footer__helpfulness-wrapper .upvote.active:hover, .question-footer__helpfulness-wrapper .upvote.active:focus, .question-footer__helpfulness-wrapper .downvote.active:hover, .question-footer__helpfulness-wrapper .downvote.active:focus {
  background-color: #1a6da2;
  outline-width: 0;
  transition: background-color .25s;
}

@media (max-width: 600px) {
  .question-footer__helpfulness-wrapper .upvote.active:hover, .question-footer__helpfulness-wrapper .upvote.active:focus, .question-footer__helpfulness-wrapper .downvote.active:hover, .question-footer__helpfulness-wrapper .downvote.active:focus {
    background-color: #1e7cb8;
    outline-width: 0;
    transition: background-color .25s;
  }
}

.question-response-stats {
  max-width: 35em;
  margin-bottom: 1em;
}

.question-response-stats__header {
  color: #aeb1b4;
  text-transform: uppercase;
  margin-bottom: 1em;
}

.question-response-stats__item {
  align-items: center;
  margin-bottom: .5em;
  display: flex;
}

.question-response-stats__item.incorrect .question-response-stats__item-label {
  color: #fff;
  cursor: default;
  background-color: #dc4538;
  border-radius: 3px;
  padding: .5em .75em;
  font-size: .75rem;
  font-weight: bold;
  display: inline-block;
}

.question-response-stats__item.incorrect .question-response-stats__item-label, .question-response-stats__item.incorrect .question-response-stats__item-label a {
  text-decoration: none;
}

.question-response-stats__item.incorrect .question-response-stats__item-label:empty:not([type="button"]) {
  display: none;
}

.question-response-stats__item.incorrect .question-response-stats__item-label::selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-response-stats__item.incorrect .question-response-stats__item-label ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-response-stats__item.correct .question-response-stats__item-label {
  color: #fff;
  cursor: default;
  background-color: #059669;
  border-radius: 3px;
  padding: .5em .75em;
  font-size: .75rem;
  font-weight: bold;
  display: inline-block;
}

.question-response-stats__item.correct .question-response-stats__item-label, .question-response-stats__item.correct .question-response-stats__item-label a {
  text-decoration: none;
}

.question-response-stats__item.correct .question-response-stats__item-label:empty:not([type="button"]) {
  display: none;
}

.question-response-stats__item.correct .question-response-stats__item-label::selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-response-stats__item.correct .question-response-stats__item-label ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-response-stats__item-label {
  color: #fff;
  cursor: default;
  white-space: nowrap;
  background-color: #9ca3af;
  border-radius: 3px;
  padding: .5em .75em;
  font-size: .75rem;
  font-weight: bolder;
  display: inline-block;
}

.question-response-stats__item-label, .question-response-stats__item-label a {
  text-decoration: none;
}

.question-response-stats__item-label:empty:not([type="button"]) {
  display: none;
}

.question-response-stats__item-label::selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-response-stats__item-label ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-response-stats__item-fillbar {
  background-color: #f3f4f6;
  border-radius: 2em;
  width: 100%;
  height: 1em;
  margin: 0 .75em;
  position: relative;
}

.question-response-stats__item-fillbar:before {
  content: "";
  background-color: #059669;
  border-radius: 2em;
  height: 1em;
  display: inline-block;
}

.question-response-stats__item [data-fill-percentage]:before {
  width: 0%;
  transition: width 1s;
}

.question-response-stats__item [data-fill-percentage="0"]:after, .question-response-stats__item [data-fill-percentage="100"]:after {
  content: normal;
}

.question-response-stats__item [data-fill-percentage="1"]:before {
  width: 1%;
}

.question-response-stats__item [data-fill-percentage="2"]:before {
  width: 2%;
}

.question-response-stats__item [data-fill-percentage="3"]:before {
  width: 3%;
}

.question-response-stats__item [data-fill-percentage="4"]:before {
  width: 4%;
}

.question-response-stats__item [data-fill-percentage="5"]:before {
  width: 5%;
}

.question-response-stats__item [data-fill-percentage="6"]:before {
  width: 6%;
}

.question-response-stats__item [data-fill-percentage="7"]:before {
  width: 7%;
}

.question-response-stats__item [data-fill-percentage="8"]:before {
  width: 8%;
}

.question-response-stats__item [data-fill-percentage="9"]:before {
  width: 9%;
}

.question-response-stats__item [data-fill-percentage="10"]:before {
  width: 10%;
}

.question-response-stats__item [data-fill-percentage="11"]:before {
  width: 11%;
}

.question-response-stats__item [data-fill-percentage="12"]:before {
  width: 12%;
}

.question-response-stats__item [data-fill-percentage="13"]:before {
  width: 13%;
}

.question-response-stats__item [data-fill-percentage="14"]:before {
  width: 14%;
}

.question-response-stats__item [data-fill-percentage="15"]:before {
  width: 15%;
}

.question-response-stats__item [data-fill-percentage="16"]:before {
  width: 16%;
}

.question-response-stats__item [data-fill-percentage="17"]:before {
  width: 17%;
}

.question-response-stats__item [data-fill-percentage="18"]:before {
  width: 18%;
}

.question-response-stats__item [data-fill-percentage="19"]:before {
  width: 19%;
}

.question-response-stats__item [data-fill-percentage="20"]:before {
  width: 20%;
}

.question-response-stats__item [data-fill-percentage="21"]:before {
  width: 21%;
}

.question-response-stats__item [data-fill-percentage="22"]:before {
  width: 22%;
}

.question-response-stats__item [data-fill-percentage="23"]:before {
  width: 23%;
}

.question-response-stats__item [data-fill-percentage="24"]:before {
  width: 24%;
}

.question-response-stats__item [data-fill-percentage="25"]:before {
  width: 25%;
}

.question-response-stats__item [data-fill-percentage="26"]:before {
  width: 26%;
}

.question-response-stats__item [data-fill-percentage="27"]:before {
  width: 27%;
}

.question-response-stats__item [data-fill-percentage="28"]:before {
  width: 28%;
}

.question-response-stats__item [data-fill-percentage="29"]:before {
  width: 29%;
}

.question-response-stats__item [data-fill-percentage="30"]:before {
  width: 30%;
}

.question-response-stats__item [data-fill-percentage="31"]:before {
  width: 31%;
}

.question-response-stats__item [data-fill-percentage="32"]:before {
  width: 32%;
}

.question-response-stats__item [data-fill-percentage="33"]:before {
  width: 33%;
}

.question-response-stats__item [data-fill-percentage="34"]:before {
  width: 34%;
}

.question-response-stats__item [data-fill-percentage="35"]:before {
  width: 35%;
}

.question-response-stats__item [data-fill-percentage="36"]:before {
  width: 36%;
}

.question-response-stats__item [data-fill-percentage="37"]:before {
  width: 37%;
}

.question-response-stats__item [data-fill-percentage="38"]:before {
  width: 38%;
}

.question-response-stats__item [data-fill-percentage="39"]:before {
  width: 39%;
}

.question-response-stats__item [data-fill-percentage="40"]:before {
  width: 40%;
}

.question-response-stats__item [data-fill-percentage="41"]:before {
  width: 41%;
}

.question-response-stats__item [data-fill-percentage="42"]:before {
  width: 42%;
}

.question-response-stats__item [data-fill-percentage="43"]:before {
  width: 43%;
}

.question-response-stats__item [data-fill-percentage="44"]:before {
  width: 44%;
}

.question-response-stats__item [data-fill-percentage="45"]:before {
  width: 45%;
}

.question-response-stats__item [data-fill-percentage="46"]:before {
  width: 46%;
}

.question-response-stats__item [data-fill-percentage="47"]:before {
  width: 47%;
}

.question-response-stats__item [data-fill-percentage="48"]:before {
  width: 48%;
}

.question-response-stats__item [data-fill-percentage="49"]:before {
  width: 49%;
}

.question-response-stats__item [data-fill-percentage="50"]:before {
  width: 50%;
}

.question-response-stats__item [data-fill-percentage="51"]:before {
  width: 51%;
}

.question-response-stats__item [data-fill-percentage="52"]:before {
  width: 52%;
}

.question-response-stats__item [data-fill-percentage="53"]:before {
  width: 53%;
}

.question-response-stats__item [data-fill-percentage="54"]:before {
  width: 54%;
}

.question-response-stats__item [data-fill-percentage="55"]:before {
  width: 55%;
}

.question-response-stats__item [data-fill-percentage="56"]:before {
  width: 56%;
}

.question-response-stats__item [data-fill-percentage="57"]:before {
  width: 57%;
}

.question-response-stats__item [data-fill-percentage="58"]:before {
  width: 58%;
}

.question-response-stats__item [data-fill-percentage="59"]:before {
  width: 59%;
}

.question-response-stats__item [data-fill-percentage="60"]:before {
  width: 60%;
}

.question-response-stats__item [data-fill-percentage="61"]:before {
  width: 61%;
}

.question-response-stats__item [data-fill-percentage="62"]:before {
  width: 62%;
}

.question-response-stats__item [data-fill-percentage="63"]:before {
  width: 63%;
}

.question-response-stats__item [data-fill-percentage="64"]:before {
  width: 64%;
}

.question-response-stats__item [data-fill-percentage="65"]:before {
  width: 65%;
}

.question-response-stats__item [data-fill-percentage="66"]:before {
  width: 66%;
}

.question-response-stats__item [data-fill-percentage="67"]:before {
  width: 67%;
}

.question-response-stats__item [data-fill-percentage="68"]:before {
  width: 68%;
}

.question-response-stats__item [data-fill-percentage="69"]:before {
  width: 69%;
}

.question-response-stats__item [data-fill-percentage="70"]:before {
  width: 70%;
}

.question-response-stats__item [data-fill-percentage="71"]:before {
  width: 71%;
}

.question-response-stats__item [data-fill-percentage="72"]:before {
  width: 72%;
}

.question-response-stats__item [data-fill-percentage="73"]:before {
  width: 73%;
}

.question-response-stats__item [data-fill-percentage="74"]:before {
  width: 74%;
}

.question-response-stats__item [data-fill-percentage="75"]:before {
  width: 75%;
}

.question-response-stats__item [data-fill-percentage="76"]:before {
  width: 76%;
}

.question-response-stats__item [data-fill-percentage="77"]:before {
  width: 77%;
}

.question-response-stats__item [data-fill-percentage="78"]:before {
  width: 78%;
}

.question-response-stats__item [data-fill-percentage="79"]:before {
  width: 79%;
}

.question-response-stats__item [data-fill-percentage="80"]:before {
  width: 80%;
}

.question-response-stats__item [data-fill-percentage="81"]:before {
  width: 81%;
}

.question-response-stats__item [data-fill-percentage="82"]:before {
  width: 82%;
}

.question-response-stats__item [data-fill-percentage="83"]:before {
  width: 83%;
}

.question-response-stats__item [data-fill-percentage="84"]:before {
  width: 84%;
}

.question-response-stats__item [data-fill-percentage="85"]:before {
  width: 85%;
}

.question-response-stats__item [data-fill-percentage="86"]:before {
  width: 86%;
}

.question-response-stats__item [data-fill-percentage="87"]:before {
  width: 87%;
}

.question-response-stats__item [data-fill-percentage="88"]:before {
  width: 88%;
}

.question-response-stats__item [data-fill-percentage="89"]:before {
  width: 89%;
}

.question-response-stats__item [data-fill-percentage="90"]:before {
  width: 90%;
}

.question-response-stats__item [data-fill-percentage="91"]:before {
  width: 91%;
}

.question-response-stats__item [data-fill-percentage="92"]:before {
  width: 92%;
}

.question-response-stats__item [data-fill-percentage="93"]:before {
  width: 93%;
}

.question-response-stats__item [data-fill-percentage="94"]:before {
  width: 94%;
}

.question-response-stats__item [data-fill-percentage="95"]:before {
  width: 95%;
}

.question-response-stats__item [data-fill-percentage="96"]:before {
  width: 96%;
}

.question-response-stats__item [data-fill-percentage="97"]:before {
  width: 97%;
}

.question-response-stats__item [data-fill-percentage="98"]:before {
  width: 98%;
}

.question-response-stats__item [data-fill-percentage="99"]:before {
  width: 99%;
}

.question-response-stats__item [data-fill-percentage="100"]:before {
  width: 100%;
}

.question-response-stats__item-percentage {
  color: #9ca3af;
  text-align: left;
  width: 3em;
}

.question-response-top-attempts__toggle {
  color: #4b5563;
  cursor: pointer;
  background-color: #f3f4f6;
  border-radius: .25em;
  margin-bottom: .5em;
  padding: .5em .75em;
  display: block;
}

.question-response-top-attempts__toggle::selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-response-top-attempts__toggle.is-showing {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  margin-bottom: 0;
}

.question-response-top-attempts__list {
  border: 1px solid #f3f4f6;
  border-top: 0;
  border-radius: 0 0 .25em .25em;
  margin: 0 0 .5em;
  padding: 0;
  list-style-type: none;
  display: none;
}

.question-response-top-attempts__list.is-showing {
  display: block;
}

.question-response-top-attempts__list-item {
  justify-content: center;
  margin: 0;
  padding: .65em 1em .65em .75em;
  display: flex;
}

.question-response-top-attempts__list-item.correct .fa {
  color: #059669;
}

.question-response-top-attempts__list-item.incorrect .fa {
  color: #dc4538;
}

.question-response-top-attempts__list-item.correct.selected {
  background-color: #d1fae5;
  font-weight: bolder;
}

.question-response-top-attempts__list-item.incorrect.selected {
  background-color: #fbefef;
  font-weight: bolder;
}

.question-response-top-attempts__list-item:first-child {
  padding-top: .75em;
}

.question-response-top-attempts__list-item:last-child {
  padding-bottom: .75em;
}

.question-response-top-attempts__list-item:not(:last-child) {
  border-bottom: 1px solid #f3f4f6;
}

.question-response-top-attempts__list-item .fa {
  margin-right: .5em;
  font-size: 1.15em;
  line-height: .75rem;
}

.question-response-top-attempts__attempt-value, .question-response-top-attempts__attempt-percentage {
  display: inline-block;
}

.question-response-top-attempts__attempt-value {
  flex-grow: 1;
  margin-right: 1em;
}
