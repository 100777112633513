.queue-search-panel {
  background-color: var(--background-subtle);
  box-shadow: 1px 0 0 var(--border-primary);
  width: 100%;
  overflow-x: scroll;
}

.queue-search-panel__facet {
  width: 100%;
}

.queue-search-panel__section {
  padding: 1em;
}

.queue-search-panel__section .queue-search-panel__facet {
  margin: .75em .25em;
}

.queue-search-panel__section--highlight {
  background-color: var(--background-primary);
}

.queue-search-panel__section--actions {
  align-content: center;
  margin-top: 1em;
  display: flex;
}

.queue-search-panel__sort-by {
  margin-top: 8px;
}
