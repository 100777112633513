.edit-question-set-modal .simple-modal__window {
  max-width: 420px;
}

.edit-question-positions-list {
  background-color: #f3f4f6;
  align-items: center;
  margin: 10px 0 20px;
  padding: 10px;
  list-style-type: none;
  box-shadow: inset 1px 1px rgba(255, 255, 255, .5), inset -1px -1px rgba(0, 0, 0, .1);
}

.edit-question-positions-item {
  z-index: 9999;
  background-color: #fff;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 15px;
  font-size: 16px;
  list-style-type: none;
  display: flex;
}

.edit-question-positions-item:last-child {
  margin-bottom: 0;
}
