.edit-page {
  width: 100%;
  min-height: 100%;
  margin: 0 auto;
}

.edit-page-v2__header-section {
  box-shadow: var(--elevation-shadow-l1);
  z-index: 999;
  position: sticky;
  top: 0;
}

.edit-page-v2__header {
  background-color: var(--background-primary);
  border-bottom: 1px solid var(--border-primary);
  padding: var(--space-x2) var(--space-x4);
  justify-content: space-between;
  align-items: center;
  gap: var(--space-x2);
  display: flex;
}

.edit-page__actions-wrapper {
  background-color: var(--background-subtle);
  z-index: 999;
  position: sticky;
  top: 0;
}

.edit-page__preview-mode {
  flex-basis: auto;
}

.edit-page__preview-mode .sg-checkbox-toggle__label {
  margin-right: .5em;
  font-weight: bold;
}

.edit-page__question-wrapper {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

@media (max-width: 900px) {
  .edit-page__question-wrapper {
    flex-wrap: wrap;
  }
}

.edit-page-controls-buttons {
  display: flex;
}

.edit-page-controls-buttons .copy-to-clipboard {
  margin-right: 8px;
}

.edit-page-controls-buttons .copy-to-clipboard .sg-button {
  margin-right: 0;
}

.edit-page__section-card {
  border-bottom: 1px solid var(--border-subtle);
  padding: var(--space-x2) var(--space-x3);
  justify-content: space-between;
  align-items: center;
  display: flex;
}
